import _ from 'lodash'
import React, { Component } from 'react'
import { Grid , Button,  Table} from 'semantic-ui-react'
import { observer } from 'mobx-react';
import Lottie from 'react-lottie'
import animationData from '../assets/json/anim.json'
import animationData2 from '../assets/json/anim2.json'
import ExamStore from '../../stores/exam';
import styles from './prepareExam.css';

@observer
class Score extends Component{
    saveAndContinue = (e) => {
        e.preventDefault()
        this.props.nextStep()
        let session_id = e.target.value
        ExamStore.saveSessionId(session_id);
        this.props.handleExamCorrection(e)
        
    }

  render(){
    // equalWidth2
    // let scoreDetails = ExamStore.AllScore;
    // const MapScoreDetails = scoreDetails.map(singleData => {
    //     let subjectDecode = singleData.split("/");
    //     return <List.Item> <List.Content floated='right'> <Button basic color='green' Key={subjectDecode[1]} onClick={this.saveAndContinue}>start</Button>  </List.Content>  <List.Content>{subjectDecode[0]}</List.Content> </List.Item>
    
    //   })
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    const defaultOptions2 = {
        loop: true,
        autoplay: true, 
        animationData: animationData2,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    return ( 
        <div className="centerFlex allWrap">
        <div style={styles.DivWrapper2_2} className="centerFlex pad0 alignFlex blueLayer pad4">
            <div className="DivWrapper2Wrap2_1 centerFlex fullHeight" style={styles.DivWrapper3Wrap}>
    {/* <div style={styles.DivWrapper} className="flexDisplayColume"> */}
        <Lottie options={defaultOptions}
             className="fullHeight fullSize"
        />
        <div className="scoreWrap2">
        <Lottie options={defaultOptions2}
             className="fullHeight fullSize "
        />
        </div>
        
        <div className='flexDisplayColume alignFlex justifyFlex scoreWrap2'>
            <div className='flexDisplayColume alignFlex justifyFlex scoreWrap'>
            <p className="twentyFontSize scoreTxt font500"> You Did Great!!!</p>
            <p className="scoreTxt2"> Your Scored</p>
            <p className=" scoreTxt3 font500"> {ExamStore.AllScore}%</p>
            <Button value={ExamStore.AllSession} onClick={this.saveAndContinue} className=" font500 btnInc_2" style={{width: '240px', marginBottom: '10px'}}>View Correction</Button>
            </div>
        </div>
  {/* </div> */}
  </div>
  </div>
  </div>
)
}
}

export default Score
