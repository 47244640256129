export default {
 navInc: {
        padding: ' 1rem 5rem',
        fontSize: '15px',
    },
 navInc3: {
        padding: ' 10px 5rem',
        fontSize: '15px',
        marginTop: 10,
    },
    edvesAca: {
        alignItems: 'first baseline',
        color:'#1473e6',
        fontSize: '20px',
        zIndex:9
    },
    edvesAca2: {
        alignItems: 'first baseline',
        color:'#fff',
        fontSize: '20px',
    },
    navInc2:{
        background: '#0e5e9e',
        padding: '1rem 5rem',
        fontSize: '15px',
        marginBottom: 0
    }
  }