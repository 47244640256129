import React, { Component } from 'react'
import { Icon , Button,  Table, Image, Header, List, Modal, Input, Popup} from 'semantic-ui-react'
import {
  ToastContainer,
  toast
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './prepareExam.css';
import ExamStore from '../../stores/exam';
import Arrow from '../assets/img/minAssets/arrow.png'
import { observer } from 'mobx-react';

const timeoutLength = 2500
@observer
class StepThree extends Component{

  state = { open: false, isOpen: false  }

  show = (size) => () => this.setState({ size, open: true })
  close = () => this.setState({ open: false })
  saveAndContinue = (e) => {
    e.preventDefault()
   // this.props.nextStep()
    this.props.handleStartExam(e)
    // let told = this.textInput.current.value this.textInput.value
    // alert(told)
}
BackContinue = (e) => {
  e.preventDefault()
  this.props.prevStep()
}
handleOpen = () => {
  this.setState({ isOpen: true })

  this.timeout = setTimeout(() => {
    this.setState({ isOpen: false })
  }, timeoutLength)
}

handleClose = () => {
  this.setState({ isOpen: false })
  clearTimeout(this.timeout)
}
    notify = () => toast("Invitation Send");

// 
  render(){ 

    const { open, size } = this.state
    // Globesubject
    // const { open, dimmer } = this.state
    // let subject = this.props.values.subject
  //   let subjectDetails = this.props.values.Globesubject
  // const MapSubject = subjectDetails.map(singleData => {
  //   let subjectDecode = singleData.split("/");
  //   return <List.Item> <List.Content floated='right'> <Button basic color='green' Key={subjectDecode[1]} onClick={this.saveAndContinue}>start</Button>  </List.Content>  <List.Content>{subjectDecode[0]}</List.Content> </List.Item>

  // })
    return ( 
      <div className="centerFlex allWrap">
    <div style={styles.DivWrapper2} className="centerFlex pad0 alignFlex blueLayer pad4">
            <div className="DivWrapper2Wrap2 centerFlex alignCenterFlex fullHeight" style={styles.DivWrapper2Wrap}>
            <div className="centerSty1 flexDisplayColume alignCenterFlex">
            <div className="font500 fullWidth mgBtm3 twentyFontSize centerFlex">These are the 3 steps you need to take to start your test</div>
            <div className="fullWidth centerFlex justifyFlexSpaceBtw">
          <div className="flexDisplayColume alignCenterFlex Rectangle-113 justifyFlex">
                <span className="thirtyFontSize font500 lHg0">01</span>
                <span className="fourteenFontSize lHg2 txtmedia1">Curriculum & Class</span>
                {/* <span className="nineFontSize lHg1">
                This is going to help you choose the education system you want to follow
                </span> */}
          </div>
          <div className="centerFlex alignFlex"> <Icon name='play' className="ActiveStep" /></div>
          <div className="flexDisplayColume alignCenterFlex Rectangle-113 justifyFlex">
                <span className="thirtyFontSize font500 lHg0">02</span>
                <span className="fourteenFontSize lHg2 txtmedia1">Subject & Topics</span>
                {/* <span className="nineFontSize lHg1">
                This is going to help you select the subject and topics you want to answer
                </span> */}
          </div>
          <div className="centerFlex alignFlex"> <Icon name='play' className="ActiveStep" /></div>
          <div className="flexDisplayColume alignCenterFlex Rectangle-113 Active113 justifyFlex">
                <span className="thirtyFontSize font500 lHg0">03</span>
                <span className="fourteenFontSize lHg2 txtmedia1">Confirmation</span>
                {/* <span className="nineFontSize lHg1">
                This is when you will get to confirm your entry before you start the test
                </span> */}
          </div>
        </div>

          <div style={{padding: "5% 0px",}}>
          <div className="twentyFontSize" style={{ marginBottom: '3%'}}>Kindly confirm your selection. Your exam will start now </div>
            <div className="ConfirmBox flexDisplayColume pd1">
            <div className="centerFlex"> 
                  <div className="leftFlex pd1 sideTxt tdsty sixteenFontSize bluecolor font500"> Curriculum </div>
                  <div className="leftFlex pd1 equalWidth2 twelveFontSize"> {this.props.values.curriculum} </div>
                 </div>
                <div className="centerFlex"> 
                  <div className="leftFlex pd1 sideTxt tdsty sixteenFontSize bluecolor font500"> Class </div>
                  <div className="leftFlex pd1 equalWidth2 twelveFontSize"> {this.props.values.grade} </div>
                 </div>
                <div className="centerFlex"> 
                  <div className="leftFlex pd1 sideTxt tdsty sixteenFontSize bluecolor font500"> Subject </div>
                  <div className="leftFlex pd1 equalWidth2 twelveFontSize">{this.props.values.subject}  </div>
                 </div>
                
                <div className="centerFlex"> 
                  <div className="leftFlex pd1 sideTxt tdsty sixteenFontSize bluecolor font500"> Topic(s) </div>
                  <div className="leftFlex pd1 pdL equalWidth2 twelveFontSize"> {this.props.values.topic} </div>
                 </div>
            </div>
       </div>
       <div className="fullWidth centerFlex justifyFlexSpaceBtw  alignCenterFlex">
       <Button onClick={this.show('tiny')} className="Rectangle-118 bluecolor sixteenFontSize font500" style={{marginBottom: '10px'}}>INVITE USER </Button>  

       <Button onClick={this.BackContinue} className="Rectangle-118 bluecolor sixteenFontSize font500" style={{marginBottom: '10px'}}>BACK </Button>  
       
       <Button onClick={this.saveAndContinue} className="Rectangle-114 whiteColor sixteenFontSize font500" style={{ marginBottom: '10px'}}>Start Exam</Button>

       </div>
       </div>
       </div>
       <Modal size={size} open={open} onClose={this.close}>
          <Modal.Header>Invite User</Modal.Header>
          <Modal.Content>
            <p>Enter User email</p>
            <Input className="fullWidth" placeholder='email...' />
            <div className="centerFlex fullWidth mgTop1">
            <p>https://lo/v15659garde2SocStu1_m9l8zk</p>

            <Popup
            trigger={<Icon className="copy" name="copy"/>}
            content={`Copied!!!`}
            on='click'
            open={this.state.isOpen}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            position='top right'
          />
            
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={this.close}>Close</Button>
            <Button
            onClick={this.close}
              positive
              icon='checkmark'
              labelPosition='right'
              content='Proceed'
            />
          </Modal.Actions>
        </Modal>
{/* <Modal dimmer={dimmer} open={open} onClose={this.close} size={this.state.size}>
          <Modal.Header>Select Subject to start with</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
                <List divided verticalAlign='middle'>
              {MapSubject}
                
        </List>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={this.close}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal> */}
  </div>
  </div>
)
}
}

export default StepThree
