import React, { Component } from 'react'
import { Menu, Button, Icon, Dropdown, Image} from 'semantic-ui-react'
import styles from './landingNav.css.js';
import Scrollchor from 'react-scrollchor';
import Logo from '../assets/img/logo.png'
import {isSignedIn,getUsertype,getMyRole,hasChangePwd} from '../../auth';
import { LogoutNow } from "../../dependency/UtilityFunctions";

// import { BrowserRouter } from 'react-router-dom';

import { Link, BrowserRouter, Route } from 'react-router-dom'
export default class Exam extends Component {
  
  state = { activeItem: 'How it worksss', loggedIn: 'false', }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })
  
  componentDidMount() {
    let loginstate=isSignedIn();
    console.log(loginstate + 'tom')
    // if(loginstate === true){
    //    this.setState({ 
    //     loggedIn: loginstate
    //   });
    //   alert(this.state.loggedIn)
    //   console.log(this.state.loggedIn)
    // };
      // this.setState({ 
      //   open: loginstate ===true ? false:true 
      // });
      // alert(this.state.loggedIn)
      // console.log()
    }
    handleLogout = () => {
      LogoutNow();
      // this.props.logoutUser(this.props.history);
    };
    // setLogin(params) {
    //   this.setState({ 
    //     loggedIn: loginstate
    //   });
    // }
  render() {
    let loginstate=isSignedIn();
    // return (loginstate)
      //  this.setState({ 
      //   loggedIn: loginstate
      // });
    const { activeItem } = this.state 
    const userImg = <Image avatar className="noMargin" src='https://react.semantic-ui.com/images/avatar/small/helen.jpg' />;
    // const userContent = null;
     
    

    
    return (
      // <BrowserRouter> <Link to='/home/#section1'></Link>
      <Menu secondary style={styles.navInc3}>
        <Menu.Item name='Edves Academy' className="noPadding" style={styles.edvesAca}><Link to="/"><img style={{width: 130}} src={Logo} alt="LO" /></Link></Menu.Item>
        <Menu.Menu position='right' className="zIndex3">
          {/* <Scrollchor to="#samplecode" className="loNav centerFlex alignCenterFlex"><Menu.Item className="navSTy" name='How it works' active={activeItem === 'How it works'} onClick={this.handleItemClick} className=""/></Scrollchor>
          <Scrollchor to="#Features" className="loNav centerFlex alignCenterFlex"><Menu.Item name='Features' active={activeItem === 'Features'} onClick={this.handleItemClick} className="" /></Scrollchor>
          <Scrollchor to="#Curriculum" className="loNav centerFlex alignCenterFlex"><Menu.Item name='Curriculum' active={activeItem === 'Curriculum'} onClick={this.handleItemClick} className="" /></Scrollchor>
          <Scrollchor to="#Pricing" className="loNav centerFlex alignCenterFlex"><Menu.Item name='Pricing' active={activeItem === 'Pricing'} onClick={this.handleItemClick} className="" /></Scrollchor> */}
          {/* <Link style={{display:'flex'}} to={'/exam'}><Menu.Item ><Button positive>TAKE A TEST</Button></Menu.Item></Link> */}
          <Menu.Item >
             {
               loginstate == true ?
               // return(
               <Dropdown  className="displayflex menuImage centerFlex alignFlex" text={userImg}>
                 <Dropdown.Menu>
                 <Link to={'/'}>  <Dropdown.Item text='Home' /></Link>
                   <Dropdown.Item onClick={() => this.handleLogout()} text='Logout'/>
                 </Dropdown.Menu>
               </Dropdown>
               // )
               :
               ''
             } 
             </Menu.Item>
        </Menu.Menu>
      </Menu>
      // </BrowserRouter>
    )
  }
  
}
