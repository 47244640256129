export default {
    DivWrapper: {
          minHeight: 'calc(100vh - 100px)',
          // borderLeft: 'solid thin #ccc',
          paddingTop: '15px',
          width: '100vw',
          padding: '10px',
          
       },
    DivWrapper2: {
          minHeight: '100vh',
          // paddingTop: '15px',
          width: '100vw',
          // padding: '10px',
          
       },
    DivWrapper2_2: {
          minHeight: '100vh',
          width: '100vw',
          maxHeight: '100vh',
       },
       DivWrapper2Wrap:{
         width: '100%',
        //  height: '70%',
         background: '#fff',
         padding: '3% 0% '
       },
       DivWrapper3Wrap:{
         width: '100%',
        //  height: '70%',
         background: '#fff',
         padding: '1%'
       },
       selectWrap :{
        //  width: '45%',
        marginTop: '3%'
       },
       selectWrap2:{
          marginBottom: "3.5%",
       },
       iconShare: {
         marginLeft: '5% 4px',
         fontSize: '13px',
       },
       minWrap:{
        borderRadius: '50%',
        height: '60px',
        width: '60px',
        border: 'solid 1px #0e5e9e',
        color:'#0e5e9e',
       },
       minWrap2:{
        borderRadius: '50%',
        height: '140px',
        width: '140px',
        border: 'solid 5px #00d280',
        // color:'#0e5e9e',
        margin: '3% 0px',
       },
       examWrap:{
        textAlign: 'left',
        marginTop: '4%',
        minWidth: '55%',
       },
       examWrap2:{
        textAlign: 'left',
        marginTop: '4%',
        minWidth: '100%',
       },
       examWrapGet:{
        textAlign: 'left',
        width: '100%',
        marginTop:5,
       },
       examFooter: {
        margin: '5% 0px',
       },
       wrongQ:{
        background: '#fff',
        // color: '#fff'
        borderBottom: 'solid 1px #cccccc40',
        padding: '0em .7em',
       },
       correctQ:{
        background: '#fff',
        // color: '#fff'
        padding: '0em .7em',
       },
    timeReader:{
      fontSize: '25px',
      color:'#d22323',
    },
    inputSkip:{
      width:' 90px',
      background: 'transparent'
    }

      
     }