import _ from 'lodash'
import React, { Component } from 'react'
import { observer } from 'mobx-react';
import { Link, BrowserRouter, Route } from 'react-router-dom'

import ExamStore from '../../stores/exam';
import { Button,  Label, List, Icon} from 'semantic-ui-react'
import styles from './prepareExam.css';
import { withRouter } from 'react-router-dom';
@observer
class Correction extends Component{
  saveAndContinue = (e) => {
    e.preventDefault()
    this.props.nextStep()
}
Mereload=(e) => {
  // e.preventDefault()
  this.props.history.push('/dashboard')
  window.location.reload();
}
componentDidMount(){
  ExamStore.DisplayQuestion(ExamStore.currentIndex);
}
  render(){ 
  let examCorrection =  ExamStore.Allcorrection;
  console.table(examCorrection)
  let itemkeys = -1;
  let itemCount = 0;
  const processCorrection = examCorrection.map(singleData => {
    
    itemkeys += 1;
    itemCount += 1;
    // console.log(singleData.expectedAnwser + '<br>')
    console.log(singleData.isCorrect + '<rb>')

    if(singleData.isCorrect == ''){
    return (  <div style={styles.examWrap2} className="ExamWrap" key={itemkeys.toString()}>
    <p>{itemCount} : {singleData.question}</p>
  
         <List divided selection className="correctWrap">
         <List.Item className="displayflex alignFlex" style={styles.wrongQ}>
         <Icon name='close' className="correctionIcon color1 pad9"/><div className="QSTsty"> {singleData.userAnwserName}</div>
         </List.Item>
         <List.Item className="displayflex alignFlex" style={styles.correctQ}>
         <Icon name='check' className="correctionIcon color2 pad9"/> <div className="QSTsty">{singleData.expectedAnwser}</div>
         </List.Item>
     </List>
    
   
    </div>
    );
    }
  })
//   console.log(examCorrection)
// processCorrection
    // equalWidth2 
    return ( 
      <div className="centerFlex allWrap">
        <div style={styles.DivWrapper2} className="centerFlex pad0 alignFlex blueLayer pad4">
            <div className="DivWrapper2Wrap2 centerFlex" style={styles.DivWrapper3Wrap}>
    <div style={styles.DivWrapper} className="flexDisplayColume">
    <div className='leftFlex'>
            <div className='centerFlex alignFlex scoreTxt font500'>CORRECTIONS</div>
        </div>
        <div style={{width:'70%', margin:'0 auto',}}>
       <div className="flexDisplayColume alignCenterFlex">
       {processCorrection}
        </div>
        <div className='centerFlex' style={{maxWidth:'100%',marginTop: '15px'}}>
            <div style={{minWidth:'100%'}}>
                <div className="fullWidth centerFlex" style={styles.examFooter}>
                <div className="equalWidth leftFlex alignFlex fontBold">1</div>
                <div className="equalWidth rightFlex alignFlex"><Button onClick={this.Mereload}  className="Rectangle-114 whiteColor sixteenFontSize font500">Continue</Button></div>
                </div>
            
            </div>
        
        </div>
        
        </div>
  </div>
  </div>
  </div>
  </div>
)
}
}

export default withRouter(Correction)
