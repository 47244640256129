import React, { Component } from 'react';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import StepThree from './stepThree';
import StepFour  from './exam'
import Score from './score'
import Correction from './correction'
import ExamStore from '../../stores/exam';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { observer } from 'mobx-react';


// import DetailsFour from './DetailsFour';
// import "react-tabs/style/react-tabs.css";
// import "./linkcard.css";

 

@observer
class PrepareExam extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            step: 1,
            curriculum: '',
            grade: '',
            subject: '',
            Globesubject: '',
            topic: [],
            counter: '',
            answer: '',
        };
      }
      componentDidMount() {
        ExamStore.updateNavar('loggedIn');
   
      }


      nextStep = () => {
        // const { step } = this.state
        // this.setState({
        //     step : step + 1
        // })
        ExamStore.nextStep()
    }
    prevStep = () => {
    //   const { step } = this.state
    //   this.setState({
    //       step : step - 1
    //   })
    ExamStore.prevStep();
  }
//   this._handleChangeSelect
  handleChange = input => event => {
      this.setState({ [input] : event.target.value })
  }
  _handleChangeSelect = (Option) => {
      
    //   alert(JSON.stringify(Option))
    //   alert(Option)
    let Selectvalue2 = Option.split("/");
    // alert(Selectvalue2[1]);
    let Selectname = Selectvalue2[0];
    let Selectvalue = Selectvalue2[1];
//     // this[type][name] = stateOption;
//     // console.log(`Option selected:`, selectedOption);
    this.setState({
        curriculum: Selectname

    })
   ExamStore._handleChangeSelect(Selectvalue);
}
_handleChangeSelectClass = (Option) => {
    let Classvalue2 = Option.split("/");
    let Classtname = Classvalue2[0];
    
    let Classvalue = Classvalue2[1];
    // alert(Classvalue);
    this.setState({
        grade: Classtname
    })
    
   ExamStore._handleChangeSelectClass(Classvalue);
}
_handleChangeSelectSubject = (Option) => {
    let Subjevtvalue2 = Option.split("/");
    let Subjectname = Subjevtvalue2[0];
    
    let SUbjectvalue = Subjevtvalue2[1];
    // alert(SUbjectvalue);
    this.setState({
        subject: Subjectname,
        Globesubject: Subjectname
    })
    
   ExamStore._handleChangeSelectSubject(SUbjectvalue);
    // alert();
    // let myarray= [];
    // let myarraySubjectvalue= [];
    // let G_Subject = [];
    // let tru = Option;
    // alert(tru.length);
    // for (let value of tru) {
    //     let Subjectvalue2 = Option.split("/");
    //     let Subjectname = Subjectvalue2[0];
    //     let Subjectvalue = Subjectvalue2[1];
    //     // alert(Subjectvalue2[1]);
    //     myarray.push(Subjectname + ", ")
    //     myarraySubjectvalue.push(Subjectvalue)
    //     G_Subject.push(value)
    // }

//     ExamStore._handleChangeSelectSubject(myarraySubjectvalue);

//     this.setState({
//     subject: myarray,
//     Globesubject: G_Subject
// })
  
}
_handleChangeSelecttopic = (Option) => {
    // alert(Option)
    let myarray= [];
    let myarrayTopicvalue= [];
    let tru = Option;
    for (let value of tru) {
        let Topicvalue2 = value.split("/");
        let Topicname = Topicvalue2[0];
        let Topicvalue = Topicvalue2[1];
        // console.table(Topicvalue)
        myarray.push(Topicname + ", ")
        myarrayTopicvalue.push(Topicvalue)


    }

    this.setState({
    topic: myarray
})
ExamStore._handleChangeSelecttopic(myarrayTopicvalue);
   

// let Topicvalue2 = Option.split("/");
// let Subjectname = Topicvalue2[0];
// let myarrayTopicvalue= [];
// let myarray= [];
// let Topicvalue = Topicvalue2[1];
// myarray.push(Subjectname + ", ")
// myarrayTopicvalue.push(Topicvalue)
// // alert(SUbjectvalue);
// this.setState({
//     topic: myarray
// })

// ExamStore._handleChangeSelecttopic(myarrayTopicvalue);
}
 
_handleChangeSelecttopicAmount = (Option) => {
    let Selectvalue = Option;
    let Selectvalue2 = Option.value;
    // this.setState({
    //     [grade]: Option
    // })
    // alert(Option)
    
   ExamStore._handleChangeSelecttopicAmount(Option);
}
_handleGetExamCorrection = () => {
    // let Selectvalue = pram.value;
    // console.log(Selectvalue + "yes")
    // // let Selectvalue2 = Option.value;
    // // this.setState({
    // //     [grade]: Option
    // // })
    
   ExamStore._handleGetAllExamCorrection();
}
_handleChangeStartExam = (e) => {

   ExamStore._handleChangeStartExam();
}
_handleChangeSaveExam = (e) => {

    ExamStore._handleChangeSaveExam();
 }
// _handleChangeStartExam
 
      
  render() {
   // const {step} = this.state;
        const { curriculum, grade, subject, Globesubject, topic, counter, answer } = this.state;
        const values = { curriculum, grade, subject, Globesubject, topic, counter, answer };
        switch(ExamStore.FormSteps) {
        case 1:
            return <StepOne 
                    nextStep={this.nextStep} 
                    handleChange = {this.handleChange}
                    handleChangeSelect={this._handleChangeSelect}
                    handleChangeSelect2={this._handleChangeSelectClass}
                    values={values}
                    />
        case 2:
            return <StepTwo 
                    // nextStep={ExamStore.nextStep}
                    // nextStep={ExamStore.prevStep}
                    nextStep={this.nextStep} 
                    prevStep={this.prevStep}
                    handleChange = {this.handleChange}
                    handleChangeSelect3={this._handleChangeSelectSubject}
                    handleChangeSelect4={this._handleChangeSelecttopic}
                    handleChangeSelect5={this._handleChangeSelecttopicAmount}
                    values={values}
                    />
        case 3:
            return <StepThree 
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleStartExam={this._handleChangeStartExam}
                    values={values}
                    />
        case 4:
            return <StepFour
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleSaveExam={this._handleChangeSaveExam}
                    values={values}
                    />
        case 5:
            return <Score
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleExamCorrection={this._handleGetExamCorrection}
                    values={values}
                    />
        case 6:
            return <Correction
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    values={values}
                    />
        }
        

}
}

export default PrepareExam;