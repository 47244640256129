// app/auth.js
import ls from 'local-storage'
 
export const USER_KEY = "auth-demo-key";
 

 
export const  isSignedIn = () => {
  getMyRole();
 let ReturnedString=ls.get('Loggedinstate') || {};
 
 if(ReturnedString.isloggedin===true){
  return ReturnedString.isloggedin;
 }
 else{
    // alert(JSON.stringify(ReturnedString))

  return false;
 }

}
 
export const  getMyRole = () => {
 let ReturnedString=ls.get('Loggedinstate') || {};
 
 if(ReturnedString.isloggedin===true){
  let data=ls.get('loggedinUserdetails') || {};
  console.log(data)
return data;
 }
 else{
    // alert(JSON.stringify(ReturnedString))

  return false;
 }

}
export const  getloginFullDetails_____ = () => {
 let ReturnedString=ls.get('Loggedinstate') || {};
 
 if(ReturnedString.isloggedin===true){
  let data=ls.get('loggedinUserdetails') || {};
  console.log(data)
return data;
 }
 else{
    // alert(JSON.stringify(ReturnedString))

  return false;
 }

}
export const  hasChangePwd = () => {
 let ReturnedString=ls.get('Loggedinstate') || {};
 
 if(ReturnedString.isloggedin===true){
  let data=ls.get('loggedinUserdetails') || {};
  console.log(data.ResetResponse +'kikikikikikikikiki')
return data.ResetResponse;
 }
 else{
    // alert(JSON.stringify(ReturnedString)) 

  return false;
 }

}

export const  getAccessToken = () => {
    let ReturnedString=ls.get('Loggedinstate') || {};
    // alert(JSON.stringify(ReturnedString))
    if(ReturnedString.isloggedin===true){
     return 'Bearer '+ReturnedString.accessToken;
    }
    else{
     return '';
    }
   
   }
export const  getloginFullDetails = () => {
    let ReturnedString=ls.get('Loggedinstate') || {};
    // alert(JSON.stringify(ReturnedString))
    if(ReturnedString.isloggedin===true){
     return ReturnedString;
    }
    else{
     return '';
    }
   
   }
export const  getRefreshToken = () => {
    let ReturnedString=ls.get('Loggedinstate') || {};
    // alert(JSON.stringify(ReturnedString))
    if(ReturnedString.isloggedin===true){
     return ReturnedString.refresh_token;
    }
    else{
     return '';
    }
   
   }
export const  getUsertype = () => {
    let ReturnedString=ls.get('Loggedinstate') || {};
     console.log(ReturnedString)
    //  alert(JSON.stringify(ReturnedString))
    if(ReturnedString.isloggedin===true){
     return ReturnedString.usertype;
    }
    else{
     return '';
    }
   
   }
