// import _ from 'lodash'
import React, { Component } from 'react'
import { Grid , Button, Segment, List, Image, Table, Icon, Modal, Header, Tab} from 'semantic-ui-react'
import User3 from '.././assets/img/user3.jpeg';
import ExamStore from '../../stores/exam';
import { observer } from 'mobx-react';
import DashNav from '../navs/dashboardNav'
import Sidebar from '../navs/sidebar'
import ProfileBar from '../navs/profileBar'
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import Grade1 from '../assets/img/minAssets/grade.png'
import DashToggle from '../assets/img/minAssets/dashToggle.png'
import Cup from '../assets/img/minAssets/cup.png'
import User1 from '../assets/img/user1.jpeg'
import Fire from '../assets/img/minAssets/fire.png'
import styles from '../ranking/ranking.css';
import { Progress } from 'semantic-ui-react'
// import Canvas from 'react-canvas-js'
const square = { width: 50, height: 50, marginBottom: 0, padding: '0px !important', borderRadius: '50%' }
const panes = [
  {
    menuItem: 'Curiculum',
    render: () => <Tab.Pane attached={false}><ButtonToolbar className="mgBtm2">
    <ToggleButtonGroup className="btnGroup1" type="radio" name="options" defaultValue={1}>
    <ToggleButton 
      className="Rectangle-118 bluecolor sixteenFontSize mgRgh mgBtm1 centerFlex alignFlex "
    key='1'
    value='British'
    >
      British
    </ToggleButton>
    <ToggleButton 
      className="Rectangle-118 bluecolor sixteenFontSize mgRgh mgBtm1 centerFlex alignFlex "
    key='1'
    value='British'
    >
      Nigeria
    </ToggleButton>
    <ToggleButton 
      className="Rectangle-118 bluecolor sixteenFontSize mgRgh mgBtm1 centerFlex alignFlex "
    key='1'
    value='British'
    >
      America
    </ToggleButton>
      
    </ToggleButtonGroup>
  </ButtonToolbar></Tab.Pane>,
  },
  {
    menuItem: 'Class',
    render: () => <Tab.Pane attached={false}>Tab 2 Content</Tab.Pane>,
  },
  {
    menuItem: 'Subject',
    render: () => <Tab.Pane attached={false}>Tab 3 Content</Tab.Pane>,
  },
  {
    menuItem: 'Topic',
    render: () => <Tab.Pane attached={false}>Tab 4 Content</Tab.Pane>,
  },
]
@observer
class Dashboards extends Component{

  constructor(props) {
    super(props);
  this.state = { 
     open: false,
     }
    //  this.open = this.open.bind(this);
     }

     show = (dimmer) => () => this.setState({ dimmer, open: true })
     close = () => this.setState({ open: false })

//   saveAndContinue = (e) => {
//     e.preventDefault()
//     this.props.nextStep()
// }
componentDidMount() {
   
    ExamStore.updateNavar('loggedIn');

  }
  render(){ 
    const { open, dimmer } = this.state
    let pathNAme = this.props.location.pathname
    // alert(pathNAme);
	
    return ( 
    <div style={styles.RankingWrap} className="flexDisplayColume DashForebg">
       <div className="flexDisplayColume alignCenterFlex DashForebgInner">
           <div className="fullWidth dashNaveSty"><DashNav/></div>
           <div className="fullWidth flexDisplay spa234">
               {/* sidebar */}
                <Sidebar/>

               {/* sidebar */}
         {/* <div style={styles.tableWrap} className="screenIner"> */}
         <div className="screenIner">
           <div className="centerFlex mgBtm3">
           <div className="equalWidth2 leftFlex font500 TewntyFiveFontSize bluecolor"> LEADERS BOARD</div>
           <div className="equalWidth2 rightFlex"> <img  onClick={this.show(true)} className="toggleClicker" src={DashToggle} alt="toggle" /> </div>
           </div>
         <Grid stackable columns={3} className="justifyFlex mystakable">
    <Grid.Column className="columRank">
      <Segment className="noElement">
        <div className="userRankingWrap flexDisplayColume alignCenterFlex justifyFlex">
         <img className="rankingImg" src={User3} alt=""/>
         <img className="rankingImg2" src={Cup} alt=""/>
         <p className="rankingText1 sixteenFontSize whiteColor noMargin mgBtm4">Tomzy Adex</p>
         <p className="rankingText1 sixteenFontSize whiteColor noMargin mgBtm4">13 Years Old</p>
         <p className="rankingText1 sixteenFontSize whiteColor noMargin mgBtm4">JSS1</p>
         <p className="rankingText1 sixteenFontSize whiteColor noMargin mgBtm4">EDVES INT’L SCHOOL</p>
         <p className="rankingText2 thirtyFontSize whiteColor noMargin">1<span className="rankingText1super">st</span></p>
         <div className="centerFlex RankingcardFooter alignCenterFlex">
         <div className="centerFlex sixteenFontSize equalWidth2_2 bluecolor bRL">Questions 100</div>
         <div className="centerFlex sixteenFontSize equalWidth2 bluecolor">Score 95</div>
         </div>
        </div>
      </Segment>
    </Grid.Column>
    <Grid.Column className="columRank">
      <Segment className="noElement">
        <div className="userRankingWrap flexDisplayColume alignCenterFlex justifyFlex">
         <img className="rankingImg" src={User3} alt=""/>
         <img className="rankingImg2" src={Cup} alt=""/>
         <p className="rankingText1 sixteenFontSize whiteColor noMargin mgBtm4">Aderanti Tomiwa</p>
         <p className="rankingText1 sixteenFontSize whiteColor noMargin mgBtm4">14 Years Old</p>
         <p className="rankingText1 sixteenFontSize whiteColor noMargin mgBtm4">JSS1</p>
         <p className="rankingText1 sixteenFontSize whiteColor noMargin mgBtm4">LiLLY SCHOOL</p>
         <p className="rankingText2 thirtyFontSize whiteColor noMargin">2<span className="rankingText1super">nd</span></p>
         <div className="centerFlex RankingcardFooter alignCenterFlex">
         <div className="centerFlex sixteenFontSize equalWidth2_2 bluecolor bRL">Questions 100</div>
         <div className="centerFlex sixteenFontSize equalWidth2 bluecolor">Score 93</div>
         </div>
        </div>
      </Segment>
    </Grid.Column>
    <Grid.Column className="columRank">
      <Segment className="noElement">
        <div className="userRankingWrap flexDisplayColume alignCenterFlex justifyFlex">
         <img className="rankingImg" src={User3} alt=""/>
         <img className="rankingImg2" src={Cup} alt=""/>
         <p className="rankingText1 sixteenFontSize whiteColor noMargin mgBtm4">Tope Moses</p>
         <p className="rankingText1 sixteenFontSize whiteColor noMargin mgBtm4">13 Years Old</p>
         <p className="rankingText1 sixteenFontSize whiteColor noMargin mgBtm4">JSS1</p>
         <p className="rankingText1 sixteenFontSize whiteColor noMargin mgBtm4">FRAB'S INT’L SCHOOL</p>
         <p className="rankingText2 thirtyFontSize whiteColor noMargin">3<span className="rankingText1super">rd</span></p>
         <div className="centerFlex RankingcardFooter alignCenterFlex">
         <div className="centerFlex sixteenFontSize equalWidth2_2 bluecolor bRL">Questions 100</div>
         <div className="centerFlex sixteenFontSize equalWidth2 bluecolor">Score 92</div>
         </div>
        </div>
      </Segment>
    </Grid.Column>
  </Grid>
  <Table celled striped responsive>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell className="sixteenFontSize font500">S/N</Table.HeaderCell>
        <Table.HeaderCell className="sixteenFontSize font500">NAME</Table.HeaderCell>
        <Table.HeaderCell className="sixteenFontSize font500">AGE</Table.HeaderCell>
        <Table.HeaderCell className="sixteenFontSize font500">CLASS</Table.HeaderCell>
        <Table.HeaderCell className="sixteenFontSize font500">SCHOOL</Table.HeaderCell>
        <Table.HeaderCell className="sixteenFontSize font500">NO OF QUESTION</Table.HeaderCell>
        <Table.HeaderCell className="sixteenFontSize font500">SCORE</Table.HeaderCell>
      </Table.Row>
      <Table.Row>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      <Table.Row>
        <Table.Cell collapsing> 1 </Table.Cell>
        <Table.Cell>Adetola Muhammed</Table.Cell>
        <Table.Cell collapsing> 13 Years </Table.Cell>
        <Table.Cell collapsing> JSS1 </Table.Cell>
        <Table.Cell collapsing> Grace high School </Table.Cell>
        <Table.Cell collapsing> 100 </Table.Cell>
        <Table.Cell collapsing> 82 </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell collapsing> 2 </Table.Cell>
        <Table.Cell>Niyi Saram</Table.Cell>
        <Table.Cell collapsing> 13 Years </Table.Cell>
        <Table.Cell collapsing> JSS1 </Table.Cell>
        <Table.Cell collapsing> Steady School </Table.Cell>
        <Table.Cell collapsing> 100 </Table.Cell>
        <Table.Cell collapsing> 81 </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell collapsing> 3 </Table.Cell>
        <Table.Cell>Joy Andrew</Table.Cell>
        <Table.Cell collapsing> 13 Years </Table.Cell>
        <Table.Cell collapsing> JSS1 </Table.Cell>
        <Table.Cell collapsing> Fountain School </Table.Cell>
        <Table.Cell collapsing> 100 </Table.Cell>
        <Table.Cell collapsing> 80 </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell collapsing> 4 </Table.Cell>
        <Table.Cell>Love Bolsa</Table.Cell>
        <Table.Cell collapsing> 12 Years </Table.Cell>
        <Table.Cell collapsing> JSS1 </Table.Cell>
        <Table.Cell collapsing> Edves School </Table.Cell>
        <Table.Cell collapsing> 99 </Table.Cell>
        <Table.Cell collapsing> 80 </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell collapsing> 5 </Table.Cell>
        <Table.Cell>Dewumi Adams</Table.Cell>
        <Table.Cell collapsing> 14 Years </Table.Cell>
        <Table.Cell collapsing> JSS1 </Table.Cell>
        <Table.Cell collapsing> King's School </Table.Cell>
        <Table.Cell collapsing> 100 </Table.Cell>
        <Table.Cell collapsing> 75 </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell collapsing> 6 </Table.Cell>
        <Table.Cell>Jamiu Adewale</Table.Cell>
        <Table.Cell collapsing> 13 Years </Table.Cell>
        <Table.Cell collapsing> JSS1 </Table.Cell>
        <Table.Cell collapsing> Eben Noble </Table.Cell>
        <Table.Cell collapsing> 100 </Table.Cell>
        <Table.Cell collapsing> 74 </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell collapsing> 7 </Table.Cell>
        <Table.Cell>Jude Bello</Table.Cell>
        <Table.Cell collapsing> 14 Years </Table.Cell>
        <Table.Cell collapsing> JSS1 </Table.Cell>
        <Table.Cell collapsing> Blessed Grace Land </Table.Cell>
        <Table.Cell collapsing> 100 </Table.Cell>
        <Table.Cell collapsing> 70 </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
         </div>
        {/* Profile Bar */}
        <ProfileBar/>
        {/* Profile Bar */}
         </div>
       </div>
       <Modal 
        // open={open} 
        centered={false}
        dimmer={dimmer} open={open} onClose={this.close}
          closeOnEscape={false}
          // closeOnDimmerClick={false}
          size={'small'}
        >
    <Modal.Content className="flexDisplayColume whiteBackground minheight">
      <div className="centerFlex font500 sixteenFontSize mgBtm3">Nigerial + JSS + Social Studies + Life Studies</div>

      <Tab menu={{tabular: false }} panes={panes} className="tabMysty" />
    </Modal.Content>
    <Modal.Actions className="centerFlex alignCenterFlex tabMystyFooter">
      <Button className="centerFlex alignFlex btnBoxShadow1" style={styles.checkNow}>
        Check Now
      </Button>
     
    </Modal.Actions>
  </Modal>
        
        

  </div>
)
}
}

export default Dashboards
