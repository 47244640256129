import React, { Component } from 'react'
import { Dropdown, Button,  Icon } from 'semantic-ui-react'
import { observer } from 'mobx-react';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import Arrow from '../assets/img/minAssets/arrow.png'

import ExamStore from '../../stores/exam';

import styles from './prepareExam.css';
const options = [
  { key: 'angular', text: 'Angular', value: 'angular' },
  { key: 'css', text: 'CSS', value: 'css' },
  { key: 'design', text: 'Graphic Design', value: 'design' },
  { key: 'ember', text: 'Ember', value: 'ember' },
  { key: 'html', text: 'HTML', value: 'html' },
  { key: 'ia', text: 'Information Architecture', value: 'ia' },
  { key: 'javascript', text: 'Javascript', value: 'javascript' },
  { key: 'mech', text: 'Mechanical Engineering', value: 'mech' },
  { key: 'meteor', text: 'Meteor', value: 'meteor' },
  { key: 'node', text: 'NodeJS', value: 'node' },
  { key: 'plumbing', text: 'Plumbing', value: 'plumbing' },
  { key: 'python', text: 'Python', value: 'python' },
  { key: 'rails', text: 'Rails', value: 'rails' },
  { key: 'react', text: 'React', value: 'react' },
  { key: 'repair', text: 'Kitchen Repair', value: 'repair' },
  { key: 'ruby', text: 'Ruby', value: 'ruby' },
  { key: 'ui', text: 'UI Design', value: 'ui' },
  { key: 'ux', text: 'User Experience', value: 'ux' },
]

const curriculumOptions = [
  { key: 'cur1', value: 'Cur1', text: 'Usefulness of Water' },
  { key: 'cur2', value: 'Cur2', text: 'Life of a Plant' },
  { key: 'cur3', value: 'Cur3', text: 'Our culture' },
  { key: 'cur4', value: 'Cur4', text: 'LSchool life' },
  { key: 'cur5', value: 'Cur5', text: 'Top of Element' },
]
const classOptions = [
  { key: 'ss3', value: 's3', text: 'SSS 3' },
  { key: 'ss2', value: 's2', text: 'SSS 2' },
  { key: 'ss1', value: 's1', text: 'SSS 1' },
  { key: 'js1', value: 'j1', text: 'JSS 1' },
  { key: 'js2', value: 'j2', text: 'JSS 2' },
  { key: 'js3', value: 'j3', text: 'JSS 3' },
  { key: 'pry1', value: 'py1', text: 'PRY 1' },
  { key: 'pry2', value: 'py2', text: 'PRY 2' },
  { key: 'pry3', value: 'py3', text: 'PRY 3' },

]
const questionOptions = [
  { key: 'key1', value: '10', text: '10' },
  { key: 'key2', value: '20', text: '20' },
  { key: 'key3', value: '30', text: '30' },
  { key: 'key4', value: '40', text: '40' },
  { key: 'key5', value: '50', text: '50' },
  { key: 'key6', value: '60', text: '60' },
  { key: 'key7', value: '70', text: '70' },
  { key: 'key8', value: '80', text: '80' },
  { key: 'key9', value: '100', text: '100' },

]
@observer
class StepTwo extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      activeItem: 'How it worksss', 
      open: false ,
      setQuestionOpion : []
    }
    this.passSubject = this.passSubject.bind(this);
    this.passTopic = this.passTopic.bind(this);
    this.passQstSize = this.passQstSize.bind(this);
  }
  saveAndContinue = (e) => {
    e.preventDefault()
    if (ExamStore.AllCurriculum === '') {
      //   toast.warn("Select a Curriculum before you proceed", {
      //     position: toast.POSITION.TOP_CENTER
      // });
    }
    else {
      this.props.nextStep()
    }
  }
  BackContinue = (e) => {
    e.preventDefault()
    this.props.prevStep()
}
  passSubject(data){
    this.props.handleChangeSelect3(data)
}
passTopic(data){
  const setQuestionOpion = this.state.setQuestionOpion 
  setQuestionOpion.push(data)
  this.setState({ setQuestionOpion: setQuestionOpion })
    this.props.handleChangeSelect4(this.state.setQuestionOpion )
}
passQstSize(data){
    this.props.handleChangeSelect5(data)
}

  componentDidMount() {
    // ExamStore.GetCurriculumClass();
    // DashboardStore.GetRoles_Bankuser();
    // DashboardStore.getCoperateUsers();
  }

  render() {
    let  Question_Subject=null;
    if(Array.isArray(ExamStore.AllSubject)){
      let itemkeys = -1;
        Question_Subject = ExamStore.AllSubject.map((item) => {
          itemkeys += 1;
          let myData = item.subject + '/' + item.idx;
          // alert(CuriName[0]);
          // alert(myData)
           return (
          <ToggleButton 
          className="Rectangle-118 bluecolor sixteenFontSize mgRgh mgBtm1 centerFlex alignFlex "
        key={itemkeys.toString()} 
        value={myData} 
        onChange={()=>this.passSubject(myData)}
        >
          {item.subject} 
          
        </ToggleButton>
       
          );
         
        });
    }
    else{
      Question_Subject='No Option Available';
    }


    let  Question_Topic=null;
    if(Array.isArray(ExamStore.AllTopic)){
      let itemkeys2 = -1;
      Question_Topic = ExamStore.AllTopic.map((Topicitem) => {
        itemkeys2 += 1;
          // let myData = item.subject + '/' + item.idx;
          let myData2 = Topicitem.topic + '/' + Topicitem.idx;
          // alert(CuriName[0]);
          // alert(myData)
           return (
          <ToggleButton 
          className="Rectangle-118 bluecolor sixteenFontSize mgRgh mgBtm1 centerFlex alignFlex "
        key={itemkeys2.toString()} 
        value={myData2} 
        onChange={()=>this.passTopic(myData2)}
        >
          {Topicitem.topic} 
          
        </ToggleButton>
       
          );
         
        });
    }
    else{
      Question_Topic='No Option Available';
    }

    let  Question_Size=null;
    if(Array.isArray(questionOptions)){
      let itemkeys3 = -1;
      Question_Size = questionOptions.map((qstitem) => {
        itemkeys3 += 1;

           return (
          <ToggleButton 
          className="Rectangle-118 bluecolor sixteenFontSize mgRgh mgBtm1 centerFlex alignFlex "
        key={itemkeys3.toString()} 
        value={qstitem.value} 
        onChange={()=>this.passQstSize(qstitem.value)}
        >
          {qstitem.text} 
          
        </ToggleButton>
       
          );
         
        });
    }
    else{
      Question_Size='No Option Available';
      alert('am not')
    }
    return (
      <div className="centerFlex allWrap">
      <div style={styles.DivWrapper2} className="centerFlex pad0 alignFlex blueLayer pad4">
        <div className="DivWrapper2Wrap2 centerFlex alignCenterFlex fullHeight" style={styles.DivWrapper2Wrap}>
        <div className="centerSty1 flexDisplayColume alignCenterFlex">
        <div className="font500 fullWidth mgBtm3 twentyFontSize centerFlex">These are the 3 steps you need to take to start your test</div>
        <div className="fullWidth centerFlex justifyFlexSpaceBtw">
          <div className="flexDisplayColume alignCenterFlex Rectangle-113 justifyFlex">
                <span className="thirtyFontSize font500 lHg0">01</span>
                <span className="fourteenFontSize lHg2 txtmedia1">Curriculum & Class</span>
                {/* <span className="nineFontSize lHg1">
                This is going to help you choose the education system you want to follow
                </span> */}
          </div>
          <div className="centerFlex alignFlex"> <Icon name='play' className="ActiveStep" /></div>
          <div className="flexDisplayColume alignCenterFlex Rectangle-113 Active113 justifyFlex">
                <span className="thirtyFontSize font500 lHg0">02</span>
                <span className="fourteenFontSize lHg2 txtmedia1">Subject & Topics</span>
                {/* <span className="nineFontSize lHg1">
                This is going to help you select the subject and topics you want to answer
                </span> */}
          </div>
          <div className="centerFlex alignFlex"> <Icon name='play' className="NotActiveStep" /></div>
          <div className="flexDisplayColume alignCenterFlex Rectangle-113 justifyFlex">
                <span className="thirtyFontSize font500 lHg0">03</span>
                <span className="fourteenFontSize lHg2 txtmedia1">Confirmation</span>
                {/* <span className="nineFontSize lHg1">
                This is when you will get to confirm your entry before you start the test
                </span> */}
          </div>
        </div>

        <div className="fullWidth">
          
          <div className="flexColumeflexStart selectWrap padTop1" style={styles.selectWrap}>
            <p style={{textAlign: 'left', fontSize: '16px',}} className='noMargin font500 mgBtm2'>Select Subject</p>
          </div>

          <ButtonToolbar className="mgBtm2">
    <ToggleButtonGroup className="btnGroup1" type="radio" name="options" defaultValue={1}>
      
      {Question_Subject}
    </ToggleButtonGroup>
  </ButtonToolbar>
       </div> 
        <div className="fullWidth">
          
          <div className="flexColumeflexStart selectWrap padTop1" style={styles.selectWrap}>
            <p style={{textAlign: 'left', fontSize: '16px',}} className='noMargin font500 mgBtm2'>Select Topic</p>
          </div>

          <ButtonToolbar className="mgBtm2">
    <ToggleButtonGroup className="btnGroup1" type="checkbox">
      
      {Question_Topic}
    </ToggleButtonGroup>
  </ButtonToolbar>
       </div> 
        <div className="fullWidth">
          
          <div className="flexColumeflexStart selectWrap padTop1" style={styles.selectWrap}>
            <p style={{textAlign: 'left', fontSize: '16px',}} className='noMargin font500 mgBtm2'>Select number of question</p>
          </div>

          <ButtonToolbar className="mgBtm2">
    <ToggleButtonGroup className="btnGroup1" type="radio" name="questionSize">
      
      {Question_Size}
    </ToggleButtonGroup>
  </ButtonToolbar>
       </div> 
    
        {/* <div style={{ paddingBottom: "20px", width: '45%' }}> */}

          {/* <div>
            <div className="flexColumeflexStart">
              <span style={{ textAlign: 'left', fontSize: '16px', marginBottom: '10px' }} className=' fullWidth'>Select your Subject</span>
              <p style={{ textAlign: 'left', fontSize: '13px', color: '#666', marginBottom: '13px' }}>Select a Subject</p>
            </div>
            <Dropdown placeholder='List of Subject' fluid multiple selection options={ExamStore.AllSubject} style={styles.selectWrap2} onChange={(e, value) => this.props.handleChangeSelect3(e, value)} />
          </div> */}
          {/* <div>
            <div className="flexColumeflexStart">
              <p style={{ textAlign: 'left', fontSize: '16px', marginBottom: '10px' }} className='fullWidth'>Select one or more Topic</p>
              <p style={{ textAlign: 'left', fontSize: '13px', color: '#666', marginBottom: '13px' }}>Search or select a topic from the drop down</p>
            </div>
            <Dropdown className="fullWidth" placeholder='List of topic' fluid multiple selection options={ExamStore.AllTopic} style={styles.selectWrap2} onChange={(e, value) => this.props.handleChangeSelect4(e, value)} />
          </div> */}

          {/* <div>
            <div className="flexColumeflexStart">
              <p style={{ textAlign: 'left', fontSize: '16px', marginBottom: '10px' }} className=' fullWidth'>How many question would you like to answer ?</p>
              <p style={{ textAlign: 'left', fontSize: '13px', color: '#666', marginBottom: '13px' }}>Select number of question</p>
            </div>
            <Dropdown placeholder='Number of questions' fluid selection options={questionOptions} style={styles.selectWrap2} onChange={(e, value) => this.props.handleChangeSelect5(e, value)} />
          </div> */}
        {/* </div> */}
        <div className="fullWidth centerFlex ">
          <div className="equalWidth leftFlex alignFlex"><Button onClick={this.BackContinue}  className="Rectangle-115 font500">Back</Button></div>
          <div className="equalWidth rightFlex alignFlex"><Button onClick={this.saveAndContinue} className="Rectangle-114 whiteColor sixteenFontSize font500">Continue</Button></div>
        </div>

  
      </div>
      </div>
      </div>
      </div>
    )
  }
}

export default StepTwo
