import React, { Component } from 'react'
import { Menu, Button, Icon} from 'semantic-ui-react'
import styles from './landingNav.css.js';
import { Link, BrowserRouter, Route } from 'react-router-dom'
import { observer } from 'mobx-react';
import ExamStore from '../../stores/exam';
import Logo from '../assets/img/logoWh.png'


export default
@observer

class MenuExampleSecondary extends Component {
  state = { activeItem: 'How it works' }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render() {
    const { activeItem } = this.state 

    return (
      <Menu secondary style={styles.navInc2}>
        <Menu.Item name='Edves Academy' style={styles.edvesAca}><Link to="/"><img style={{width: 130}} src={Logo} alt="LO" /></Link></Menu.Item>
        <Menu.Menu position='right'>
        
        {/* <Menu.Item name={ExamStore.UserSubject + "(" + ExamStore.UserTopic + ")"} active={activeItem === 'How it workss'} onClick={this.handleItemClick} className="fontbold"/> */}
        {/* <Menu.Item name='Features' active={activeItem === 'Features'} onClick={this.handleItemClick} className="fontbold" /> */}
        {/* <Menu.Item name={"Class:" + ExamStore.UserGrade} active={activeItem === 'Curriculum'} onClick={this.handleItemClick} className="fontbold" /> */}
        {/* <Menu.Item name='Pricing' active={activeItem === 'Pricing'} onClick={this.handleItemClick} className="fontbold" /> */}
        </Menu.Menu>
      </Menu>
    )
  }
  
}
