import _ from 'lodash'
import React, { Component } from 'react'
import Timer from 'react-compound-timer'
import { Grid , Button,  Label, List, Icon, Radio, Segment, Image} from 'semantic-ui-react'
import ExamStore from '../../stores/exam';
import ReactPlayer from 'react-player'
import styles from './prepareExam.css';
import { observer } from 'mobx-react';
 
const colors = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ]
  const  Myquestions=[
    {
        "idx": "1",
        "question": "2 + 3 * 2 ",
        "qstGen_idx": "DSBGKRA2",
        "image": "https://image2.com",
        "topic_idx": "1",
        "class_idx": "1",
        "curriculum_idx": "1",
        "subject_idx": "1",
        "score": "10",
        "date_created": "27/04/2019 9:42:44 PM",
        "date_modified": "02/05/2019 5:19:00 PM",
        "status": "0",
        "subject": "Mathematics",
        "class_name": "Year 1",
        "curriculum_Idx": "1",
        "curriculum": "British Curriculum",
        "topic": "Surd",
        "subject_name": "Mathematics",
        "curriculum_name": "British Curriculum",
        "className": "Year 1",
        "qstOpt": [
            {
                "idx": "1",
                "_option": "8"
            },
            {
                "idx": "2",
                "_option": "4"
            },
            {
                "idx": "3",
                "_option": "7"
            },
            {
                "idx": "4",
                "_option": "10"
            }
        ]
    },
    {
        "idx": "1",
        "question": "list type of drama",
        "qstGen_idx": "12w212qq",
        "image": "https://image3",
        "topic_idx": "1",
        "class_idx": "1",
        "curriculum_idx": "1",
        "subject_idx": "1",
        "score": "15",
        "date_created": "27/04/2019 9:42:44 PM",
        "date_modified": "02/05/2019 5:19:00 PM",
        "status": "0",
        "subject": "Mathematics",
        "class_name": "Year 1",
        "curriculum_Idx": "1",
        "curriculum": "British Curriculum",
        "topic": "Surd",
        "subject_name": "Mathematics",
        "curriculum_name": "British Curriculum",
        "className": "Year 1",
        "qstOpt": "empty option"
    },
    {
        "idx": "1",
        "question": "what is a sentence",
        "qstGen_idx": "1234wsac",
        "image": "https://image4",
        "topic_idx": "1",
        "class_idx": "1",
        "curriculum_idx": "1",
        "subject_idx": "1",
        "score": "10",
        "date_created": "27/04/2019 9:42:44 PM",
        "date_modified": "02/05/2019 5:19:00 PM",
        "status": "0",
        "subject": "Mathematics",
        "class_name": "Year 1",
        "curriculum_Idx": "1",
        "curriculum": "British Curriculum",
        "topic": "Surd",
        "subject_name": "Mathematics",
        "curriculum_name": "British Curriculum",
        "className": "Year 1",
        "qstOpt": "empty option"
    },
    {
        "idx": "1",
        "question": "what is english",
        "qstGen_idx": "12w212kl",
        "image": "https://image3",
        "topic_idx": "1",
        "class_idx": "1",
        "curriculum_idx": "1",
        "subject_idx": "1",
        "score": "10",
        "date_created": "27/04/2019 9:42:44 PM",
        "date_modified": "02/05/2019 5:19:00 PM",
        "status": "0",
        "subject": "Mathematics",
        "class_name": "Year 1",
        "curriculum_Idx": "1",
        "curriculum": "British Curriculum",
        "topic": "Surd",
        "subject_name": "Mathematics",
        "curriculum_name": "British Curriculum",
        "className": "Year 1",
        "qstOpt": "empty option"
    },
    {
        "idx": "1",
        "question": "what is figure of speach",
        "qstGen_idx": "UFR7HEK9",
        "image": "imageurl.net",
        "topic_idx": "1",
        "class_idx": "1",
        "curriculum_idx": "1",
        "subject_idx": "1",
        "score": "20",
        "date_created": "27/04/2019 9:42:44 PM",
        "date_modified": "02/05/2019 5:19:00 PM",
        "status": "0",
        "subject": "Mathematics",
        "class_name": "Year 1",
        "curriculum_Idx": "1",
        "curriculum": "British Curriculum",
        "topic": "Surd",
        "subject_name": "Mathematics",
        "curriculum_name": "British Curriculum",
        "className": "Year 1",
        "qstOpt": [
            {
                "idx": "13",
                "_option": "a word or phrase used in a non-literal sense for rhetorical or vivid effect."
            },
            {
                "idx": "14",
                "_option": "its a phase"
            },
            {
                "idx": "15",
                "_option": "a word"
            },
            {
                "idx": "16",
                "_option": "all of the above"
            }
        ]
    }
]
const OptionLable=['A','B','C','D','E','F']
  
  @observer
  
class StepFour extends Component{

    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            selectedOption: '3',
            active: '',
            displayOption : false
        }
        // this._LoginHandler = this._LoginHandler.bind(this);
        this.addActiveClass = this.addActiveClass.bind(this);
        this.adddisplayOptions = this.adddisplayOptions.bind(this);
        this.outsideClick = this.outsideClick.bind(this);

      }
      adddisplayOptions() {
        this.setState({
          displayOption: !this.state.displayOption
        });
    }
      addActiveClass(e){
          
        const clicked = e.target.id
        // alert(clicked);
        if(this.state.active === clicked) { 
            this.setState({active: ''});
        } else {
            this.setState({active: clicked})
       }
    }
    // componentDidMount() {
        
    //   }
      componentDidMount() {
    //  ExamStore.SaveQuestion2local(Myquestions);
      ExamStore.GetMyquestionFromLocal();
      ExamStore.PrepareOptionCell();
       ExamStore.DisplayQuestion(ExamStore.currentIndex);

       const Usergrade =  this.props.values.grade;
        ExamStore.globaGrade(Usergrade);
        const Usertopic =  this.props.values.topic;
        ExamStore.globaTopic(Usertopic);
        const Usersubject =  this.props.values.subject;
        ExamStore.globaSubject(Usersubject);
      }
  saveAndContinue = (e) => {
    e.preventDefault()
    this.props.nextStep()
    this.props.handleSaveExam(e)
}
outsideClick = (e) => {
//  alert("hey")
}
alertValue =(idx) =>{
 let a = idx;
 alert(a);
}
// const clicked = e.target.id()
 

toggle = () => this.setState(prevState => ({ checked: !prevState.checked }))

  render(){ 
    // equalWidth2
    let subjectDetails = this.props.values.subject
    let curriculumDetails = this.props.values.curriculum
    let topicDetails = this.props.values.topic
    let classDetails = this.props.values.grade

//     let Qst = ExamStore.SetQuestion
// console.log(Qst + '>><<<')
//     const MapQst = Qst.map(singleData => {
//       return <p>{singleData}</p>
    
//     })
let  Question_Options=null;
if(Array.isArray(ExamStore.currentOptions)){
    let itemkeys = -1;
     Question_Options = ExamStore.currentOptions.map((item) => {
        itemkeys += 1;
        // _optionMedia _optionType: 
       return (
        <Grid.Column width={7} className="displayflex alignFlex mgBtm2 QuestionDisplay" key={itemkeys.toString()} onClick={this.outsideClick}>
        <input type="radio" value={item.idx} checked={ExamStore.currentSelectedOption === item.idx} onChange={ExamStore.handleOptionChange} />
        {/* <input type="radio" value={item.idx} name='radioGroup' onChange={this.handleOptionChange} checked={this.state.selectedOption} /> */}
        <div className="flexDisplayColume justifyFlex">
        <span style={{marginLeft:11}}>{item._option}</span>
        {
                   
                   item._optionType == 'image' ? 
                   <div className="fullWidth centerFlex alignFlex"><img src={item._optionMedia} className="optionImgDisplay" alt=""/> </div>
                   :
                   item._optionType == 'Video' ? 
                   <div className="fullWidth centerFlex alignFlex ReactStylePlayer2"><ReactPlayer controls={true} className="optionVideoDisplay" url={item._optionMedia}   className=""/> </div>
                   :
                   ""
        }
        
        
        </div>
        </Grid.Column>
   
      );
     
    });
}
else{
    Question_Options='No Option Available';
}
let  Question_Image=null;
if(Array.isArray(ExamStore.currentQuestionImage)){
    let itemkeys2 = -1;
    Question_Image = ExamStore.currentQuestionImage.map((item) => {
        itemkeys2 += 1;
        if (item.filetype == 'image'){
               return (
                <Grid.Column className="QuestionimgWrapSpa ">
                  <Segment className="fullWidth fullHeight"> 
                    <Image className="displayedImgQst" src={item.questionMedia} />
                  </Segment>
                </Grid.Column>
      );
        }
        if (item.filetype == 'Video'){
               return (
                <Grid.Column className="QuestionimgWrapSpa equalWidth2">
                  <Segment className="fullWidth fullHeight ReactStylePlayer"> 
                  <ReactPlayer controls={true} url={item.questionMedia}   className=""/>
                  </Segment>
                </Grid.Column>
      );
        }
    
     
    });
}
else{
    Question_Image='';
}

    return ( 
        <div className="centerFlex allWrap">
        <div style={styles.DivWrapper2} className="centerFlex pad0 alignFlex blueLayer pad4">
            <div className="DivWrapper2Wrap2 leftFlex" style={styles.DivWrapper3Wrap}>
{/* 
        <div className="leftFlex"> */}
              {
        ExamStore.navMonitor==='exam'

      }
    <div className="flexDisplayColume widthcalc pad5">

       <div className="flexDisplayColume alignCenterFlex" style={{alignItems: 'flex-start'}}>
    <div className="TewntyFiveFontSize font500 mgBtm1"> {ExamStore.currentIndex+1}</div>
       <div style={styles.examWrapGet} className="ExamWrap">
             <div>
             <p className="fourteenFontSize">
                 
               {
            //       ExamStore.Question2Answer.length >0 ?

            //    JSON.stringify()
            //       :

            //       null
                  
                   ExamStore.currentQuestion
                   }
                  
            </p>
            {/* {
                ExamStore.currentQuestion > 0 ? */}
                <Grid stackable columns={2}>
                    {Question_Image}
                </Grid>
                {/* :

            //     ""
            // } */}
           
             </div>
            {/* {MapQst} */}
            {/* <p>{ExamStore.SetQuestion}</p> */}
            <Grid className="noMargin mgTop1 justifyFlex">
            <Grid.Row className="Imp_justifyFlexSpaceBtw optionSwap" >
                    {Question_Options}
                   </Grid.Row>
                </Grid>
        </div>
       </div>
        <div className='centerFlex' style={{maxWidth:'100%',marginTop: '15%'}}>
            <div style={{maxWidth:'100%', width:'100%'}} className="tomGrid">
              
                <div className="fullWidth centerFlex Imp_justifyFlexSpaceBtw" style={styles.examFooter}>
                {/* <div className="leftFlex alignFlex fontBold">{ExamStore.currentIndex+1} 0f { ExamStore.TotalQuestions2Answer}</div> */}
                {
                    ExamStore.currentIndex > 0 ?
                    <Button onClick={ExamStore.PreviousQuestion} className="Rectangle-118 bluecolor sixteenFontSize font500">Previous</Button>

                    :
                    null 
                }
               {
                 ExamStore.currentIndex+1 <  ExamStore.TotalQuestions2Answer ?

                 <Button onClick={ExamStore.NextQuestion} className="Rectangle-114 whiteColor sixteenFontSize font500">Next</Button>

                 :

                 <Button onClick={ExamStore.SaveAnswer} className="Rectangle-114 whiteColor sixteenFontSize font500">Submit</Button>

                 
                }
                {/* <div className="equalWidth rightFlex alignFlex"><Button onClick={this.saveAndContinue} className="greenBackground btnInc">Submit</Button></div> */}
               
                </div>
            
            </div>
        
        </div>
        

  </div>
  <div className={`arrowToggler arrowTogglerDetails ${this.state.active === "first"? 'timmer_qstList2': ''}`} ><Icon id="first" onClick={this.adddisplayOptions} name='exchange' /></div>

  <div className={`examQstListSide flexDisplayColume pd1  ${this.state.displayOption === true ? 'examQstListSide2': ''}`}>
  <div className="fullWrapper modalDisplayContent">
  <div className="flexDisplayColume">
            <div className="centerFlex"> 
                  <div className="leftFlex pad6 examQstDetail fourteenFontSize  font500"> Curriculum </div>
                  <div className="leftFlex pad6 equalWidth2 twelveFontSize"> {curriculumDetails} </div>
                 </div>
                <div className="centerFlex"> 
                  <div className="leftFlex pad6 examQstDetail fourteenFontSize font500"> Class </div>
                  <div className="leftFlex pad6 equalWidth2 twelveFontSize"> {classDetails} </div>
                 </div>
                <div className="centerFlex"> 
                  <div className="leftFlex pad6 examQstDetail fourteenFontSize font500"> Subject </div>
                  <div className="leftFlex pad6 equalWidth2 twelveFontSize">{subjectDetails}  </div>
                 </div>
                
                <div className="centerFlex"> 
                  <div className="leftFlex pad6 examQstDetail fourteenFontSize font500"> Topic(s) </div>
                  <div className="leftFlex pad6 equalWidth2 twelveFontSize"> {topicDetails} </div>
                 </div>
            </div>
  {/* <div className='centerFlex' style={{borderBottom: 'solid 1px #ccc',}}>
            <div className="leftFlex" style={{minWidth: '55%', marginBottom: '2%'}}>
                <div className='flexDisplayColume alignteamLeft' style={{}}>
                    <p className="noMargin"> Subject: {subjectDetails}</p>
                    <p className="noMargin"> Topic: {topicDetails}</p>
                    <p className="noMargin"> Curriculum: {curriculumDetails}</p>
                    <p className="noMargin"> Class: {classDetails}</p>
                </div>
            </div>
        </div> */}


    < div className={`flexDisplayColume timmer_qstList examNum ${this.state.active === "first"? 'timmer_qstList2': ''}`} >
    
    < div className="flexDisplayColume" style={styles.examNum}>
    <div className="centerFlex alignFlex justifyFlex Mypadding" style={{minHeight: "117px"}}> <div></div> <div className="timerSty flexDisplayColume"  style={{textAlign:'center'}}><div className="fourteenFontSize">Time Remaining</div>
    <div className="timerCounterSty TimeInColor">
         { ExamStore.ready===true ?
            <Timer
            initialTime={ExamStore.TimerInnitailTime}
            direction="backward"
            onStart={() => console.log('onStart hook')}
            // onResume={() => console.log('onResume hook')}
            // onPause={() => console.log('onPause hook')}
            onStop={() => alert("Your Time is Up")}
        >
            {() => (
                <React.Fragment>
                   
                    <Timer.Hours /> :
                    <Timer.Minutes /> :
                    <Timer.Seconds /> 
                   
                </React.Fragment>
            )}
        </Timer>
        :
        null
        }
        </div>
        </div></div>

        <div className="centerFlex alignFlex justifyFlexSpaceEvenly Mypadding">
            <div className="fourteenFontSize">Skip to</div>
        {/* <div className="centerFlex"> */}
        <input
         type="text" 
        //  style={styles.inputSkip}
         name="questionIndex"
         className="Rectangle-72"
         value={ExamStore.JumpQuestionParam.questionIndex}

         onChange={(e) => ExamStore.handleInputChange(e, "JumpQuestionParam")}
         style={{width: '32%',}}
         />
          <Button
           style={{marginLeft: '5px',}}
            className="Rectangle-112 go"
            onClick={ExamStore.JumpToQuestion}
            >
            Go</Button>
             {/* </div> */}
             {/* </div> */}
             </div>
        <div className='centerFlex' style={{maxWidth:'100%',marginTop: '15px'}}>
            <div style={{maxWidth:'100%'}} className="tomGrid">
                <Grid columns={25} padded>
                    {ExamStore.OptionBanks_obs.map(options => (
                    <Grid.Column color={options.unique_Q_id} key={options.unique_Q_id} className={`gridItem ${ExamStore.CheckIfQuestionIsAnswered(options.unique_Q_id)===true ? 'gridItemActive' : null}`}>
                        {_.capitalize(options.unique_Q_id +1)}
                    </Grid.Column>
                    ))}
                </Grid>
            </div>
        
        </div>
        

        {/* gridItemActive */}

  </div>
  </div>
  </div>
  </div>

  </div>
  </div>
  </div>
)
}
}

export default StepFour
 