import React, { Component } from 'react'
import { Button, Header, Image, Modal, Divider, Form  } from 'semantic-ui-react'
import { observer } from 'mobx-react';
import DashboardStore from '../../stores/Dashboard';
const Tinsizes = ['tiny']
const sizes = ['huge']

@observer
class SignUp extends Component{
  state = { open: false }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })
    render(){
      const { open } = this.state
        return(

          <div>
            <Button  onClick={this.open}>Show Modal</Button>
            <Modal open={open}
            onOpen={this.open}
            onClose={this.close}>
           
            <Modal.Content image style={{padding: '6% 5%',}}>
              <div style={{minWidth: '45%'}}>
                <div>
                    <p className="blueColor noMargin" style={{fontSize: '50px',fontWeight: 'bold'}}>Good to see you again</p>
                    <p style={{fontSize: '18px'}}>Please login into your account</p>
                    <Image wrapped size='medium' src='https://react.semantic-ui.com/images/avatar/large/rachel.png' />
                </div>
              </div>
              <Modal.Description style={{minWidth: '41%'}}>
              <div>
                    {sizes.map(size => (
                    <Form size={size} key={size}>

                       <Button  className='fbBackground fontBold loginExBtn' fluid type='submit'>Sign In with Facebook</Button>
                       <Button  className='redbackground fontBold loginExBtn' fluid type='submit'>Sign In with Google</Button>
                       <Button  className='blueBackground fontBold loginExBtn' fluid type='submit'>Sign In with Edves</Button>
                        <Form.Field 
                        control='input' 
                        placeholder='User id'
                        
                        />
                        <Form.Field control='input' placeholder='Password' />
                        <Form.Group widths='equal'>
                        <Form.Checkbox label='Remember me' className="blueColor colorB RememberMe" />
                        <Form.Field label='Forget password?' className='blueColor colorB rightFlex alignFlexBtm' style={{fontSize: '13px', textAlign: 'right'}} />
                        </Form.Group>
                        {/* <Button fluid>Fits to Container</Button> */}
                        <Button  className='blueBackground fontBold' fluid type='submit'>Sign In</Button>
                        <Divider hidden />
                    </Form>
                    ))}
                </div>
              </Modal.Description>
            </Modal.Content>
          </Modal>
          </div>
        )
    }

}

export default SignUp
