import axios from 'axios';
import qs from 'qs';
import React from "react";
 
import ls from 'local-storage'


import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
// import BeatLoader from 'react-spinners/BeatLoader';
import PacmanLoader from 'react-spinners/SyncLoader';
import { resolve } from 'path';
var moment = require('moment');
const override = css`
display: block;
margin: 0 auto;
border-color: red;
`;
  
export const LogoutNow = async () => {
     // const { history } = this.props;
       let response= await ls.remove('Loggedinstate');
      //window.location.reload();
      window.location.href = '/';
   }
export const isToday = (someDate) => {
     const today = new Date()
     return someDate.getDate() == today.getDate() &&
       someDate.getMonth() == today.getMonth() &&
       someDate.getFullYear() == today.getFullYear()
   }
   
export const ShowCurrentDate = () => {
  let day = new Date().getDate();
 let month = new Date().getMonth() + 1;
 let year = new Date().getFullYear();
 let currentdate=null;
 if(day <10){
 day= `0${day}`;
 }
 if(month <10){
month= `0${month}`;
 }
// day=parseInt(day)
// let plus1=day+1;
  currentdate = year + '-' + month + '-' + day;
  console.log(currentdate)
  console.log('currentdate')

//  var date = new Date(currentdate);
let datearray=currentdate.split('-');
console.table(datearray);
 var date = new Date(datearray[0],datearray[1]-1,datearray[2]);
 console.log(date)
 console.log('date')
 return date;
//  return currentdate =day+'/'+month+'/'+year;  ;


} 
export const date_diff_indays = (date1, date2) => {
     console.log(date1+'>>>>'+date2)
          let dt1 = new Date(date1);
          let dt2 = new Date(date2);
          let daysDiff= Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
          
 return daysDiff;
//  return currentdate =day+'/'+month+'/'+year;  ;


} 
export const getFutureDate = (numberOfDaysToAdd) => {
  var now     = new Date(); 
  now.setDate(now.getDate() + numberOfDaysToAdd); 
  var year    = now.getFullYear();
  var month   = now.getMonth()+1; 
  var day     = now.getDate();
  var hour    = now.getHours();
  var minute  = now.getMinutes();
  var second  = now.getSeconds(); 
  if(month.toString().length == 1) {
       month = '0'+month;
  }
  if(day.toString().length == 1) {
       day = '0'+day;
  }   
  if(hour.toString().length == 1) {
       hour = '0'+hour;
  }
  if(minute.toString().length == 1) {
       minute = '0'+minute;
  }
  if(second.toString().length == 1) {
       second = '0'+second;
  }   
  // var dateTime = year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;   
//   var futuredate = year+'-'+month+'-'+day; 
  var futuredate = year+'/'+month+'/'+day; 
  var date = new Date(futuredate);

  console.log(futuredate+"future future")  
  console.log(date+"future future")  
  // var futuredate = day+'/'+month+'/'+year;  
//   new Date() 
   return date;

} 
 
export const convert2CalendarDate = (dateString) => {
     
     
     var dateObj = new Date(dateString);
     var momentObj = moment(dateObj);
     var momentString = momentObj.format('YYYY-MM-DD');
  return momentString;

//   var dateObj = new Date(dateString);
// var momentObj = moment(dateObj);
// var momentString = momentObj.format('YYYY-MM-DD');
} 
 
export const myspiner = (state,text=null) => {
     let spin=null; 
     // state= true;
    if(state===true){
     spin  = <div className='sweet-loading'>
     <PacmanLoader
       css={override}
       sizeUnit={"px"}
       size={100}
       color={'#123abc'}
       loading={state}
     />
   </div> 
    }
   return spin;
     
     } 

//     export const  UploadImage = async (formData) => {
     export const UploadImage =   (formData) => new Promise((resolve, reject) => {

     const config = {
          headers: {
              "Content-Type": "multipart/form-data"
              // 'content-type': `multipart/form-data; boundary=${bodyFormData._boundary}`
          }
      };
       axios.post('/imageUpload', formData,config)
     .then(response => {
           console.log(response)
        // let check = response.data.hasOwnProperty("login_token");
         //   alert(true)
         if (response.status === 200 || response.status === 201) {
             let resp = response.data;
             // console.log(resp)
             let check = resp.status
             let data = null;
             if (check === true) {
              //  alert(resp.message)
         let filepath='';
         data=resp.data;
              filepath=data.file_url;
           //   alert
           // return data;
           resolve(data)
             }
         else {
          resolve ( false);

         }

     }
     else{
// alert("here")
resolve ( false);

     }
     })
     .catch(err => {
         console.log(typeof(err));
         resolve ( false);
       
     
     })
    
});

// export const ErrorComponent = (text='') => {
//      let spin=  <div className='sweet-loading'>
//    {text}
//    </div> 
    
//      return spin;
//     } 

 


 
export const  getfullname = () => {
     let ReturnedString=ls.get('Loggedinstate') || {};
     // console.log(ReturnedString)
     if(ReturnedString.isloggedin===true){
      return ReturnedString.fullname;
     }
     else{
      return '';
     }
    
    }
    export const  getuserdata = () => {
     let ReturnedString=ls.get('Loggedinstate') || {};
    //  console.log(ReturnedString)
     //  alert(JSON.stringify(ReturnedString))
     if(ReturnedString.isloggedin===true){
     //  return ReturnedString.usertype;
      return ReturnedString;
     }
     else{
      return '';
     }
    
    }
    export const  getuserType = () => {
     let ReturnedString=ls.get('Loggedinstate') || {};
    //  console.log(ReturnedString)
     //  alert(JSON.stringify(ReturnedString))
     if(ReturnedString.isloggedin===true){
      return ReturnedString.usertype;
     //  return ReturnedString;
     }
     else{
      return '';
     }
    
    }
    export const  getusershortProfile = () => {
     let ReturnedString=ls.get('Loggedinstate') || {};
    //  console.log(ReturnedString)
     //  alert(JSON.stringify(ReturnedString))
     if(ReturnedString.isloggedin===true){
          
          let ShortProfile=ls.get('loggedinUserdetails') || {};
          console.log('ShortProfile')
          console.log(ShortProfile)
          console.log('ShortProfile')
          return ShortProfile;
      }
     else{
      return '';
     }
    
    }
  







 