export default {

    pStyle: {
        fontSize: 60,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.2,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#333333',
    },
    pStyle3: {
        fontSize: '21px',
    },
    priceAmount:{
        fontSize:  42,     
        lineHeight: 1.43,
        textAlign: 'center',
        margin: '10% 0px',
    },
    cardPriceListIcon: {
        width: 18,
        height: 18,
        border: 'solid 1px #0c5d9e',
        color: '#0c5d9e',
        borderRadius:' 50%',
        fontSize: 12,
        padding: 0,
        minWidth: 18,
        minHeight: 18,
    },
    cardPriceListIcon2: {
        width: 18,
        height: 18,
        border: 'solid 1px #fff',
        color: '#fff',
        borderRadius:' 50%',
        fontSize: 12,
        padding: 0,
        minWidth: 18,
        minHeight: 18,
    },
    cardPriceList:{
        fontSize: 16,
    },
    lMore: {
        borderRadius: '50px',
        padding: '20px 50px',
        marginTop: '15px',
        alignItems: 'center',

    },
    lMore2: {
        width: 206,
        height: 49,
        borderRadius: 3,
        backgroundColor: '#1aab39',
        fontSize: 16,
        marginTop: 20
    },
    fltstyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    secondLayer: {
        // marginTop: '5%',
        padding: '3% 10%',
    },
    secondLayerSty2: {
        // marginTop: '5%',
        padding: '6% 10% 3%',
    },
    thirdLayer: {
        // marginTop: '5%',
        padding: '3rem 5rem',

    },
    secondLayerSubject: {
        fontSize: 40,
        marginBottom: '1rem',
        // marginTop: '3rem',
        alignItems: 'center',
        color: '#333333',
        height: 57,
    },
    secondLayerSubject3: {
        fontSize: 40,
        marginBottom: '3rem',
        // marginTop: '3rem',
        alignItems: 'center',
        color: '#333333',
        height: 57,
    },
    subjectList: {
        width: 102,
        marginBottom: '1rem',
        fontSize: 16,
        lineHeight: 1.44,
        marginTop: '1rem',
        alignItems: 'center',
        color: '#333333',
        textTransform: 'capitalize'
        
    },
    secondLayerSubject2: {
        fontSize: '16px',
        color: '#666',
        marginBottom: '3rem',
        alignItems: 'center',
    },

    itemsAlign: {
        textAlign: 'left',
        fontSize: '18px',
    },
    circlewrap: {
        height: '150px',
        width: '150px',
        borderRadius: '50%',
        // background: '#fff',
    },
    thirdSessionIconStyle1: {
        fontSize: '80px',
        color: '#0e5e9e',
        height: 68,
        width: 64,
    },
    thirdSessionIconStyle1_2: {
        fontSize: '80px',
        color: '#0e5e9e',
        height: 68,
        width: 82,
    },
    thirdSessionIconStyle1_3: {
        fontSize: '80px',
        color: '#0e5e9e',
        height: 70,
        width: 80,
    },
    thirdSessionIconStyle: {
        fontSize: '80px',
        color: '#0e5e9e',
        height: 'auto',
        width: '70%',
    },
    flagShow:{
            maxWidth:'27%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 260

    },
    flagShow2:{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

    },
    sys_flagWrap: {
        width: 254,
        height: 222,
        borderRadius: 3,
        boxShadow: '0 3px 17px 0 rgba(0, 0, 0, 0.23)',
        background: '#ffffff',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sys_flagWrap2: {
        borderRadius: 3,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20.,
    },
    sys_flagWrap2_2: {
        borderRadius: 3,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // padding: 20.,
    },
    sys_flag: {
        width: 160,
        height: 80,
    },
    thirdSessionTopic1: {
        marginTop: '10px',
        fontSize: '30px',
        // fontWeight: 'bold',
        marginBottom: '10px',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#333333',
    },
    thirdSessionTopic: {
        marginTop: '10px',
        fontSize: '30px',
        fontWeight: 'bold',
        marginBottom: '10px'
    },
    sys_flagName: {
        marginTop: '25px',
        fontSize: '25px',
        lineHeight: 1.44,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#333333',
    },
    featuresNote: {
        padding: '0 2rem',
    },
    featuresNote1: {
        // padding: '0 2rem',
        lineHeight: 1.43,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#333333',
    },
    priceImg: {
        width: '100%',
        height: '30%'
    },
    priceWrap: {
        width: '30%'
    }


}