import PropTypes from 'prop-types'
import React, { Component , Fragment} from 'react'
import { Menu, Button, Icon, Dropdown, Image,  Modal, Divider, Form, Sidebar, Grid, Segment} from 'semantic-ui-react'
import styles from './landingNav.css.js';
import Scrollchor from 'react-scrollchor';
import Logo from '../assets/img/logo.png'
import {isSignedIn,getUsertype,getMyRole,hasChangePwd} from '../../auth';
import ExamStore from '../../stores/exam';
import { observer } from 'mobx-react';
import AccountStore from '../../stores/Account'
import { withRouter } from 'react-router-dom';
import { LogoutNow } from "../../dependency/UtilityFunctions";

// import { BrowserRouter } from 'react-router-dom';

import { Link, BrowserRouter, Route } from 'react-router-dom'
const sizes = ['huge']
const Tinsizes = ['tiny']



// VerticalSidebar.propTypes = {
//   animation: PropTypes.string,
//   direction: PropTypes.string,
//   visible: PropTypes.bool,
// }

@observer
 class Header extends Component {
  
  constructor(props) {
    super(props);
  this.state = {  
    activeItem: 'How it worksss', 
    loggedIn: 'false', 
    open: false,
    showModal: false,
    animation: 'overlay',
    direction: 'right',
    display: false,
    dimmed: false,
    visible: false,
   }
   this.toggleSideBar = this.toggleSideBar.bind(this);
  //  this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })
  openSUModal = () => this.setState({ showModal: true })
  openSUModal2 = () => {
    this.setState({ open: false });
    this.setState({ showModal: true });
  };
  openLOgModal2 = () => {
    this.setState({ showModal: false });
    this.setState({ open: true });
  };
  closeSUModal = () => this.setState({ showModal: false })
  
  handleItemClick = (e, { name }) => this.setState({ activeItem: name })
  
  componentDidMount() {
    let loginstate=isSignedIn();
    console.log(loginstate + 'tom')
    // if(loginstate === true){
    //    this.setState({ 
    //     loggedIn: loginstate
    //   });
    //   alert(this.state.loggedIn)
    //   console.log(this.state.loggedIn)
    // };
      // this.setState({ 
      //   open: loginstate ===true ? false:true 
      // });
      // alert(this.state.loggedIn)
      // console.log()
      // this.setState({ 
      //   open: loginstate ===true ? false:true 
      // });
    }
    toggleSideBar() {
      this.setState({
        dimmed: !this.state.dimmed,
        visible: !this.state.visible,
        display: !this.state.display
      });
  }
    handleLogout = () => {
      LogoutNow();
      // this.props.logoutUser(this.props.history);
    };
     handleAnimationChange = (animation) => () =>
    this.setState((prevState) => ({ animation, visible: true }))
  
  render() {

    // const { animation, direction, visible } = this.state
    // const vertical = direction === 'bottom' || direction === 'top'

    const VerticalSidebar = ({ animation, direction, visible }) => (
      <Sidebar
        as={Menu}
        animation={animation}
        direction={direction}
        icon='labeled'
        inverted
        vertical
        visible={visible}
        width='thin'
        className="sidebarHome"
      >
        <Link to="/" >
        <Menu.Item as='a'>
        
          <Icon name='home' />
          Home
         
        </Menu.Item>
        </Link>
        <Scrollchor to="#Features">
          <Menu.Item as='a'>
            <Icon name='clipboard outline' />
            Features
          </Menu.Item>
        </Scrollchor>
        <Scrollchor to="#Curriculum" >
        <Menu.Item as='a'>
          <Icon name='dot circle' />
          Curriculum
        </Menu.Item>
        </Scrollchor>
        <Scrollchor to="#Pricing">
        <Menu.Item as='a'>
          <Icon name='dollar sign' />
          Pricing
        </Menu.Item>
        </Scrollchor>
        <Menu.Item as='a'>
          <Icon name='dashboard' />
          Dashboard
        </Menu.Item>
        {
               loginstate == true ?
               <Menu.Item as='a' onClick={() => this.handleLogout()}>
               <Icon name='log out' />
               Log-out
             </Menu.Item>
               :
               <Menu.Item as='a' onClick={this.open}>
               <Icon name='sign-in' />
               Log-In
             </Menu.Item>
        } 
        
      </Sidebar>
    )
    const { from } = this.props.location.state || { from: { pathname: '/' } }
        const { redirectToReferrer } = this.state
        const { history } = this.props;
        if (AccountStore.redirectToReferrer === true) {
            history.push(from);
            window.location.reload();
            //  return <Redirect to={from} />
        }
        else {
            console.log("am false")
        }
    let loginstate=isSignedIn();
    // return (loginstate)
      //  this.setState({ 
      //   loggedIn: loginstate
      // });
    const { activeItem } = this.state 
    const userImg = <Image avatar className="noMargin" src='https://react.semantic-ui.com/images/avatar/small/helen.jpg' />;
    // const userContent = null;
     
    const { open } = this.state
    const { showModal } = this.state

    
    return (
      <Fragment>
      {/* // <BrowserRouter> */}
       <Link to='/home/#section1'></Link>
      <Menu secondary style={styles.navInc} className="NavIncl">
        <Menu.Item name='Edves Academy' style={styles.edvesAca}><Link to="/"><img style={{width: 130}} src={Logo} alt="LO" /></Link></Menu.Item>
        <Menu.Menu position='right' className="zIndex4 topNav1_2">
          <div className="element2">
          {/* <div className="element2" active={direction === 'right'}> */}
        <Icon  onClick={this.toggleSideBar} name='bars' className="IconBar"/>
        </div>
        </Menu.Menu>
        <Menu.Menu position='right' className="zIndex3 topNav1_1">
          <Scrollchor to="#samplecode" className="loNav centerFlex alignCenterFlex"><Menu.Item className="navSTy" name='How it works' active={activeItem === 'How it works'} onClick={this.handleItemClick} className=""/></Scrollchor>
          <Scrollchor to="#Features" className="loNav centerFlex alignCenterFlex"><Menu.Item name='Features' active={activeItem === 'Features'} onClick={this.handleItemClick} className="" /></Scrollchor>
          <Scrollchor to="#Curriculum" className="loNav centerFlex alignCenterFlex"><Menu.Item name='Curriculum' active={activeItem === 'Curriculum'} onClick={this.handleItemClick} className="" /></Scrollchor>
          <Scrollchor to="#Pricing" className="loNav centerFlex alignCenterFlex"><Menu.Item name='Pricing' active={activeItem === 'Pricing'} onClick={this.handleItemClick} className="" /></Scrollchor>
          
          <Menu.Item >
             {
               loginstate == true ?
               // return(
               <Dropdown  className="displayflex menuImage centerFlex alignFlex" text={userImg}>
                 <Dropdown.Menu>
                 <Link to={'/dashboard'}>  <Dropdown.Item text='Dashboard' /></Link>
                   <Dropdown.Item onClick={() => this.handleLogout()} text='Logout'/>
                 </Dropdown.Menu>
               </Dropdown>
               // )
               :
               <Menu.Item ><Button onClick={this.open} positive>SIGN IN </Button></Menu.Item>
             } 
             </Menu.Item>
        </Menu.Menu>



        <Modal open={open}
          onOpen={this.open}
          onClose={this.close}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          size={'small'}
          >
            
          <Modal.Content image style={{ padding: '4% 10% 5%', }}>
            <Modal.Description style={{ minWidth: '41%' }}>
              <div className="TewntyFiveFontSize font500" style={{marginBottom: 35}}>Sign In</div>
              <div>
                {sizes.map(size => (
                  <Form size={size} key={size}>
                    <Form.Field className="relPosition">

                    <span className="inputLabel">Email</span>
                    <input
                    className="styInputAcct"
                     control='input' 
                     name="email"
                     onChange={(e)=>AccountStore.handleReg_Change(e,'loginInfo')}
                      />
                       </Form.Field>
                       <Form.Field className="relPosition">
                       <span className="inputLabel">Password</span>
                       <input
                     control='input' 
                     className="styInputAcct"
                     name="password"
                     type="password"
                     onChange={(e)=>AccountStore.handleReg_Change(e,'loginInfo')}
                     />
                       </Form.Field>
                    
                    <Button
                     className='greenBackground sixteenFontSize font500 LologinBtn'
                      onClick={AccountStore._LoginHandler}
                      >
                         {AccountStore.isprocessing ? 'Processing....' : 'Sign in'}
                      
                      </Button>
                    <Divider hidden  style={{marginBottom:3}}/>
                    {/* <p style={{ fontSize: '18px' }}>Don't have an account yet?<span onClick={this.openSUModal2}  className="blueColor" style={{cursor: 'pointer'}}> Register.</span></p> */}

                    <p style={{ fontSize: '18px', marginTop:0 }}>Don’t have an Account? <span onClick={this.openSUModal2}  className="blueColor font500" style={{cursor: 'pointer'}}> Sign Up </span> now</p>
                    {/* <Link to={'/'} className="greenColor">Home</Link> */}
                  </Form>
                ))}
              </div>
            </Modal.Description>
          </Modal.Content>
        </Modal>


        <Modal 
        open={showModal}
        onOpen={this.openSUModal}
        onClose={this.closeSUModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        size={'small'}
        >
           
            <Modal.Content image style={{padding: '4% 10% 5%',}}>
              <Modal.Description>
              <div className="TewntyFiveFontSize font500" style={{marginBottom: 35}}>Sign Up</div>
              <div>
                    {Tinsizes.map(size => (
                    <Form size={size} key={size}>
                      <Form.Field className="relPosition">
                        <span className="inputLabel">First Name</span>
                        <input
                          className="styInputAcct"
                          control='input' 
                          name="Reg_firstname"
                          onChange={(e)=>AccountStore.handleReg_Change(e,'regInfo')}
                        />
                      </Form.Field>
                      <Form.Field className="relPosition">
                        <span className="inputLabel">Last Name</span>
                        <input
                          className="styInputAcct"
                          control='input' 
                          name="Reg_lastname"
                          onChange={(e)=>AccountStore.handleReg_Change(e,'regInfo')}
                        />
                      </Form.Field>
                      <Form.Field className="relPosition">
                        <span className="inputLabel">Age</span>
                        <input
                          className="styInputAcct"
                          control='input' 
                          name="Reg_age"
                          onChange={(e)=>AccountStore.handleReg_Change(e,'regInfo')}
                        />
                      </Form.Field>

                      <Form.Field className="relPosition">
                        <span className="inputLabel">Email</span>
                        <input
                          className="styInputAcct"
                          control='email' 
                          name="Reg_email"
                          onChange={(e)=>AccountStore.handleReg_Change(e,'regInfo')}
                        />
                      </Form.Field>
                      <Form.Field className="relPosition">
                        <span className="inputLabel">Password</span>
                        <input
                          className="styInputAcct"
                          control='input' 
                          type="password"
                          name="Reg_password"
                          onChange={(e)=>AccountStore.handleReg_Change(e,'regInfo')}
                        />
                      </Form.Field>
                      <Form.Field className="relPosition">
                        <span className="inputLabel">Confirm Password</span>
                        <input
                          className="styInputAcct"
                          control='input' 
                          type="password"
                          name="Reg_confirm"
                          onChange={(e)=>AccountStore.handleReg_Change(e,'regInfo')}
                        />
                      </Form.Field>

                      <Form.Field className="relPosition">
                        <span className="inputLabel">School</span>
                        <input
                          className="styInputAcct"
                          control='input' 
                          name="Reg_school"
                          onChange={(e)=>AccountStore.handleReg_Change(e,'regInfo')}
                        />
                      </Form.Field>
                      
                        {/* <Form.Field control='input' placeholder='Country' />
                        <Form.Field control='input' placeholder='State' /> */}
                        {/* <Button fluid>Fits to Container</Button> */}
                        <Button  
                        className='greenBackground sixteenFontSize font500 LologinBtn' 
                        fluid type='submit'
                        onClick={AccountStore._SignupHandler}
                        >
                          {AccountStore.isprocessing ? 'Processing....' : 'Sign Up'}</Button>
                         
                        <Divider hidden  style={{marginBottom:3}}/>

                    <p style={{ fontSize: '18px', marginTop:0 }}>Already have an Account?<span onClick={this.openLOgModal2}  className="blueColor font500" style={{cursor: 'pointer'}}> Sign In </span> now</p>
                    </Form>
                    ))}
                </div>
              </Modal.Description>
            </Modal.Content>
          </Modal>
     
      </Menu>
      
      {/* // </BrowserRouter> */}
      <Sidebar.Pushable as={Segment} className={`sidebarSty ${this.state.display == false ? 'displayNo':'displayYes'}`} onClick={this.toggleSideBar}>
      <VerticalSidebar
              animation={this.state.animation}
              direction={this.state.direction}
              visible={this.state.visible}
            />

          <Sidebar.Pusher dimmed={this.state.dimmed}>
            {/* <Segment basic>
              <Header as='h3'>Application Content</Header>
              <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
            </Segment> */}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Fragment>
    )
  }
  
}
export default withRouter(Header);
