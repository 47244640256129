import React, { Component, Fragment } from 'react'
import { Menu, Button, Icon, Dropdown, Image} from 'semantic-ui-react'
import styles from './landingNav.css.js';
import Scrollchor from 'react-scrollchor';
import Logo from '../assets/img/logo.png'
import HistoryIcon from '../assets/img/minAssets/history.png'
import RankingIcon from '../assets/img/minAssets/ranking.png'
import TutoIcon from '../assets/img/minAssets/tuto.png'
import User3 from '.././assets/img/user3.jpeg';
import {isSignedIn,getUsertype,getMyRole,hasChangePwd, getloginFullDetails} from '../../auth';
import { LogoutNow } from "../../dependency/UtilityFunctions";

// import { BrowserRouter } from 'react-router-dom';

import { Link, BrowserRouter, Route } from 'react-router-dom'
export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      activeItem: 'How it worksss', 
      loggedIn: 'false', 
      profileScreen: false ,
      loggedin_username:''
    }
    this.triggerProfileScr = this.triggerProfileScr.bind(this);
  }
  triggerProfileScr() {
    this.setState({
      profileScreen: !this.state.profileScreen
    });
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })
  
  componentDidMount() {
    let loginstate=isSignedIn();
    console.log(loginstate + 'tom')
  let details= getloginFullDetails();
  console.log(details)
  if(details.isloggedin===true){
let fullname=details.fullname
this.setState({
  loggedin_username:fullname
})
  }
    }
    handleLogout = () => {
      LogoutNow();
      // this.props.logoutUser(this.props.history);
    };
  render() {
    let loginstate=isSignedIn();
    const { activeItem } = this.state 
    const userImg = <Image avatar className="noMargin" src='https://react.semantic-ui.com/images/avatar/small/helen.jpg' />;
const {loggedin_username}=this.state
    
    return (
        <Fragment>
          <Icon name="bars" className="profileBarIcon" onClick={this.triggerProfileScr}/>
        <div className={`sidebarWrap2  ${this.state.profileScreen == false ? '': 'DisplaySidebarWrap2'}`}> 
        {/* <Icon name="bars" className="profileBarIcon"/> */}
        <div className="flexDisplayColume fullWidth sidebarSide1 pad1">
        <div className="fullWidth flexDisplayColume pad1 alignCenterFlex sidebarLink">
                <img className="profileImg" src={User3} alt />
            </div>
            <div className="fullWidth flexColumeflexStart pad1 mgTop1">
            <span className="twelveFontSize font500">Name</span>
    <span className="sixteenFontSize">{loggedin_username}</span>
            </div>
            <div className="fullWidth flexColumeflexStart pad1 mgTop1">
            <span className="twelveFontSize font500">CLASS </span>
            <span className="sixteenFontSize">JSS1</span>
            </div>
            <div className="fullWidth flexColumeflexStart pad1 mgTop1">
            <span className="twelveFontSize font500">SCHOOL </span>
            <span className="sixteenFontSize">EDVES INT’L SCHOOL</span>
            </div>
            <div className="fullWidth flexColumeflexStart pad1 mgTop1">
            <span className="twelveFontSize font500">Email Adderess </span>
            <span className="sixteenFontSize">tomzy.brewer@mail.com</span>
            </div>
            <div className="fullWidth flexDisplay justifyFlexSpaceBtw pad1 mgTop1_1">
            <Button className="ProfileEditBtn">EDIT PROFILE</Button>
            <Link to="/exam">
            <Button className=" ProfileStartBtn">TAKE A TEST</Button></Link>
            </div>
        </div>
     </div>
     </Fragment>

    )
  }
  
}
