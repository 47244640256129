// import _ from 'lodash'
import React, { Component } from 'react'
import { Grid , Button, Segment, List, Image, Table, Icon, Modal, Header, Tab} from 'semantic-ui-react'
import User3 from '.././assets/img/user3.jpeg';
import ExamStore from '../../stores/exam';
import { observer } from 'mobx-react';
import DashNav from '../navs/dashboardNav'
import Sidebar from '../navs/sidebar'
import ProfileBar from '../navs/profileBar'
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import Sugg from '../assets/img/sug.png'
import Grade1 from '../assets/img/minAssets/grade.png'
import DashToggle from '../assets/img/minAssets/dashToggle.png'
import Cup from '../assets/img/minAssets/cup.png'
import User1 from '../assets/img/user1.jpeg'
import Fire from '../assets/img/minAssets/fire.png'
import styles from '../ranking/ranking.css';
import { Progress } from 'semantic-ui-react'
// import Canvas from 'react-canvas-js'
const square = { width: 50, height: 50, marginBottom: 0, padding: '0px !important', borderRadius: '50%' }
const panes = [
  {
    menuItem: 'Curiculum',
    render: () => <Tab.Pane attached={false}><ButtonToolbar className="mgBtm2">
    <ToggleButtonGroup className="btnGroup1" type="radio" name="options" defaultValue={1}>
    <ToggleButton 
      className="Rectangle-118 bluecolor sixteenFontSize mgRgh mgBtm1 centerFlex alignFlex "
    key='1'
    value='British'
    >
      British
    </ToggleButton>
    <ToggleButton 
      className="Rectangle-118 bluecolor sixteenFontSize mgRgh mgBtm1 centerFlex alignFlex "
    key='1'
    value='British'
    >
      Nigeria
    </ToggleButton>
    <ToggleButton 
      className="Rectangle-118 bluecolor sixteenFontSize mgRgh mgBtm1 centerFlex alignFlex "
    key='1'
    value='British'
    >
      America
    </ToggleButton>
      
    </ToggleButtonGroup>
  </ButtonToolbar></Tab.Pane>,
  },
  {
    menuItem: 'Class',
    render: () => <Tab.Pane attached={false}>Tab 2 Content</Tab.Pane>,
  },
  {
    menuItem: 'Subject',
    render: () => <Tab.Pane attached={false}>Tab 3 Content</Tab.Pane>,
  },
  {
    menuItem: 'Topic',
    render: () => <Tab.Pane attached={false}>Tab 4 Content</Tab.Pane>,
  },
]
@observer
class Dashboards extends Component{

  constructor(props) {
    super(props);
  this.state = { 
     open: false,
     }
    //  this.open = this.open.bind(this);
     }

     show = (dimmer) => () => this.setState({ dimmer, open: true })
     close = () => this.setState({ open: false })

//   saveAndContinue = (e) => {
//     e.preventDefault()
//     this.props.nextStep()
// }
componentDidMount() {
   
    ExamStore.updateNavar('loggedIn');

  }
  render(){ 
    const { open, dimmer } = this.state
    let pathNAme = this.props.location.pathname
    // alert(pathNAme);
	
    return ( 
    <div style={styles.RankingWrap} className="flexDisplayColume DashForebg">
       <div className="flexDisplayColume alignCenterFlex DashForebgInner">
           <div className="fullWidth dashNaveSty"><DashNav/></div>
           <div className="fullWidth flexDisplay spa234">
               {/* sidebar */}
                <Sidebar/>

               {/* sidebar */}
         <div className="screenIner">
           <div className="centerFlex mgBtm3">
           <div className="equalWidth2 leftFlex font500 TewntyFiveFontSize bluecolor"> SUGGESTED TUTORIALS</div>
           <div className="equalWidth2 rightFlex">
                {/* <img  onClick={this.show(true)} className="toggleClicker" src={DashToggle} alt="toggle" />  */}
           </div>
           </div>
         <Grid stackable columns={4} className="justifyFlex">
    <Grid.Column className="columsuggest">
      <Segment className="noElement noPadding">
        <div className="userRankingWrapSugg flexDisplayColume alignCenterFlex justifyFlex" style={{background:'fff'}}>
         <img className="sugImg" src={'https://res.cloudinary.com/leaningoutcome/image/upload/v1581071001/Q_images/pdf_lh19qs.png'} style={{objectFit:'contain'}} alt=""/>
         
         <p className="sugText centerFlex fourteenFontSize noMargin">Mathematics</p>
         
        </div>
      </Segment>
    </Grid.Column>
    <Grid.Column className="columsuggest">
      <Segment className="noElement noPadding">
      <div className="userRankingWrapSugg flexDisplayColume alignCenterFlex justifyFlex" style={{background:'fff'}}>
         <img className="sugImg" src={'https://res.cloudinary.com/leaningoutcome/image/upload/v1581071468/Q_images/audio_z2grbf.png'} style={{objectFit:'contain'}} alt=""/>
         
         
         <p className="sugText centerFlex fourteenFontSize noMargin">English</p>
         
        </div>
      </Segment>
    </Grid.Column>
    <Grid.Column className="columsuggest">
      <Segment className="noElement noPadding">
      <div className="userRankingWrapSugg flexDisplayColume alignCenterFlex justifyFlex" style={{background:'fff'}}>
         <img className="sugImg" src={'https://res.cloudinary.com/leaningoutcome/image/upload/v1581071837/Q_images/video_evfbaf.png'} style={{objectFit:'contain'}} alt=""/>
         
         <p className="sugText centerFlex fourteenFontSize noMargin">PHE</p>
         
        </div>
      </Segment>
    </Grid.Column>
    <Grid.Column className="columsuggest">
      <Segment className="noElement noPadding">
      <div className="userRankingWrapSugg flexDisplayColume alignCenterFlex justifyFlex" style={{background:'fff'}}>
         <img className="sugImg" src={'https://res.cloudinary.com/leaningoutcome/image/upload/v1581071001/Q_images/pdf_lh19qs.png'} style={{objectFit:'contain'}} alt=""/>
         
         <p className="sugText centerFlex fourteenFontSize noMargin">GEOGRAPHY</p>
         
        </div>
      </Segment>
    </Grid.Column>
  </Grid>
        </div>
        {/* Profile Bar */}
        <ProfileBar/>
        {/* Profile Bar */}
         </div>
       </div>
       <Modal 
        // open={open} 
        centered={false}
        dimmer={dimmer} open={open} onClose={this.close}
          closeOnEscape={false}
          // closeOnDimmerClick={false}
          size={'small'}
        >
    <Modal.Content className="flexDisplayColume whiteBackground minheight">
      <div className="centerFlex font500 sixteenFontSize mgBtm3">Nigerial + JSS + Social Studies + Life Studies</div>

      <Tab menu={{tabular: false }} panes={panes} className="tabMysty" />
    </Modal.Content>
    <Modal.Actions className="centerFlex alignCenterFlex tabMystyFooter">
      <Button className="centerFlex alignFlex btnBoxShadow1" style={styles.checkNow}>
        Check Now
      </Button>
     
    </Modal.Actions>
  </Modal>
        
        

  </div>
)
}
}

export default Dashboards
