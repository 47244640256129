export default {
    RankingWrap: {
         marginTop: '-15px',
       },
       picDisplay:{
        //    height: '230px',
        paddingBottom: '8%',
       },
       checkNow: {
        width: 160,
        height: 39,
        borderRadius: 3,
        backgroundColor: '#1aab39',
        fontSize: 16,
        marginTop: 20,
        color: '#fff'
        },
       minWrap:{
        borderRadius: '50%',
        height: '100%',
        width: '100%',
        border: 'solid 1px #0e5e9e',
        color:'#0e5e9e',
       },
       minWrap2:{
        borderRadius: '50%',
        height: '100%',
        width: '100%',
        border: 'solid 1px #0e5e9e',
        color:'#0e5e9e',
        marginLeft: '20px',
        marginRight: '20px',
       },
       FirstDp:{
        borderRadius: '50%',
        border: "solid 6px #00d280",
        width: '225px',
        height: '225px',
        position: 'relative',
        marginTop: '-100px',
       },
       secondDp:{
        borderRadius: '50%',
        border: "solid 6px #fc911d",
        width: '150px',
        height: '150px',
        position: 'relative',
       },
       secondDp2:{
        borderRadius: '50%',
        border: "solid 6px #37cfb1",
        width: '150px',
        height: '150px',
        position: 'relative',
       },
       positionDisplay:{
        position: 'absolute',
        height: '45px',
        width: '45px',
        borderRadius: '50%',
        bottom: '-23px',
        fontSize: '20px',
        fontWeight: 'bold',
        
       },
       positionDisplay2:{
        position: 'absolute',
        height: '35px',
        width: '35px',
        borderRadius: '50%',
        bottom: '-18px',
        fontSize: '20px',
        fontWeight: 'bold',
        
       },
       titlesty:{
        marginBottom: '12%',
        marginTop: '5%',
        fontSize: '30px'
       },
       imgSp:{
        width: '100%',
        height: '100%',
        borderRadius: '50%',
       },
       tableWrap:{
        width: '70%',
        flex:1,
        padding:20
    },
       tableWrap:{
        width: '70%',
        flex:1,
        padding:20,
        marginTop: 74,
        marginLeft: 90,
        marginRight: 313,
    },
    segSty:{
        padding: '10px !important',
    }
       
     }