/* eslint-disable max-len */

import React, { Component } from 'react'
// import ReactPlayer from 'react-player/lib/players/YouTube'
// import { Carousel } from 'react-materialize';
import { Container,Grid,Button ,Image, List, Icon, Card, Form,Flag, Segment, Input } from 'semantic-ui-react'
import ExamStore from '../../stores/exam';
 import { observer } from 'mobx-react';


// import DetailsFour from './DetailsFour';
// import "react-tabs/style/react-tabs.css";
// import "./linkcard.css";

// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";

import Logo from '../assets/img/logo.png'
import Slider from "react-slick";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import Scrollchor from 'react-scrollchor';
import ReactPlayer from 'react-player'
import Anim from '../assets/img/Component2.png'
import file1 from '../assets/img/Icon1.png'
import file2 from '../assets/img/icon2.png'
import file3 from '../assets/img/icon3.png'
import Caro1 from '../assets/img/carou/calculusImg.png'
import Caro2 from '../assets/img/carou/drawingPen.jpg'
import Caro3 from '../assets/img/carou/graphicDesign.png'
import Caro4 from '../assets/img/carou/illu.jpg'
import Tri1 from '../assets/img/minAssets/tri1.png'
import Tri2 from '../assets/img/minAssets/tri2.png'
import CircleDot from '../assets/img/minAssets/circleDot.png'
import Box1 from '../assets/img/minAssets/box1.png'
import Bgside1 from '../assets/img/minAssets/bgShapA.png'
import Parttern from '../assets/img/minAssets/parttern.png'
import DotedBox from '../assets/img/minAssets/dotedBox.png'
import BottomCaroDot from '../assets/img/bottomCaroDot.png'
import TestiImg from '../assets/img/user3.jpeg'
import Ng from '../assets/img/ngflag.png'
import Am from '../assets/img/amFlag.png'
import Br from '../assets/img/brflag.png'
import Learning from '../assets/img/learning.png'
import OnlineTest from '../assets/img/onlineTest.png'
import ScoreRating from '../assets/img/scoreRating.png'
import MathImg from '../assets/img/math.png'
import styles from './landingPage.css.js';
import { Link, BrowserRouter, Route } from 'react-router-dom'
import { withRouter } from 'react-router-dom';

const src = '../assets/img/file4.svg'
const FlagExampleFlag = () => (
  <Segment>
    <Flag name='ng' />
    <Flag name='vg' />
    <Flag name='as' />
  </Segment>
)
const ListExampleBasicShorthand = () => <List items={['Apples', 'Pears', 'Oranges']} />


@observer
class ContainerExampleFluid extends Component{
  constructor(props) {
    super(props);

    this.state = {
        step: 1,
        curriculum: '',
        grade: '',
        subject: [],
        Globesubject: [],
        topic: [],
        counter: '',
        answer: '',
        
    };
    this.handleNotScroll = this.handleNotScroll.bind(this);
  }
  handleNotScroll = (gotoPage) => {
    ExamStore.updateCurriculum(gotoPage);
       this.props.history.push('/exam')
}
  componentDidMount() {
    ExamStore.updateNavar('home');

  }

   render(){ 
    const { children, top, target, ...props } = this.props; 
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const bottomSlide = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrow: true
    };

    return( 
   

      <div>
    <Container stackable fluid className="noPadding noMargin">
    {/* first session */}
    <img src={Bgside1} className="zIndex1 imgUnderImg" alt=""/>
    <Grid className="firstLayer pad8 noMargin">
    <Grid.Column floated='left' width={8} className="fltstyle">
      <div className="leftFlex landingWrap zIndex2" style={{textAlign: 'left',}}>
          <div className="flexDisplayColume alignFlexStart textWidth landingWrapTxt1">
          <p className="noMargin txtLand mainText" style={styles.pStyle}> Is your child really learning?</p>
          <p className="noMargin txtLand" style={styles.pStyle3}> A unified system to check students performance</p>
          <div className=""><Link style={{display:'flex'}} to={'/exam'}><Scrollchor to="#Curriculum" > <Button className="centerFlex alignFlex btnBoxShadow1" positive style={styles.lMore2}>START NOW</Button></Scrollchor></Link></div>
          </div>
      </div>
    </Grid.Column>
    <Grid.Column floated='right' width={8} className="ImgRight">
      <Image className="width1 zIndex2" src={Anim} alt="img" />
    </Grid.Column>
    <div className="lineScribe" >
    <img className="fullHeight" src={Parttern} alt=""/>
    </div>
    <img className="AsoluteBtm" src={DotedBox} alt=""/>
    
  </Grid>


    {/* third session */}
    <div className='fullWidth flexDisplayColume thirdLayer pad8' id='Features' style={styles.secondLayer} style={{background:'#fff'}}>
        <div className='centerFlex headLine2' style={styles.secondLayerSubject}>Features</div>
        <Grid stackable columns={3} style={{}}>
            <Grid.Column>
                <div className="fullWidth flexDisplayColume alignFlex">
                    <div className='centerFlex alignFlex' style={styles.circlewrap}><img  src={OnlineTest} alt="" style={styles.thirdSessionIconStyle1}/></div>
                    <p style={styles.thirdSessionTopic1}>Online Testing</p>
                    {/* <p style={styles.featuresNote}>make CSS modules work with Webpack you only have to include the modules mentioned above and add the following loader to your webpack</p> */}
                    <p style={styles.featuresNote1} className="textCenter">Provides parents with an avenue for testing their child's understanding and retention of topics taught in school</p>
               </div>
            </Grid.Column>
            <Grid.Column>
                <div className="fullWidth flexDisplayColume alignFlex">
                    <div className='centerFlex alignFlex' style={styles.circlewrap}><img src={Learning} alt="" style={styles.thirdSessionIconStyle1_2}/></div>
                    <p style={styles.thirdSessionTopic1}>Learning Analytics</p>
                    {/* <p style={styles.featuresNote}>make CSS modules work with Webpack you only have to include the modules mentioned above and add the following loader to your webpack</p> */}
                    <p style={styles.featuresNote1} className="textCenter"> Gives you an insight into your child's learning progress, providing you with accurate statistical data</p>
               </div>
            </Grid.Column>
            <Grid.Column>
                <div className="fullWidth flexDisplayColume alignFlex">
                    <div className='centerFlex alignFlex' style={styles.circlewrap}><img src={ScoreRating} alt="" style={styles.thirdSessionIconStyle1_3}/></div>
                    <p style={styles.thirdSessionTopic1}>Score Rating</p>
                    {/* <p style={styles.featuresNote}>make CSS modules work with Webpack you only have to include the modules mentioned above and add the following loader to your webpack</p> */}
                    <p style={styles.featuresNote1} className="textCenter">See how well your child is doing based on a ranking system that consists of their peers all across our platform</p>
               </div>
            </Grid.Column>
           
            
        </Grid>
    </div >
    {/* third session ends */}

      {/* third session */}
    <div className='fullWidth flexDisplayColume thirdLayer pad8' id='Curriculum' style={styles.secondLayer} style={{background:'#F7FAFC'}}>
        <div className='centerFlex headLine2' style={styles.secondLayerSubject}>Curriculum</div>
        <Grid stackable columns={3} className="displayFlexSpaceAround flexWrap" >
            <Grid.Column style={styles.flagShow}>
                <div className="fullWidth flexDisplayColume alignFlex hoverEvt" onClick={() => this.handleNotScroll('Nigerian/3')} style={styles.sys_flagWrap}>
                    <img src={Ng} alt="Ng-Flag"  style={styles.sys_flag}/>
                    <p style={styles.sys_flagName}>Nigerian</p>
               </div>
            </Grid.Column>
            <Grid.Column style={styles.flagShow}>
                <div className="fullWidth flexDisplayColume alignFlex hoverEvt" onClick={() => this.handleNotScroll('British/1')} style={styles.sys_flagWrap}>
                    <img src={Br} alt="Br-Flag"  style={styles.sys_flag}/>
                    <p style={styles.sys_flagName}>British</p>
               </div>
            </Grid.Column>
            <Grid.Column style={styles.flagShow}>
                <div className="fullWidth flexDisplayColume alignFlex hoverEvt" onClick={() => this.handleNotScroll('American/2')} style={styles.sys_flagWrap}>
                    <img src={Am} alt="Am-Flag"  style={styles.sys_flag}/>
                    <p style={styles.sys_flagName}>American</p>
               </div>
            </Grid.Column>
           
            
        </Grid>
    </div >
    <div className="fullWidth flexDisplayColume alignFlex pad8">
      <div className="centerFlex headLine" style={styles.secondLayerSubject}>
        Subject
      </div>
      <div className=" fullWidth displayFlexSpaceAround flexWrap">
       <div className="hoverSubjectList" style={styles.subjectList}>MATHEMATICS</div>
       <div className="hoverSubjectList" style={styles.subjectList}>ENGLISH</div>
       <div className="hoverSubjectList" style={styles.subjectList}>Technology</div>
       <div className="hoverSubjectList" style={styles.subjectList}>lifestyle</div>
      </div>
    {/* Carousel */}

    <div className="fullWidth">
        <Slider {...settings} className="CarouSubject">
          <div className="centerFlex displayflex pad3">
            <img className="fullWidth imgCarou" src={Caro1} alt=""/>
            <div className="imgCarou2" alt=""></div>
            <div className="imgCarou2" alt="">MATHEMATICS</div>
          </div>
          <div className="centerFlex displayflex pad3">
          <img className="fullWidth imgCarou" src={Caro2} alt=""/>
          <div className="imgCarou2" alt="">ENGLISH</div>
          </div>
          <div className="centerFlex displayflex pad3">
          <img className="fullWidth imgCarou" src={Caro3} alt=""/>
          <div className="imgCarou2" alt="">ENGLISH</div>
          </div>
          <div className="centerFlex displayflex pad3">
          <img className="fullWidth imgCarou" src={Caro4} alt=""/>
          <div className="imgCarou2" alt="">ENGLISH</div>
          </div>
          <div className="centerFlex displayflex pad3">
          <img className="fullWidth imgCarou" src={MathImg} alt=""/>
          <div className="imgCarou2" alt="">ENGLISH</div>
          </div>
          <div className="centerFlex displayflex pad3">
          <img className="fullWidth imgCarou" src={MathImg} alt=""/>
          <div className="imgCarou2" alt="">ENGLISH</div>
          </div>
          <div className="centerFlex displayflex pad3">
          <img className="fullWidth imgCarou" src={MathImg} alt=""/>
          <div className="imgCarou2" alt="">ENGLISH</div>
          </div>
          <div className="centerFlex displayflex pad3">
          <img className="fullWidth imgCarou" src={MathImg} alt=""/>
          <div className="imgCarou2" alt="">ENGLISH</div>
          </div>
        </Slider>
      </div>

    {/* Carousel */}

    </div>
  {/* fifth session */}

  <div className='fullWidth flexDisplayColume thirdLayer pad1' id='samplecode' style={styles.secondLayer} style={{background:'#F7FAFC'}}>
        <div className='centerFlex padTop1 headLine2' style={styles.secondLayerSubject}>How It Works</div>
        <Grid stackable style={{padding:0}}>
            <Grid.Column width={8} className="noPadding" style={styles.flagShow2}>
                <div className="fullWidth flexDisplayColume alignFlex" style={styles.sys_flagWrap2_2}>
                   <p className="alignVideoText">
                   Donec dapibus mauris id odio ornare tempus. Duis sit amet accumsan justo, 
                   quis tempor ligula. Quisque quis pharetra felis. Ut quis consequat orci, at 
                   consequat felis. Suspendisse auctor laoreet placerat. Nam et risus non 
                   lacus dignissim lacinia sit amet nec eros. Nulla vel urna quis libero 
                   pharetra varius. Nulla tellus nunc, malesuada at scelerisque eget, cursus 
                   at eros. Maecenas pellentesque lacus quis erat eleifend sagittis. Sed vel 
                   maximus ante, quis mattis neque. Nullam dapibus erat sed nulla cursus 
                   accumsan. Nulla volutpat libero lacinia venenatis sodales. Ut in 
                   pellentesque velit.
                   </p>
               </div>
            </Grid.Column>
            <Grid.Column width={8} >
                <div className="fullWidth flexDisplayColume alignFlex videoWrap" style={styles.sys_flagWrap2}>
                <ReactPlayer  controls={true} url='https://res.cloudinary.com/leaningoutcome/video/upload/v1580917298/Q_images/Location_for_Kids_-_ON_IN_UNDER_ABOVE_BETWEEN_NEXT_TO_-_Kid_s_Vocabulary_-_Preschool_Learning_ygnpms.mp4'   className="fullWidth2 videoSound "/>

               </div>
            </Grid.Column>
           
            
        </Grid>
    </div >
    
    {/* fifth session ends */}

    {/* sixth session */}
    <div className='fullWidth flexDisplayColume alignFlex' id="Pricing" style={styles.secondLayerSty2} >
    <div className='centerFlex headLine' style={styles.secondLayerSubject3}>Pricing</div>
    <img src={CircleDot} className="AsoluteBtm2 PiconSty1" alt/>
    <Card.Group itemsPerRow={3} className="justifyFlex fullWidth flexWrap allPriceCard">
    <Card >
            <p className="thirtyFontSize textCenter PriceHeader">STARTER</p>
      <List className="MypaddingBy">
    <List.Item className="listItem"> 
      <List.Content className="bluecolor centerFlex" style={styles.priceAmount}> <span>&#8358;</span> 50,000</List.Content>
    </List.Item>
    <List.Item className="listItem">
      <List.Icon style={styles.cardPriceListIcon} name='check' />
      <List.Content style={styles.cardPriceList}>No of questions  50</List.Content>
    </List.Item>
    <List.Item className="listItem">
      <List.Icon style={styles.cardPriceListIcon} name='check' />
      <List.Content style={styles.cardPriceList}>Attempt Analysis</List.Content>
    </List.Item>
    <List.Item className="listItem">
      <List.Icon style={styles.cardPriceListIcon} name='check' />
      <List.Content style={styles.cardPriceList}> Invite  Friend </List.Content>
    </List.Item>
    <List.Item className="listItem">
      <List.Icon style={styles.cardPriceListIcon} name='check' />
      <List.Content style={styles.cardPriceList}> Multiple Subject </List.Content>
    </List.Item>
  </List>
    <p className="thirtyFontSize buyNow blueColor textCenter">Buy Now</p>
    </Card>
    <Card className="proCard">
          <p className="thirtyFontSize whiteColor textCenter PriceHeader">PRO</p>
      <List className="MypaddingBy whiteColor">
      <List.Item className="listItem"> 
      <List.Content className="whiteColor centerFlex" style={styles.priceAmount}> <span>&#8358;</span> 50,000</List.Content>
    </List.Item>
    <List.Item className="listItem">
      <List.Icon style={styles.cardPriceListIcon2} name='check' />
      <List.Content style={styles.cardPriceList}>No of questions  200</List.Content>
    </List.Item>
    <List.Item className="listItem">
      <List.Icon style={styles.cardPriceListIcon2} name='check' />
      <List.Content style={styles.cardPriceList}>Attempt Analysis</List.Content>
    </List.Item>
    <List.Item className="listItem">
      <List.Icon style={styles.cardPriceListIcon2} name='check' />
      <List.Content style={styles.cardPriceList}> Invite  Friend </List.Content>
    </List.Item>
    <List.Item className="listItem">
      <List.Icon style={styles.cardPriceListIcon2} name='check' />
      <List.Content style={styles.cardPriceList}> Multiple Subject </List.Content>
    </List.Item>
  </List>
    <p className="thirtyFontSize buyNow whiteColor textCenter">Buy Now</p>
    </Card>
    <Card  style={{marginBottom:' 0px'}} className="enterpriseCard">
        <p className="thirtyFontSize whiteColor textCenter PriceHeader">ENTERPRISE</p>
      <List className="MypaddingBy whiteColor">
      <List.Item className="listItem"> 
      <List.Content className="whiteColor centerFlex" style={styles.priceAmount}> <span>&#8358;</span> 50,000</List.Content>
    </List.Item>
    <List.Item>
      <List.Icon style={styles.cardPriceListIcon2} name='check' />
      <List.Content>Request School Package</List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name='' />
      <List.Content></List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name='' />
      <List.Content>
        <a href='mailto:jack@semantic-ui.com'></a>
      </List.Content>
    </List.Item>
    {/* <List.Item>
      <List.Icon name='check' />
      <List.Content>
        <a href='http://www.semantic-ui.com'></a>
      </List.Content>
    </List.Item> */}
  </List>
    <p className="thirtyFontSize buyNow whiteColor textCenter"> Buy Now</p>
    </Card>
    
  </Card.Group>
    </div>
    {/* sixth session ends */}
    {/* seven session */}
    <div className='fullWidth flexDisplayColume thirdLayer' id='Features' style={styles.secondLayer} style={{background:'#fff', padding:'5% 10%'}}>
        <div className='centerFlex headLine2' style={styles.secondLayerSubject}>Testimonies
        </div>
        <div className="BtmSlideArrow">
        <Slider {...bottomSlide} className="tizy">
          <div>
            <div className="centerFlex fullWidth"  style={{padding: '5%',position: 'relative', zIndex: 2,}}>
              <div className="whiteColor flexDisplayColume textCenter carou alignFlex" style={{padding: '5% 7%', position: 'relative', zIndex:2}}>
                <img src={TestiImg} className="TestiImgSty" alt=""/> 
                <div style={{  fontSize: 23,lineHeight: 1.39}}>Christine Lucas</div>
                <div style={{  fontSize: 20, lineHeight: 1.4}}>(Parent)</div>
                <div style={{  fontSize: 16, lineHeight: 1.44, marginTop: '5%', marginBottom: '3%',}}>
                  Phasellus finibus enim nulla, quis ornare odio facilisis eu. Suspendisse 
                  ornare ante sit amet arcu semper, vel eleifend tortor egestas. Aenean 
                  luctus, lorem in hendrerit interdum, leo orci egestas diam.
                </div>
                
              </div>
              <img src={BottomCaroDot} className="dotCaro" alt=""/>
            </div>
          </div>
          <div>
            <div className="centerFlex fullWidth"  style={{padding: '5%',position: 'relative',zIndex: 2,}}>
              <div className="whiteColor flexDisplayColume textCenter carou alignFlex" style={{padding: '5% 7%', position: 'relative'}}>
                <img src={TestiImg} className="TestiImgSty" alt=""/> 
                <div style={{  fontSize: 23,lineHeight: 1.39}}>Steve Job</div>
                <div style={{  fontSize: 20, lineHeight: 1.4}}>(Parent)</div>
                <div style={{  fontSize: 16, lineHeight: 1.44, marginTop: '5%', marginBottom: '3%',}}>
                  Phasellus finibus enim nulla, quis ornare odio facilisis eu. Suspendisse 
                  ornare ante sit amet arcu semper, vel eleifend tortor egestas. Aenean 
                  luctus, lorem in hendrerit interdum, leo orci egestas diam.
                </div>
                
              </div>
              <img src={BottomCaroDot} className="dotCaro" alt=""/>
            </div>
          </div>
          <div>
            <div className="centerFlex fullWidth"  style={{padding: '5%',position: 'relative', zIndex: 2,}}>
              <div className="whiteColor flexDisplayColume textCenter carou alignFlex" style={{padding: '5% 7%', position: 'relative'}}>
                <img src={TestiImg} className="TestiImgSty" alt=""/> 
                <div style={{  fontSize: 23,lineHeight: 1.39}}>Mark zuckerberg</div>
                <div style={{  fontSize: 20, lineHeight: 1.4}}>(Parent)</div>
                <div style={{  fontSize: 16, lineHeight: 1.44, marginTop: '5%', marginBottom: '3%',}}>
                  Phasellus finibus enim nulla, quis ornare odio facilisis eu. Suspendisse 
                  ornare ante sit amet arcu semper, vel eleifend tortor egestas. Aenean 
                  luctus, lorem in hendrerit interdum, leo orci egestas diam.
                </div>
               
              </div>
              <img src={BottomCaroDot} className="dotCaro" alt=""/>
            </div>
          </div>
        </Slider>
      </div>
        </div>
    {/* seventh session */}
      {/* eight session */}
      <div className='fullWidth flexDisplayColume thirdLayer footerSty' style={styles.secondLayer} style={{background:'#fff', padding: '5% 5% 1.5% 5%', color: '#333'}}>
      <Grid stackable columns={4} className="btmBorder1">
            <Grid.Column>
                <div className="fullWidth flexColumeflexStart ">
                  <img src={Logo} alt="" style={{width: 138,}} />
                    <p className="fourteenFontSize  leftAlignText marginInc3">
                    EDVES is a full-featured School Management Software that encompasses all 
                    school needs, automating processes from enrollment, payment, 
                    result processing, e-learning, etc.
                    </p>
                    {/* <p className="sixteenFontSize  leftAlignText" >+2341043562</p>
                    <p  className="sixteenFontSize  leftAlignText">hello@edves.net</p> */}
               </div>
            </Grid.Column>
            <Grid.Column>
                <div className="fullWidth flexColumeflexStart ">
                    <p className='TewntyFiveFontSize  bluecolor font500 alignFlex'>Useful Links</p>
                    <p className="sixteenFontSize font500  leftAlignText">How It works</p>
                    <p className="sixteenFontSize font500  leftAlignText">Features</p>
                    <p className="sixteenFontSize  font500 leftAlignText">Curriculum</p>
                    <p className="sixteenFontSize  font500 leftAlignText">Pricing</p>
               </div>
            </Grid.Column>
            <Grid.Column>
                <div className="fullWidth flexColumeflexStart ">
                    <p className='TewntyFiveFontSize  bluecolor font500 alignFlex'>Support</p>
                    <p className="sixteenFontSize leftAlignText">support@edves.net</p>
                    <p className="sixteenFontSize leftAlignText">+2348096200326</p>
                    <p className="sixteenFontSize leftAlignText">140, Borno Way, Adekunle, Ebute Meta, Lagos, Nigeria</p>
               </div>
            </Grid.Column>
            <Grid.Column>
                <div className="fullWidth flexColumeflexStart ">
                    <p className='thirtyFontSize bluecolor font500 alignFlex'>Subscribe Now</p>
                    <Input className="subScribeInput" type="text"  />
                    <Button className="sixteenFontSize whiteColor subScribeBtn" >SUBSCRIBE NOW</Button>
               </div>
            </Grid.Column>
  </Grid>
                 
    <p className="" style={{fontSize:'14px', marginTop: '20px', textAlign:'center', color:'#9d9d9d', }}>EDVES 2020</p>
    
    </div>
     {/* eight session ends */}

    </Container>


  </div>


)
}}


export default withRouter(ContainerExampleFluid)
