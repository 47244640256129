import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import App from './App';
import axios from 'axios';
// import Routes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

axios.defaults.baseURL = 'https://cors-anywhere.herokuapp.com/https://api2.edves.net/edvesacademy/v1/';
// axios.defaults.baseURL = 'https://api2.edves.net/edvesacademy/v1/';
// https://api2.edves.net/edvesacademy/v1/fetchCurriculum
// axios.defaults.headers.common['Authorization'] = '';
//    axios.defaults.headers.post['Content-Type'] = 'application/json';
// 
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//   axios.defaults.headers.common['Content-Type'] = 'application/json';
//   axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axios.interceptors.request.use(request => {
    //console.log(request);
    // Edit request config
    return request;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    //   console.warn(response);
      // Edit request config
      return response;
  }, error => {
      console.log(error);
      return Promise.reject(error);
  });

ReactDOM.render(
    <BrowserRouter>
    <App />
 </BrowserRouter>
 ,
   
    document.getElementById('root')

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
