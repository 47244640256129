// import _ from 'lodash'
import React, { Component } from 'react'
import { Grid , Button, Segment, List, Image} from 'semantic-ui-react'
import User3 from '.././assets/img/user3.jpeg';
import ExamStore from '../../stores/exam';
import { observer } from 'mobx-react';
import DashNav from '../navs/dashboardNav'
import Sidebar from '../navs/sidebar'
import ProfileBar from '../navs/profileBar'
import Grade1 from '../assets/img/minAssets/grade.png'
import User1 from '../assets/img/user1.jpeg'
import Fire from '../assets/img/minAssets/fire.png'
import SOiner from './lineChart'
import AvgChart from './chartAvg'
import styles from '.././ranking/ranking.css';
import { Progress } from 'semantic-ui-react'
// import Canvas from 'react-canvas-js'
const square = { width: 50, height: 50, marginBottom: 0, padding: '0px !important', borderRadius: '50%' }

@observer
class Dashboards extends Component{
//   saveAndContinue = (e) => {
//     e.preventDefault()
//     this.props.nextStep()
// }
componentDidMount() {
   
    ExamStore.updateNavar('loggedIn');

  }
  render(){ 

    let pathNAme = this.props.location.pathname
    // alert(pathNAme);
	
    return ( 
    <div style={styles.RankingWrap} className="flexDisplayColume DashForebg">
       <div className="flexDisplayColume alignCenterFlex DashForebgInner">
           <div className="fullWidth dashNaveSty"><DashNav/></div>
           <div className="fullWidth flexDisplay spa234">
               {/* sidebar */}
                <Sidebar/>

               {/* sidebar */}
         <div className="screenIner">
         <Grid stackable columns={2}>
    <Grid.Column className="relentColumn">
      <Segment>
        <div style={{height: 300}}>
            <SOiner/>
        </div>
      {/* <SOiner/> */}
      </Segment>
    </Grid.Column>
    <Grid.Column className="relentColumn">
      <Segment>
      <div style={{height: 300}}>
            <AvgChart/>
        </div>
      </Segment>
    </Grid.Column>
    <Grid.Column className="relentColumn">
      <Segment>
        <div className="flexDisplayColume">
          <div className="displayflex">
            <div className="displayflex"><div className="userOnline centerFlex alignCenterFlex"><img style={{height: 20}} src={Fire} alt=""/></div></div>
            <div className="flexDisplayColume justifyFlexSpaceBtw mgLeft">
              <div className="displayflex font500 twelveFontSize ">Physical Education</div>
              <div className="displayflex circleTitle LightBlueColor">20 Students are taking the test</div>
            </div>
          </div>
        <div className="displayflex mgTop1 imgInd">
          <div className="userOnline2 ">
              <img src={User3} className="userOnlineImg" alt=""/>
          </div>
          <div className="userOnline2 ">
              <img src={User1} className="userOnlineImg" alt=""/>
          </div>
          <div className="userOnline2 ">
              <img src={User3} className="userOnlineImg" alt=""/>
          </div>
          <div className="userOnline2 ">
              <img src={User1} className="userOnlineImg" alt=""/>
          </div>
          <div className="userOnline2 ">
              <img src={User3} className="userOnlineImg" alt=""/>
          </div>
          <div className="userOnline2 ">
            <div className="userOnlineImg2 centerFlex alignCenterFlex" alt=""> +9</div>
          </div>
        </div>
        </div>
      </Segment>
      <Segment className="gradeSide">
        <div className="displayflex fullHeight miniGradeCheck">
          <div className="flexDisplayColume justifyFlexSpaceBtw content1">
            <div className="displayflex twelveFontSize whiteColor">
              Keep track of those that are leading In the leaders board
            </div>
            <div className="displayflex">
              <Button className="button112 font500 centerFlex alignCenterFlex pad5">Check now</Button>
            </div>
          </div>
          <div className="centerFlex alignFlexBtm">
            <img className="grade1Img" src={Grade1} alt="" />
          </div>
        </div>
      </Segment>
    </Grid.Column>
    <Grid.Column className="relentColumn">
      <Segment>
      <div className="flexDisplayColume">
        <div className="flexDisplay alignFlex justifyFlexSpaceBtw listItem">
					<span className="elevenFontSize font500"> Latest Result</span>
					{/* <span className="elevenFontSize font500"> Month</span> */}
					{/* <div className=""></div> */}
				</div>
        <div className="displayflex progressWrap">
            <div className="displayflex">
              <div className="failOnline font500 sixteenFontSize centerFlex alignCenterFlex">F</div>
            </div>
            <div className="flexDisplayColume justifyFlexSpaceBtw mgLeft">
              <div className="displayflex font500 twelveFontSize ">Mathematica</div>
              <div className="displayflex circleTitle LightBlueColor">1 Students are taking the test</div>
            </div>
            <div className="displayflex alignCenterFlex justifyFlex mgLeft equalWidth2">
              <Progress percent={60} size='tiny' className="progress1 progressPass" />  
              {/* <Progress percent={60} size='tiny' className="progress1 progressFail" />   */}
              <span className="font500 twelveFontSize mgLeft passPercentage">60%</span>
              {/* <span className="font500 twelveFontSize mgLeft failPercentage">60%</span> */}
            </div>
        </div>
        <div className="displayflex progressWrap">
            <div className="displayflex">
              <div className="passOnline font500 sixteenFontSize centerFlex alignCenterFlex">C</div>
            </div>
            <div className="flexDisplayColume justifyFlexSpaceBtw mgLeft">
              <div className="displayflex font500 twelveFontSize ">English</div>
              <div className="displayflex circleTitle LightBlueColor">3 Students are taking the test</div>
            </div>
            <div className="displayflex alignCenterFlex justifyFlex mgLeft equalWidth2">
              <Progress percent={50} size='tiny' className="progress1 progressPass" />  
              <span className="font500 twelveFontSize mgLeft passPercentage">50%</span>
            </div>
        </div>
        <div className="displayflex progressWrap">
            <div className="displayflex">
              <div className="excellentOnline font500 sixteenFontSize centerFlex alignCenterFlex">A</div>
            </div>
            <div className="flexDisplayColume justifyFlexSpaceBtw mgLeft">
              <div className="displayflex font500 twelveFontSize ">Physical Education</div>
              <div className="displayflex circleTitle LightBlueColor">20 Students are taking the test</div>
            </div>
            <div className="displayflex alignCenterFlex justifyFlex mgLeft equalWidth2">
              <Progress percent={90} size='tiny' className="progress1 progressExccelent" />  
              <span className="font500 twelveFontSize mgLeft exccelentPercentage">90%</span>
            </div>
        </div>
			</div>
      </Segment>
    </Grid.Column>
  </Grid>
         </div>
        {/* Profile Bar */}
        <ProfileBar/>
        {/* Profile Bar */}
         </div>
       </div>
        {/* <div className='centerFlex' style={{maxWidth:'100%',marginTop: '15px'}}>
            <div style={{maxWidth:'55%'}}>
                <div className="fullWidth centerFlex" style={styles.examFooter}>
                <div className="equalWidth leftFlex alignFlex fontBold">1 0f 100</div>
                <div className="equalWidth rightFlex alignFlex"><Button onClick={this.saveAndContinue} className="greenBackground btnInc">Continue</Button></div>
                </div>
            
            </div>
        
        </div> */}
        
        

  </div>
)
}
}

export default Dashboards
