import React, { Component } from 'react';
import logo from './logo.svg';
import Nav2 from './pages/navs/nav2';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Nav from './pages/navs/landingNav';
// import Dashboard from './pages/landingPage/landingPage';
import Home from './pages/landingPage/landingPage';
import Exam from './pages/PrepareExam/allStep';
import Dashboard from './pages/dashboard/dashboard';
import Ranking from './pages/leadershipBoard/leadership';
import Suggested from './pages/suggested/surgested';

import Login from './pages/signIn/signIn';
import Signup from './pages/signUp/signUp';
import { observer } from 'mobx-react'
import ExamStore from './stores/exam';

// import Dashboard from './pages/signIn/signIn';
// import Dashboard from './pages/signUp/signUp';
// import Dashboard from './pages/PrepareExam/allStep';
import './App.css';
  
 
 
const nonNav = '';
 

@observer
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nav1: Nav,
      stuff:Nav,
      // checkedSignIn: false
    };
  }
  

  //  if ( path="/") {
  //     <Nav/>
  //   }
  //   else{
  //     <Nav2/>
  //   }
  render() {
    // let currentLocationPath = {location}  ;
    // let pathNAme = this.props.location.pathname
    // alert(pathNAme);
    // alert(ExamStore.navMonitor);

    let { stuff } = this.state.stuff;
    return (
      
      <Router>
      <div className="App">
      {
        ExamStore.navMonitor==='home'?
  < Nav />
        :
        nonNav
        // alert('not home')

      }
        
        {/* <Home/> */}
      </div>
      <Switch>
        <Route exact path="/" component={()=><Home stuff={stuff} /> } />
        <Route exact path="/home" component={()=><Home/>} /> 
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/exam" component={Exam} />
        <Route exact path="/ranking" component={Ranking} />
        <Route exact path="/suggested" component={Suggested} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
      </Switch>
      </Router>
    );
  }
}

export default App;
