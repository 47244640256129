import React, { Component } from 'react'
import { Button, Header, Image, Modal, Divider, Form  } from 'semantic-ui-react'
import AccountStore from '../../stores/Account'
import { observer } from 'mobx-react';

const Tinsizes = ['tiny']
@observer
class SignUp extends Component{
    render(){
        return(


            <Modal trigger={<Button>Show Modal</Button>}>
           
            <Modal.Content image style={{padding: '6% 5%',}}>
              <div style={{minWidth: '45%'}}>
                <div>
                    <p className="blueColor noMargin" style={{fontSize: '50px',fontWeight: 'bold'}}>Test you Knowledge!</p>
                    <p style={{fontSize: '18px'}}>Sign up to take test on our unified platform</p>
                    <Image wrapped size='medium' src='https://react.semantic-ui.com/images/avatar/large/rachel.png' />
                </div>
              </div>
              <Modal.Description>
              <div>
                    {Tinsizes.map(size => (
                    <Form size={size} key={size}>
                        <Form.Group widths='equal'>
                        <Form.Field control='input' placeholder='First Name' />
                        <Form.Field control='input' placeholder='Last Name' />
                        </Form.Group>
                        <Form.Group widths='equal'>
                        <Form.Field 
                        control='input'
                        placeholder='Gender' 
                      
                        />
                        <Form.Field control='input' placeholder='Age' />
                        </Form.Group>
                        <Form.Field control='input' placeholder='Email' />
                        <Form.Field control='input' placeholder='Password' />
                        <Form.Field control='input' placeholder='ConfirmPassword' />
                        <Form.Field control='input' placeholder='School' />
                        <Form.Field control='input' placeholder='Country' />
                        <Form.Field control='input' placeholder='State' />
                        {/* <Button fluid>Fits to Container</Button> */}
                        <Button  className='blueBackground fontBold' fluid type='submit'>Sign Up</Button>
                        <Divider hidden />
                    </Form>
                    ))}
                </div>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        )
    }

}

export default SignUp
