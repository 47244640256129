import React, { Component, Fragment } from 'react'
import { Menu, Button, Icon, Dropdown, Image} from 'semantic-ui-react'
import styles from './landingNav.css.js';
import Scrollchor from 'react-scrollchor';
import Logo from '../assets/img/logo.png'
import HistoryIcon from '../assets/img/minAssets/history.png'
import RankingIcon from '../assets/img/minAssets/ranking.png'
import TutoIcon from '../assets/img/minAssets/tuto.png'
import {isSignedIn,getUsertype,getMyRole,hasChangePwd} from '../../auth';
import { LogoutNow } from "../../dependency/UtilityFunctions";

// import { BrowserRouter } from 'react-router-dom';

import { Link, BrowserRouter, Route } from 'react-router-dom'
export default class Sidebar extends Component {
  
  constructor(props) {
    super(props);
    this.state = { 
      activeItem: 'How it worksss', 
      loggedIn: 'false', 
      sidebarActive: 'History' ,
      sidebarScreen: false 
    }
    this.triggerSidebarScr = this.triggerSidebarScr.bind(this);
    this.triggerSidebarActive = this.triggerSidebarActive.bind(this);
  }
  triggerSidebarScr() {
    this.setState({
      sidebarScreen: !this.state.sidebarScreen
    });
  }
  triggerSidebarActive(data) {
    this.setState({
      sidebarActive: data
    });
  }
  handleItemClick = (e, { name }) => this.setState({ activeItem: name })
  
  componentDidMount() {
    let loginstate=isSignedIn();
    console.log(loginstate + 'tom')
    }
    handleLogout = () => {
      LogoutNow();
      // this.props.logoutUser(this.props.history);
    };
  render() {
    let loginstate=isSignedIn();
    const { activeItem } = this.state 
    const userImg = <Image avatar className="noMargin" src='https://react.semantic-ui.com/images/avatar/small/helen.jpg' />;

    
    return (
      <Fragment>
        <Icon name="bars" className="profileBarIcon2" onClick={this.triggerSidebarScr}/>
        <div className={`sidebarWrap  ${this.state.sidebarScreen == false ? '': 'DisplaySidebarWrap'}`}> 
        <div className="flexDisplayColume fullWidth sidebarSide1 ">
        <Link to="/dashboard">
            <div 
            className={`fullWidth flexDisplayColume pad1 alignCenterFlex sidebarLink ${this.state.sidebarActive =="History" ? 'sidebarLinkActive':''}`}
              onClick={() => this.triggerSidebarActive('History')}
            >
                <img className="sidebarLinimg" src={HistoryIcon} alt />
                <span className="naveBAse">History</span>
            </div>
            </Link>
            <Link to="/suggested">
            <div className={`fullWidth flexDisplayColume pad1 alignCenterFlex sidebarLink ${this.state.sidebarActive !="Suggested" ? '':'sidebarLinkActive'}`}
            onClick={() => this.triggerSidebarActive('Suggested')}
            >
                <img className="sidebarLinimg" src={TutoIcon} alt />
                <span className="naveBAse">suggested tutorials</span>
            </div>
            </Link>
            <Link to="/ranking">
            <div className={`fullWidth flexDisplayColume pad1 alignCenterFlex sidebarLink ${this.state.sidebarActive =="Leadership" ? 'sidebarLinkActive':''}`}
            onClick={() => this.triggerSidebarActive('Leadership')}
            >
              
                <img className="sidebarLinimg" src={RankingIcon} alt />
                <span className="naveBAse">leadership board</span>
                
            </div>
            </Link>
        </div>
     </div>
     </Fragment>
    )
  }
  
}
