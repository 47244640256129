import { observable, action } from 'mobx';
import qs from 'qs';
import axios from 'axios'
import ls from 'local-storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getuserdata, getusershortProfile } from '../dependency/UtilityFunctions';


class Account {
    @observable regInfo = {
        Reg_firstname: '',
        Reg_lastname: '',
      //  Reg_gender: '',
        Reg_age: '',
        Reg_email: '',
        Reg_password: '',
        Reg_confirm: '',
        Reg_school: '',
         
 
    }
    @observable loginInfo = {
        email: '',
        password: '',
        rememberme: false,
    }
    @observable Verifymail = {
        Vcode: '',
        ErrorMessage: '',

    }
    @observable Updateprofile = {
        fullname: '',
        mobile_number: '',
        gendar: '',
        age_group: '',
        Address: '',
        Company: '',
        Website: '',
        shortinfo: '',
        company_location: '',

    }
    @observable loggedinUserdetails = {
        // Reg_Role: 'fuck you',
    }
    @observable Allcountries = []
    @observable Allstates = []
    @observable disabled = false
    @observable loader_visible = false
    @observable redirectToReferrer = false
    @observable notifications = true
    @observable redirectmode = false
    @observable isprocessing = false
    @observable signupSuccess = false


    @action handleChange(e) {
        const value = e.target[e.target.type === "checkbox" ? "checked" : "value"]
        const name = e.target.name;
        AccountStore.loginInfo[name] = value;
        // console.log(AccountStore.loginInfo.email);
    }
    @action update_profile_send_data(data) {

        AccountStore.Updateprofile = data;
    }
    @action handleInputFieldChange(e, type) {
        const value = e.target[e.target.type === "checkbox" ? "checked" : "value"]
        const name = e.target.name;
        console.log(name)
        console.log(value)
        AccountStore[type][name] = value;
        // console.log(AccountStore.loginInfo.email);
    }
    @action handleReg_Change(e,parent) {
        console.log(e)
        const value = e.target[e.target.type === "checkbox" ? "checked" : "value"]
        const name = e.target.name;
        AccountStore[parent][name] = value;
        // console.log(AccountStore.loginInfo.email);
    }
    @action handleReg_Datechange(date) {
        console.log(date)
        // const value = e.target[e.target.type === "checkbox" ? "checked" : "value"]
        // const name = e.target.name;
        AccountStore.regInfo['Reg_Dob'] = date;
        // console.log(AccountStore.loginInfo.email);
    }
    _handleChangeSelect_any = (stateOption, name, type) => {
        console.log(stateOption.value)
        console.log(name)
        AccountStore[type][name] = stateOption;
        // console.log(`Option selected:`, selectedOption);
        if (name === 'updateProfile_country') {
            AccountStore.GetStates_Country(stateOption.value);
        }
        else {
            console.log('no reques')
        }

    }
    _handleChangeSelect = (stateOption, name) => {
        console.log(stateOption.value)
        console.log(name)
        AccountStore.regInfo[name] = stateOption;
        // console.log(`Option selected:`, selectedOption);
        if (name === 'Reg_country') {
            AccountStore.GetStates_Country(stateOption.value);
        }
        else {
            console.log('no reques')
        }

    }
    @action _updatelocationDetails = (props) => {
        //  console.log(props)
        if (props.isGeolocationEnabled === true) {
            // let check = props.hasOwnProperty("coords");
            // let check = props.hasOwnProperty("coords");
            if (props.coords !== null) {
                AccountStore.regInfo['Reg_longitude'] = props.coords.longitude || '';
                AccountStore.regInfo['Reg_latitude'] = props.coords.latitude || '';
                AccountStore.regInfo['Reg_islocationEnabled'] = props.isGeolocationEnabled;
            }
            else {
                console.log("not back")
            }

        }
        else {
            AccountStore.regInfo['Reg_islocationEnabled'] = props.isGeolocationEnabled;
        }

    }

    @action _ToggleLoader = (status) => {
        this.loader_visible = status;
    }
  
    @action _validateInstagrameLogin = (code) => {
        //const { email, password, rememberme } = AccountStore.loginInfo;
        let data = getuserdata()
        if (code === '') {
            toast.info("Client Code is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }
       

        else {
            let params = {
                access_code: code,
                user_id: data.userid,

            }
            console.log(params);
            this.disabled = true
            axios.post('/validateInstaLogin', qs.stringify(params))
                .then(response => {
                    this.disabled = false

                    console.log(response)

                    if (response.status === 200 || response.status === 201) {
                        let resp = response.data;
                        console.log(resp)
                        let check = resp.status
                        let data = null;
                        if (check === true) {
                            //  alert(check)
                            console.log("here")
                            // data = resp.data;
                            // console.log(data);
                            // this.loggedinUserdetails = data
                            // let data2save = {
                            //     isloggedin: true,
                            //     userid: data.user_id,
                            //     usertype: data.user_type,
                            //     fullname: data.profile.fullname,
                            //     accessToken: 'token',
                            //     username: data.username,
                            //     primumUser: data.ispremium,
                            // }
                            // console.log(data2save)
                            // let stringify_data = JSON.stringify(data);
                            // ls.set('Loggedinstate', data2save);
                            // ls.set('loggedinUserdetails', this.loggedinUserdetails);

                         this.redirectToReferrer = true
                        }
                        else {
                            console.log('error');
                            let resp = response.data;
                            console.warn(resp);
                            let message = resp.message
                            toast.error(message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            this.redirectmode = false
                            this._ToggleLoader(false)

                        }



                        //       this._ToggleLoader(false)
                    }
                    // else if (response.status == 401) {

                    //     alert('invalid login')
                    //     console.log(response.errors)
                    // }
                    // else {

                    // }
                    // let resp = response.data;
                    // // console.warn(resp);    let data=resp.Data;   
                    // console.warn(data);
                    // let Id = data.Id;
                    // let Category = data.Category;
                    // let FirstName = data.FirstName;
                    // let LastName = data.LastName;
                    // //    let FirstName=data.FirstName;
                    // let fullname = FirstName + ' ' + LastName
                    // this.loggedinUserdetails = data;
                    // accountStore._ToggleLoader(false)

                    // return true;
                    //   PersistData('loggedinUserdetails',this.loggedinUserdetails) ;
                    //   this.saveData(Id,Category,fullname);
                    //    
                    // if (resp.StatusCode === '00') {
                    //     let data = resp.Data;
                    //     console.warn(data);
                    //     let Id = data.Id;
                    //     let Category = data.Category;
                    //     let FirstName = data.FirstName;
                    //     let LastName = data.LastName;
                    //     //    let FirstName=data.FirstName;
                    //     let fullname = FirstName + ' ' + LastName
                    //     this.loggedinUserdetails = data;
                    //     // accountStore._ToggleLoader(false)

                    //     // return true;
                    //     // PersistData('loggedinUserdetails', this.loggedinUserdetails);
                    //     // this.saveData(Id, Category, fullname);
                    //     //    

                    // }
                    // else {
                    //     console.log('error');
                    //     // let resp = response.data;
                    //     // console.warn(resp);
                    //     // let message = resp.Message
                    //     // Toast.show(message, Toast.SHORT, Toast.BOTTOM, styletoest);

                    //     // this.redirectmode = false
                    //     // accountStore._ToggleLoader(false)

                    // }
                })
                .catch(err => {
                    //  console.log(typeof(err));
                    if (typeof (err) != undefined) {
                        //    console.log(err.response)
                        let Server_error = err.response;
                        let message = Server_error.data.message
                        console.log(message);
                        // toast.error("Invalid Login Details", {
                        //     position: toast.POSITION.TOP_CENTER
                        // });
                        toast.error(message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        // console.log(err.status)
                        this._ToggleLoader(false)
                        this.isprocessing = false
                        // console.log(err)
                    }
                    else {
                        console.log("undefined")
                    }
                })
        }


    }

    @action _VeifyHandler = (method = 'code', param = '') => {
        let code = null;
        if (param === '') {
            const { Vcode } = AccountStore.Verifymail;
            code = Vcode;
        }
        else {
            code = param;
        }


        if (code === '') {
            toast.info("Verification is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }


        else {
            let params = {
                method: method,
                param: code,

            }
            console.log(params);
            // this.disabled = true
            this.isprocessing = true
            this._ToggleLoader(true)
            axios.post('/verifyEmail', qs.stringify(params))
                .then(response => {
                    // this.disabled = false
                    this.isprocessing = false
                    this._ToggleLoader(false)
                    console.log(response)

                    if (response.status === 200 || response.status === 201) {
                        let resp = response.data;
                        // console.log(resp)
                        let check = resp.status
                        let data = null;
                        if (check === true) {
                            //  alert(check)
                            console.log("here")
                            data = resp.data;
                            console.log(data);
                            this.loggedinUserdetails = data
                            let data2save = {
                                isloggedin: true,
                                userid: data.user_id,
                                usertype: data.user_type,
                                fullname: data.profile.fullname,
                                accessToken: 'token',
                                username: data.username,
                                primumUser: data.ispremium,
                            }
                            console.log(data2save)
                            let stringify_data = JSON.stringify(data);
                            ls.set('Loggedinstate', data2save);
                            ls.set('loggedinUserdetails', this.loggedinUserdetails);

                            this.redirectToReferrer = true
                        }
                        else {
                            console.log('error');
                            let resp = response.data;
                            console.warn(resp);
                            let message = resp.message
                            toast.error(message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            this.redirectmode = false
                            this.isprocessing = false
                            this._ToggleLoader(false)
                            AccountStore.Verifymail.ErrorMessage = message

                        }



                        //       this._ToggleLoader(false)
                    }
                    // else if (response.status == 401) {

                    //     alert('invalid login')
                    //     console.log(response.errors)
                    // }
                    // else {

                    // }
                    // let resp = response.data;
                    // // console.warn(resp);    let data=resp.Data;   
                    // console.warn(data);
                    // let Id = data.Id;
                    // let Category = data.Category;
                    // let FirstName = data.FirstName;
                    // let LastName = data.LastName;
                    // //    let FirstName=data.FirstName;
                    // let fullname = FirstName + ' ' + LastName
                    // this.loggedinUserdetails = data;
                    // accountStore._ToggleLoader(false)

                    // return true;
                    //   PersistData('loggedinUserdetails',this.loggedinUserdetails) ;
                    //   this.saveData(Id,Category,fullname);
                    //    
                    // if (resp.StatusCode === '00') {
                    //     let data = resp.Data;
                    //     console.warn(data);
                    //     let Id = data.Id;
                    //     let Category = data.Category;
                    //     let FirstName = data.FirstName;
                    //     let LastName = data.LastName;
                    //     //    let FirstName=data.FirstName;
                    //     let fullname = FirstName + ' ' + LastName
                    //     this.loggedinUserdetails = data;
                    //     // accountStore._ToggleLoader(false)

                    //     // return true;
                    //     // PersistData('loggedinUserdetails', this.loggedinUserdetails);
                    //     // this.saveData(Id, Category, fullname);
                    //     //    

                    // }
                    // else {
                    //     console.log('error');
                    //     // let resp = response.data;
                    //     // console.warn(resp);
                    //     // let message = resp.Message
                    //     // Toast.show(message, Toast.SHORT, Toast.BOTTOM, styletoest);

                    //     // this.redirectmode = false
                    //     // accountStore._ToggleLoader(false)

                    // }
                })
                .catch(err => {
                    //  console.log(typeof(err));
                    if (typeof (err) != undefined) {
                        //    console.log(err.response)
                        let Server_error = err.response;
                        let message = Server_error.data.message
                        console.log(message);
                        // toast.error("Invalid Login Details", {
                        //     position: toast.POSITION.TOP_CENTER
                        // });
                        toast.error(message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        // console.log(err.status)
                        this._ToggleLoader(false)
                        this.isprocessing = false
                        // console.log(err)
                    }
                    else {
                        console.log("undefined")
                    }
                })
        }


    }
    @action _ResendTokenHandler = () => {
        const { Reg_email } = AccountStore.regInfo;
        // let Reg_email='peace@g.com';

        if (Reg_email === '') {
            toast.info("Email Address is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        else {
            let params = {
                email: Reg_email,


            }
            console.log(params);
            // this.disabled = true
            this.isprocessing = true
            this._ToggleLoader(true)
            axios.post('/resendCode', qs.stringify(params))
                .then(response => {
                    // this.disabled = false
                    this.isprocessing = false
                    this._ToggleLoader(false)
                    console.log(response)

                    if (response.status === 200 || response.status === 201) {
                        let resp = response.data;
                        // console.log(resp)
                        let check = resp.status
                        let data = null;
                        if (check === true) {
                            //  alert(check)
                            console.log("here")

                            console.log(resp);
                            let message = resp.message
                            toast.success(message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                        }
                        else {
                            console.log('error');
                            let resp = response.data;
                            console.warn(resp);
                            let message = resp.message
                            toast.error(message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            this.redirectmode = false
                            this.isprocessing = false
                            this._ToggleLoader(false)
                            AccountStore.Verifymail.ErrorMessage = message

                        }



                    }

                })
                .catch(err => {
                    //  console.log(typeof(err));
                    if (typeof (err) != undefined) {
                        //    console.log(err.response)
                        let Server_error = err.response;
                        let message = Server_error.data.message
                        console.log(message);
                        // toast.error("Invalid Login Details", {
                        //     position: toast.POSITION.TOP_CENTER
                        // });
                        toast.error(message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        // console.log(err.status)
                        this._ToggleLoader(false)
                        this.isprocessing = false
                        // console.log(err)
                    }
                    else {
                        console.log("undefined")
                    }
                })
        }


    }

    @action _SignupHandler = () => {
        const {
            Reg_firstname,
            Reg_lastname,
            Reg_age,
            Reg_email,
            Reg_password,
            Reg_confirm, 
            Reg_school, 
                  
                    } = AccountStore.regInfo;
                    
                

        if (Reg_email === '') {
            toast.info("Email Address is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        else if (Reg_lastname ==='') {
            toast.info("Last name is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        else if (Reg_firstname === '') {
            toast.info("Enter Firstname", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        else if (Reg_password === '') {
            toast.info("Password is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        else if (Reg_confirm === '') {
            toast.info("Confirm Password is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }
      
      
       
        else if (Reg_password !== Reg_confirm) {
            toast.info("Password Not Match", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        else {
            let params = {
                firstname:Reg_firstname,
                lastname:Reg_lastname,
                gender:'Not Specified',
                age:Reg_age,
                email_address:Reg_email,
                Mpassword:Reg_password,
                Mconfirm_password:Reg_confirm,
                Mschool:Reg_school,
                Mcountry:'Not Specified',
                Mstate:'Not Specified',


            }
             console.log(params);
            this.isprocessing = true
            this._ToggleLoader(true)
            axios.post('/signup', qs.stringify(params))
                .then(response => {
                    this.isprocessing = false
                    this._ToggleLoader(false)
                    console.log(response)
                    // let check = response.data.hasOwnProperty("login_token");
                    //   alert(true)
                    if (response.status === 200 || response.status === 201) {
                        let resp = response.data;
                        console.log(resp)
                        let check = resp.status
                        let data = null;
                        if (check === true) {
                           // AccountStore.redirectToReferrer = true
                            this.redirectToReferrer = true
                            let message = resp.message
                            toast.success(message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                        }
                        else {
                            console.log('error');
                            let resp = response.data;
                            console.warn(resp);
                            let message = resp.message||resp.payload
                            toast.error(message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            // this.redirectmode = false
                            // this.isprocessing = false
                            // this._ToggleLoader(false)
                            // AccountStore.Verifymail.ErrorMessage = message

                        }

                    }
                    else {
                        alert("here")
                    }
                })
                .catch(err => {
                    let errorcode = err.status
                    alert(errorcode)
                    console.log(typeof (err));
                    if (typeof (err) != undefined) {
                        console.log(err.response)
                        let Server_error = err.response;
                        let message = Server_error.data.message
                        console.log(message);
                        // toast.error("Invalid Login Details", {
                        //     position: toast.POSITION.TOP_CENTER
                        // });
                        toast.error(message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        // console.log(err.status)
                        this._ToggleLoader(false)
                        this.isprocessing = false
                        // console.log(err)
                    }
                    else {
                        console.log("undefined")
                    }

                })
        }


    }
    @action _LoginHandler = () => {
        const { email, password, rememberme } = AccountStore.loginInfo;

        if (email === '') {
            toast.info("Email Address is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        else if (password === '') {
            toast.info("Password Address is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        else {
            let params = {
                email: email,
                password: password,
                // strategy: 'local',

            }
            console.log(params);
            this.disabled = true
            axios.post('/login', qs.stringify(params))
                .then(response => {
                    this.disabled = false

                    console.log(response)

                    if (response.status === 200 || response.status === 201) {
                        let resp = response.data;
                        // console.log(resp)
                        let check = resp.status
                        let data = null;
                        if (check === true) {
                            //  alert(check)
                            console.log("here")
                            data = resp.payload;
                            console.log(data);
                            this.loggedinUserdetails = data
                            let data2save = {
                                isloggedin: true,
                                userid: data.userId,
                                usertype: 'User',
                                fullname: data.fullname,
                                accessToken: 'token',
                                // username: data.username,
                               
                            }
                            console.log(data2save)
                            let stringify_data = JSON.stringify(data);
                            ls.set('Loggedinstate', data2save);
                            ls.set('loggedinUserdetails', this.loggedinUserdetails);

                            this.redirectToReferrer = true
                        }
                        else {
                            console.log('error');
                            let resp = response.data;
                            console.warn(resp);
                            let message = resp.payload
                            toast.error(message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            this.redirectmode = false
                            this._ToggleLoader(false)

                        }
                    }
                })
                .catch(err => {
                    //  console.log(typeof(err));
                    if (typeof (err) != undefined) {
                        //    console.log( err.response + 'tomzy')
                        let Server_error = err.response;
                        // let message = Server_error.data.message
                        let message = "Please Check your Network"
                        // console.log(message);
                        // toast.error("Invalid Login Details", {
                        //     position: toast.POSITION.TOP_CENTER
                        // });
                        toast.info("Empty FIle", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        // toast.info(message, {
                        //     position: toast.POSITION.TOP_CENTER
                        // });
                        // console.log(err.status)
                        this._ToggleLoader(false)
                        this.isprocessing = false
                        // console.log(err)
                    }
                    else {
                        console.log("undefined tizz")
                    }
                })
        }


    }
    @action _updateProfile = () => {
        let data = getuserdata()
        // alert(JSON.stringify(data))
        // let user_id= AccountStore.UserDate.userid;
        toast.success('Processing....', {
            position: toast.POSITION.BOTTOM_RIGHT
        });
       
        const { 
            fullname, 
            gendar, 
            company_location, 
            age_group, 
            Address,
            company_name,
            company_website, 
            company_industry, 
              Shortinfo,
               mobile_number,
               Languages,
               updateProfile_country,
               updateProfile_state,
               country_id,
               state_id,
               profile_pix,
               profile_banner,
             } = AccountStore.Updateprofile;

        console.log(AccountStore.Updateprofile)
        if (fullname === '') {
            toast.info("fullname is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        else {
            
            let state='';
            let country='';
            if (Object.keys(updateProfile_country).length === 0 && updateProfile_country.constructor === Object) {
                country=country_id;
            }
            else {
country= updateProfile_country.value;
            }

            if (Object.keys(updateProfile_state).length === 0 && updateProfile_state.constructor === Object) {
                state=state_id;
            }
            else {
                state= updateProfile_state.value;

            }

            let params = {
                user_id: data.userid,
                gender: gendar,
                age_group: age_group,
                address: Address,
                company_name: company_name,
                company_location: company_location,
                company_website: company_website,
                company_industry: company_industry,
                short_info: Shortinfo,
                fullname: fullname,
                mobile_number: mobile_number,
                country_id:country,
               state_id:state,
               languages:Languages,
               profile_pix:profile_pix,
               profile_banner

            }
            console.log(params);
            this.isprocessing = true
            this._ToggleLoader(true)
            axios.post('/updateProfile', qs.stringify(params))
                .then(response => {
                    this.isprocessing = false
                    this._ToggleLoader(false)
                    console.log(response)

                    // let check = response.data.hasOwnProperty("login_token");
                    //   alert(true)
                    if (response.status === 200 || response.status === 201) {
                        let resp = response.data;
                        console.log(resp)
                        let check = resp.status
                        let data = null;


                        if (check === true) {
                            //  alert(check)
                            let message = resp.message
                            console.log("here")
                            data = resp.data;
                            console.log(data)
                            let ReturnedString = ls.get('Loggedinstate');
                            ReturnedString.profile = data;

                            ls.set('loggedinUserdetails', ReturnedString);
                            toast.success(message, {
                                position: toast.POSITION.TOP_CENTER
                            });

setTimeout(() => {
    window.location.reload();
}, 2000);

                         

                        }
                        else {
                            toast.error("An Error occured \n please try again", {
                                position: toast.POSITION.TOP_CENTER
                            });
                        }

                    }
                    else {
                        alert("here")
                    }
                })
                .catch(err => {
                    let errorcode = err.status
                    alert(errorcode)
                    console.log(typeof (err));
                    if (typeof (err) != undefined) {
                        console.log(err.response)
                        let Server_error = err.response;
                        let message = Server_error.data.message
                        console.log(message);
                        // toast.error("Invalid Login Details", {
                        //     position: toast.POSITION.TOP_CENTER
                        // });
                        toast.error(message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        // console.log(err.status)
                        this._ToggleLoader(false)
                        this.isprocessing = false
                        // console.log(err)
                    }
                    else {
                        console.log("undefined")
                    }

                })
        }


    }

    @action _update_userdata = (data, field, url) => {
        let user_data = getuserdata()
        let user_id = user_data.userid;
        console.log(data)
        data['user_id'] = user_id;
        console.table(data);
        if (user_id === '') {
            toast.info("user id is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        else {
            let params = data
            console.log(params);
            this.isprocessing = true
            this._ToggleLoader(true)

            axios.post('/updateProfile', qs.stringify(params))
                .then(response => {
                    this.isprocessing = false
                    this._ToggleLoader(false)
                    console.log(response)

                    // let check = response.data.hasOwnProperty("login_token");
                    //   alert(true)
                    if (response.status === 200 || response.status === 201) {
                        let resp = response.data;
                        console.log(resp)
                        let check = resp.status
                        let data = null;


                        if (check === true) {
                            //  alert(check)
                            let message = resp.message
                            console.log("here")
                            data = resp.data;
                            console.log(data)
                            let ReturnedString = ls.get('Loggedinstate');
                            // ReturnedString.profile['profile_pix']=url;
                            ReturnedString.profile = data;

                            ls.set('loggedinUserdetails', ReturnedString);
                            toast.success(message, {
                                position: toast.POSITION.TOP_CENTER
                            });



                            // window.location.reload();

                        }
                        else {
                            toast.error("An Error occured \n please try again", {
                                position: toast.POSITION.TOP_CENTER
                            });
                        }

                    }
                    else {
                        alert("here")
                    }
                })
                .catch(err => {
                    console.log(err);
                    let errorcode = err.status
                    alert(errorcode)
                    console.log(typeof (err));
                    if (typeof (err) != undefined) {
                        // console.log(err.response)
                        // let Server_error=err.response;
                        // let message=Server_error.data.message
                        // console.log(message);
                        // toast.error("Invalid Login Details", {
                        //     position: toast.POSITION.TOP_CENTER
                        // });
                        // toast.error(message, {
                        //     position: toast.POSITION.TOP_CENTER
                        // });
                        // console.log(err.status)
                        this._ToggleLoader(false)
                        this.isprocessing = false
                        // console.log(err)
                    }
                    else {
                        console.log("undefined")
                        this._ToggleLoader(false)
                        this.isprocessing = false
                    }

                })
        }


    }

    @action GetCountries = () => {

        axios.get(`getcountries`)
            .then(response => {
                //   console.log(response)
                //    let resp=response.data;
                //                // console.warn(resp);

                if (response.status === 200 || response.status === 201) {
                    let resp = response.data;
                    // console.log(resp)
                    let check = resp.status
                    let data = null;
                    if (check === true) {
                        data = resp.data;
                        const formattedMessagedata = data.map(singleData => {

                            return {
                                value: singleData.id,
                                label: singleData.name
                            };
                        });
                        console.log(formattedMessagedata);
                        this.Allcountries = formattedMessagedata
                    }
                    else {
                        console.log('error');
                        let resp = response.data;
                        console.warn(resp);
                        let message = resp.message || 'Empty'
                        toast.error(message, {
                            position: toast.POSITION.TOP_CENTER
                        });


                    }

                }
                else {
                    this.Allcountries = [];

                }

            })
            .catch(error => {
                console.log(`error ${error}`);
                this.Allcountries = [];

            });
    }
    @action GetStates_Country = (countryId) => {

        // this._ToggleLoader(true)
        //      console.warn(caseid);
        axios.get(`getstates/${countryId}`)
            .then(response => {

                if (response.status === 200 || response.status === 201) {
                    let resp = response.data;
                    // console.log(resp)
                    let check = resp.status
                    let data = null;
                    if (check === true) {
                        data = resp.data;
                        const formattedMessagedata = data.map(singleData => {

                            return {
                                value: singleData.id,
                                label: singleData.name
                            };
                        });
                        console.log(formattedMessagedata);
                        this.Allstates = formattedMessagedata
                    }
                    else {
                        console.log('error');
                        let resp = response.data;
                        console.warn(resp);
                        let message = resp.message || 'Empty'
                        toast.error(message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        this.Allstates = []

                    }


                    //       this._ToggleLoader(false)
                }
                else {
                    console.log(response)
                    //    console.warn('error');
                    //    let resp=response.data;
                    //    console.warn(resp);
                    //    let message =resp.Message
                    //    Toast.show(message, Toast.SHORT, Toast.BOTTOM,styletoest);
                    this.Allstates = []
                    //   this._ToggleLoader(false)
                }
            })
            .catch(error => {
                console.log(`error ${error}`);
                this.Allstates = [];

            });
    }
}

const AccountStore = new Account()
export default AccountStore
