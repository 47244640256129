import React, {
    Component
} from 'react';
// import { MDBDataTable, MDBContainer, MDBIcon, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';


import {
    observable,
    action
} from 'mobx';
import qs from 'qs';
import axios from 'axios';
import ls from 'local-storage';
// import jsonpAdapter from 'axios-jsonp'

// import fs from 'fs'
// const fs = require('fs')
// import queryString from 'query-string'
// import ls from 'local-storage';
import {
    ToastContainer,
    toast
} from 'react-toastify';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

// import jwt_decode from 'jwt-decode';
// import SimpleReactValidator from 'simple-react-validator';
// import { arrayToObject } from '../dependency/UtilityFunctions';
import 'react-toastify/dist/ReactToastify.css';
import { getloginFullDetails } from '../auth';

const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  }

let OptionBank = []
class Account {


  
//   @observable nextStep = null;
//   @observable prevStep = null; abayomi pear
@observable ready = false;
    @observable TimerInnitailTime = 0;
    @observable OptionBanks_obs = [];
    @observable Question2Answer = [];
    @observable TotalQuestions2Answer = 0;
    @observable currentIndex = 0;
    @observable currentQuestion = '';
    @observable questionhasImage = '';
    @observable currentQuestionImage = [];
    @observable currentOptions = [];
    @observable currentSelectedOption = '';

    @observable navMonitor = 'home';
    @observable CrriMonitor = '';
    @observable navMonitor2 = 'Exam';



    @observable AllCurriculum = [];
    @observable UserGrade = null;
    @observable UserTopic = null;
    @observable UserSubject = null;
    @observable CurriculumClass = [];
    @observable QuestionCount = [];

    @observable AllSubject = [];
    @observable AllTopic = [];
    @observable AllSession = [];
    @observable AllScore = [];
    @observable Allcorrection = [];
    @observable genSessionId = null;
    @observable Question = [];
    @observable SetQuestion = [];
    @observable SetQuestonOpt = [];
    @observable FormSteps =1;
    @observable topicsparams = {
        curriculum_idx: '',
        class_idx: '',
    }
    @observable subjectTopicparams = {
        subject_idx: null,
        topic_idx: [],
        textSize: ''
    }
    @observable JumpQuestionParam = {
        questionIndex: null,

    }

    @action updateNavar(status) {
        this.navMonitor = status;
        // alert(status)
    }
    @action updateCurriculum(status) {
        this.CrriMonitor = status;
        // alert(status)
    }


    nextStep  () {
        // const { step } = this.state
        // this.setState({
        //     step : step + 1
        // })
        let NextStep=ExamStore.FormSteps+1;
        ExamStore.FormSteps=NextStep
    }
    prevStep = () => {
    //   const { step } = this.state
    //   this.setState({
    //       step : step - 1
    //   })
    let PreviousStep=ExamStore.FormSteps-1;
    ExamStore.FormSteps=PreviousStep
  }

    PrepareOptionCell = () => {

        let QuestionTotal = ExamStore.TotalQuestions2Answer

        for (let i = 0; i < QuestionTotal; ++i) {
            let qstGen_Idx = ExamStore.Question2Answer[i].qstGen_idx;
            let Question_Score = ExamStore.Question2Answer[i].score; 
            let unique_Q_id = i;
            let user_id = null;
            let userAnswerIdx = '';
            let options_object = {score:Question_Score, unique_Q_id, user_id, qstGen_Idx, userAnswerIdx }
            OptionBank[i] = options_object;
        }
        console.log(OptionBank)
        console.log(QuestionTotal)
        console.log('OptionBank')
        ExamStore.OptionBanks_obs = OptionBank;
    }
    @action handleOptionChange(changeEvent) {
        console.log(changeEvent.target.value);
        // this.setState({
        //   selectedOption: changeEvent.target.value
        // });
        let MyOption = changeEvent.target.value;
        ExamStore.currentSelectedOption = MyOption;
        // alert(changeEvent.target.value)
    }
    @action SaveSelectedOPtion(Currentindex) {
        // let UserOption=ExamStore.currentSelectedOption;
        // OptionBank[Currentindex]=UserOption;
        // ExamStore.currentSelectedOption =''
        // console.log(OptionBank)
        /////////////////////////////
        let UserOption = ExamStore.currentSelectedOption;
        // OptionBank[Currentindex]=UserOption;
        let index = ExamStore.OptionBanks_obs.findIndex(x => x.unique_Q_id === Currentindex);
        // alert(index)

        ExamStore.OptionBanks_obs[index].userAnswerIdx = UserOption;
        ExamStore.currentSelectedOption = ''
        console.log(ExamStore.OptionBanks_obs);
    }
    @action GetPreviousSelectedOption(question_index) {
        // let previousSelectedOption=OptionBank[index];
        //  alert(previousSelectedOption)
        let index = ExamStore.OptionBanks_obs.findIndex(x => x.unique_Q_id === question_index);
        // alert(index)

        let previousSelectedOption = ExamStore.OptionBanks_obs[index].userAnswerIdx;


        ExamStore.currentSelectedOption = previousSelectedOption
    }
    @action CheckIfQuestionIsAnswered(question_index) {

        let index = ExamStore.OptionBanks_obs.findIndex(x => x.unique_Q_id === question_index);
        let UserOption = ExamStore.OptionBanks_obs[index].userAnswerIdx;
        if (UserOption !== '') {
            return true;
        }
        else {
            return false;
        }
    }




    @action SaveQuestion2local(myquestions) {

        ls.set('Question_to_answer', myquestions);
        console.log("done")
    }
    @action Save2local(key,data) {

        ls.set(key, data);
        console.log("Save2local")
    }
    @action GetMyquestionFromLocal() {
        let Timeallow = ls.get('Timeallow');
        //alert(Timeallow)
       ExamStore.TimerInnitailTime=Timeallow;
        let myQuestion = ls.get('Question_to_answer');
        console.log("done")
        console.log(myQuestion)
        this.Question2Answer = myQuestion;
        ExamStore.TotalQuestions2Answer = myQuestion.length;
        ExamStore.ready = true;
         
    }
    @action DisplayQuestion(index) {
        ExamStore.currentQuestion = ExamStore.Question2Answer[index].question;
        ExamStore.questionhasImage = ExamStore.Question2Answer[index].hasImage;
        ExamStore.currentQuestionImage = ExamStore.Question2Answer[index].qstMadia;
        ExamStore.currentOptions = ExamStore.Question2Answer[index].qstOpt;
    }
    @action NextQuestion() {

        let Currentindex = ExamStore.currentIndex
        let TotalQues = ExamStore.TotalQuestions2Answer;
        let v = Currentindex + 1;
        //alert(v)
        // let UserOption=ExamStore.currentSelectedOption;
        // OptionBank[Currentindex]=UserOption;

        ExamStore.SaveSelectedOPtion(Currentindex);
        ExamStore.GetPreviousSelectedOption(v);
        if (v < TotalQues) {
            ExamStore.currentIndex = ExamStore.currentIndex += 1;
            ExamStore.DisplayQuestion(ExamStore.currentIndex)

        }

    }
    @action PreviousQuestion() {
        let Currentindex = ExamStore.currentIndex
        //    alert(Currentindex)
        // let previousSelectedOption=OptionBank[Currentindex];
        //  alert(previousSelectedOption)
        //  ExamStore.currentSelectedOption =previousSelectedOption
        let TotalQues = ExamStore.TotalQuestions2Answer;
        let v = Currentindex - 1;

        console.log(v + 'minus')
        console.log(Currentindex + 'current')

        //alert(Currentindex)
        //alert(OptionBank[v])
        ExamStore.SaveSelectedOPtion(Currentindex);
        ExamStore.GetPreviousSelectedOption(v);
        if (v >= 0) {
            ExamStore.currentIndex = ExamStore.currentIndex -= 1;
            ExamStore.DisplayQuestion(ExamStore.currentIndex)
        }

    }
    @action JumpToQuestion() {
        const { questionIndex } = ExamStore.JumpQuestionParam
        let Currentindex = ExamStore.currentIndex

        let TotalQues = ExamStore.TotalQuestions2Answer;
        // alert(TotalQues)
        let Question2JumpTo = questionIndex;
        //alert(Question2JumpTo)

        Question2JumpTo = parseInt(Question2JumpTo);
        if (Question2JumpTo <= TotalQues) {
            let v = Question2JumpTo - 1;
            ExamStore.SaveSelectedOPtion(Currentindex);
            ExamStore.GetPreviousSelectedOption(v);

            ExamStore.currentIndex = v;
            ExamStore.DisplayQuestion(ExamStore.currentIndex)

        }
        else {
            alert("You can not Jump to this number")
        }


    }

    @action handleInputChange(e, type) {
        const re = /^[0-9\b]+$/;
        const value = e.target[e.target.type === "checkbox" ? "checked" : "value"]
        const name = e.target.name;
        console.log(name)
        console.log(type)
        if (name == 'questionIndex') {
            // alert("p")
            if (e.target.value === '' || re.test(e.target.value)) {
                ExamStore[type][name] = value;
            }

        }
        else {
            ExamStore[type][name] = value;
        }

    }








    // @action _handleChangeSelect = (stateOption) => {
    //     let value = stateOption.value;

    // }
    @action saveSessionId = (params) => {
        // const formattedMessagedata = params;
        // console.log(formattedMessagedata + 'awsssss')
        this.genSessionId = params
    }
    

    @action GetCurriculum = () => {

        console.warn("curriculum1");
        // axios.get('https://api2.edves.net/edvesacademy/v1/fetchCurriculum', {
        //     headers: {
        //       'Access-Control-Allow-Origin': '*',
        //     },

        //     })
        // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.get('fetchCurriculum', config)
            .then(response => {
                // console.warn("curriculum");

                if (response.status === 200) {
                    // console.log(response)
                    let resp = response.data;
                    console.log(resp)

                    let Payload = null;
                    if (resp.status === true) {
                        Payload = resp.payload;
                        // const formattedMessagedata = Payload.map(singleData => {
                        //     // { key: 'angular', text: 'Angular', value: 'angular' },  

                        //     let myData = singleData.curriculum + '/' + singleData.idx;
                        //     // alert(myData); 
                        //     return {
                        //         key: singleData.curriculum,
                        //         value: myData,
                        //         text: singleData.curriculum
                        //     };
                        // });
                        // const formattedMessagedata = Payload.map((item) => {
                        //     // itemkeys += 1;
                        //     alert(item.idx)
                        //      return (
                        //     <ToggleButton value={item.idx}>{item.curriculum}  </ToggleButton>
                         
                        //     );
                           
                        //   });
                        // console.log(formattedMessagedata[0] + 'oluwalonimi');
                        this.AllCurriculum = Payload
                    } else {
                        toast.info("Empty FIle", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        this.AllCurriculum = []
                    }

                    //       this._ToggleLoader(false)
                } else {
                    console.log(response)
                    //    console.warn('error');
                    //    let resp=response.data;
                    //    console.warn(resp);
                    //    let message =resp.Message
                    //    Toast.show(message, Toast.SHORT, Toast.BOTTOM,styletoest);
                    this.AllCurriculum = []
                    //   this._ToggleLoader(false)
                }
            })
            .catch(error => {
                toast.info("Error", {
                    position: toast.POSITION.TOP_CENTER
                });
                this.AllCurriculum = [];

            });
    }

    @action GetCurriculumClass = (curriculumId) => {
        this.topicsparams['curriculum_idx'] = curriculumId;
        // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.get(`https://cors-anywhere.herokuapp.com/https://api2.edves.net/edvesacademy/v1/fetchclass/${curriculumId}`, config)

            .then(response => {
                if (response.status === 200) {
                    // console.log(response)
                    let resp = response.data;
                    console.log(resp)
                    let Payload = null;
                    if (resp.status === true) {
                        Payload = resp.payload;
                        // const formattedMessagedata = Payload.map(singleData => {
                        //     let myData = singleData.class_name + '/' + singleData.idx;
                        //     return {
                        //         value: myData,
                        //         text: singleData.class_name,
                        //         key: singleData.curriculum_Idx
                        //     };
                        // });
                        // console.log(formattedMessagedata);
                        this.CurriculumClass = Payload
                    } else {
                        toast.info("Empty FIle", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        this.CurriculumClass = []
                    }

                    //       this._ToggleLoader(false)
                } else {
                    console.log(response)
                    //    console.warn('error');
                    //    let resp=response.data;
                    //    console.warn(resp);
                    //    let message =resp.Message
                    //    Toast.show(message, Toast.SHORT, Toast.BOTTOM,styletoest);
                    this.CurriculumClass = []
                    //   this._ToggleLoader(false)
                }
            })
            .catch(error => {
                toast.info("Error", {
                    position: toast.POSITION.TOP_CENTER
                });
                this.CurriculumClass = [];

            });
    }

    @action GetCurriculumSubject = (classid) => {
        ExamStore.topicsparams['class_idx'] = classid;
        const {
            curriculum_idx,
            class_idx
        } = ExamStore.topicsparams;
        // alert(curriculum_idx + " "+ class_idx)
        console.warn("curriculumClass1");
        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.get(`https://cors-anywhere.herokuapp.com/https://api2.edves.net/edvesacademy/v1/fetchSubject/${curriculum_idx}/${class_idx}`, config)

            .then(response => {
                if (response.status === 200) {
                    // console.log(response)
                    let resp = response.data;
                    console.log(resp)
                    let Payload = null;
                    if (resp.status === true) {
                        Payload = resp.payload;
                        // const formattedMessagedata = Payload.map(singleData => {
                        //     let myData = singleData.subject + '/' + singleData.idx;
                        //     // alert(myData);
                        //     return {
                        //         value: myData,
                        //         text: singleData.subject,
                        //         key: singleData.curriculum_Idx
                        //     };
                        // });
                        // console.log(formattedMessagedata);
                        this.AllSubject = Payload
                    } else {
                        toast.info("Empty FIle", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        this.AllSubject = []
                    }

                    //       this._ToggleLoader(false)
                } else {
                    console.log(response)
                    //    console.warn('error');
                    //    let resp=response.data;
                    //    console.warn(resp);
                    //    let message =resp.Message
                    //    Toast.show(message, Toast.SHORT, Toast.BOTTOM,styletoest);
                    this.AllSubject = []
                    //   this._ToggleLoader(false)
                }
            })
            .catch(error => {
                toast.info("Error", {
                    position: toast.POSITION.TOP_CENTER
                });
                this.CurriculumClass = [];

            });
    }

    @action processTopic = (topicIdx) => {
        // console.table(topicIdx)
        ExamStore.subjectTopicparams['topic_idx'] = topicIdx;
        // ExamStore.subjectTopicparams['topic_idx'].push(topicIdx); 
    }
    @action processTopicAmount = (topicParam) => {
        ExamStore.subjectTopicparams['textSize'] = topicParam;
    }

    @action GetSubjectTopic = (subjectIdx) => {
        ExamStore.subjectTopicparams['subject_idx'] = parseInt(subjectIdx);
        // ExamStore.subjectTopicparams['subject_idx'].push(subjectIdx); 
        // console.log(ExamStore.subjectTopicparams['subject_idx'] + 'lovvvvvvvv');
        const {
            curriculum_idx,
            class_idx
        } = ExamStore.topicsparams;
        console.log(curriculum_idx + ' ' + class_idx + ' ' + subjectIdx);
        axios.get(`https://cors-anywhere.herokuapp.com/https://api2.edves.net/edvesacademy/v1/fetchTopic/${curriculum_idx}/${class_idx}/${subjectIdx}`, config)

            .then(response => {
                if (response.status === 200) {
                    // console.log(response)
                    let resp = response.data;
                    console.log(resp)
                    let Payload = null;
                    if (resp.status === true) {
                        Payload = resp.payload;
                        // const formattedMessagedata = Payload.map(singleData => {
                        //     //idx	subject	curriculum_idx	date_created	date_modified	status 
                        //     let myData = singleData.topic + '/' + singleData.idx;
                        //     return {
                        //         value: myData,
                        //         text: singleData.topic,
                        //         key: singleData.curriculum_Idx
                        //     };
                        // });
                        // console.log(formattedMessagedata);
                        this.AllTopic = Payload
                    } else {
                        toast.info("Empty FIle", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        this.AllTopic = []
                    }

                    //       this._ToggleLoader(false)
                } else {
                    console.log(response)
                    //    console.warn('error');
                    //    let resp=response.data;
                    //    console.warn(resp);
                    //    let message =resp.Message
                    //    Toast.show(message, Toast.SHORT, Toast.BOTTOM,styletoest);
                    this.AllTopic = []
                    //   this._ToggleLoader(false)
                }
            })
            .catch(error => {
                toast.info("Error", {
                    position: toast.POSITION.TOP_CENTER
                });
                // console.log(`error ${error}`);  ExamCorrection
                this.AllTopic = [];

            });
    }
    @action GetQuestion = () => {
        const {
            curriculum_idx,
            class_idx
        } = ExamStore.topicsparams;
        const {
            subject_idx,
            topic_idx,
            textSize
        } = ExamStore.subjectTopicparams;
        let stringsubject = JSON.stringify(subject_idx);
        let stringtopic_id = JSON.stringify(topic_idx);
        console.log(stringsubject + 'yyyyyy')
        let params = {
            "class_idx": class_idx,
            "curriculum_idx": curriculum_idx,
            "subject_idx": stringsubject,
            "textSize": textSize,
            "topic_idx": stringtopic_id,
        }
        console.table(params);
        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.post(`https://cors-anywhere.herokuapp.com/https://api2.edves.net/edvesacademy/v1/getQuestion`, qs.stringify(params))

            .then(response => {
                if (response.status === 200) {
                    let resp = response.data;
                     console.log(resp)
                     console.log(resp)
                    // let Questions = [];
                    // let Timeallow = 0;
                    if (resp.status === true) {
                        // alert(resp.payload);
                       let  Questions = resp.payload.Questions;
                       let  Timeallow = resp.payload.Timeallow;
                        // let Questionarray = [];
                        // let QuestionOptionarray = [];
                        this.SetQuestion = Questions;
                        //this.SetQuestion =Timeallow;
                        this.SaveQuestion2local(this.SetQuestion);
                        this.Save2local('Timeallow',Timeallow);
                        // alert(Timeallow)
                        ExamStore.nextStep();
                        // let examTIme = null;
                        // Payload2 = resp.payload.Timeallow;
                        // alert(Payload2);

                        // this.TimerInnitailTime = Payload2;
                        // let UserSessionId = ExamStore.TimerInnitailTime
                       // alert(UserSessionId);


                        // this. = Questionarray
                        // this.SetQuestionOpt = QuestionOptionarray

                        // this.QuestionCount = formattedMessagedata
                    } else {
                        toast.info("Empty FIle", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        this.QuestionCount = []
                    }

                    //       this._ToggleLoader(false)
                } else {
                    // console.log(response)
                    //    console.warn('error');
                    //    let resp=response.data;
                    //    console.warn(resp);
                    //    let message =resp.Message
                    //    Toast.show(message, Toast.SHORT, Toast.BOTTOM,styletoest);
                    this.QuestionCount = []
                    //   this._ToggleLoader(false)
                }
            })
            .catch(error => {
                console.log(error.response)
                // console.log(error)
                // console.log(`error ${error}`);
                // this.Question = [];
                // console.log('dis error');

            });
    }

    @action SaveAnswer = () => {
let userdata=getloginFullDetails();
console.log(userdata);
let currentuser=null;
if(userdata.isloggedin==true){
    currentuser=userdata.userid;
}
        let Currentindex = ExamStore.currentIndex


        ExamStore.SaveSelectedOPtion(Currentindex);
      let user_id = currentuser;
        let AllAnswer = ExamStore.OptionBanks_obs;
        // let params ={  "userChoice": JSON.stringify([
        //     {"qstGen_Idx":"MMWOBGCL","userAnswerIdx":"9","score":"10"},{"qstGen_Idx":"GITPPR0S","userAnswerIdx":"4","score":"10"}
        // ]),
        // "user_id":value
        // }
        let userChoice = JSON.stringify(AllAnswer);
        let params = {
            userChoice,
            user_id
        }

        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.post(`https://cors-anywhere.herokuapp.com/https://api2.edves.net/edvesacademy/v1/saveAnsweredQuestion`, qs.stringify(params))

            .then(response => {
                if (response.status === 201) {
                    let resp = response.data;
                    // console.log(resp)
                    let Payload = null;
                    if (resp.status === true) {
                        Payload = resp.payload;
                        // console.log("Payload");
                        console.log(Payload.session_id);
                        // percentage_score: Payload.percentage_score,
                        const formattedMessagedata = Payload.percentage_score;
                        const formattedMessagedata2 = Payload.session_id;
                        this.AllScore = formattedMessagedata;
                        this.AllSession = formattedMessagedata2;
                        ExamStore.nextStep();
                    } else {
                        toast.info("Empty FIle", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        this.AllScore = []
                        this.AllSession = []
                    }

                    //       this._ToggleLoader(false)
                } else {
                    console.log(response)
                    //    console.warn('error');
                    //    let resp=response.data;
                    //    console.warn(resp);
                    //    let message =resp.Message
                    //    Toast.show(message, Toast.SHORT, Toast.BOTTOM,styletoest);
                    this.AllScore = []
                    this.AllSession = []
                    //   this._ToggleLoader(false)
                }
            })
            .catch(error => {
                console.log(error.response)
                console.log(error)
                // console.log(`error ${error}`);
                this.Question = [];
                this.AllSession = []
                // console.log('dis error');

            });
    }
    @action ExamCorrection = () => {

        let UserSessionId = ExamStore.genSessionId
        // let stringsubject = JSON.stringify(subject_idx);
        // let stringtopic_id = JSON.stringify(topic_idx);
        // console.log(UserSessionId + 'yyyyyy')
        let params = {
            "session_id": UserSessionId,
        }
        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.post(`https://cors-anywhere.herokuapp.com/https://api2.edves.net/edvesacademy/v1/correction`, qs.stringify(params))

            .then(response => {
                if (response.status === 200) {
                    let resp = response.data;
                    // console.log(resp)
                    let Payload = null;
                    if (resp.status === true) {
                        Payload = resp.payload;
                        const Payloaddata = Payload
                        // const formattedMessagedata = Payload.map(singleData => {
                        //     return {

                        //     };
                        // });
                        this.Allcorrection = Payloaddata
                    } else {
                        toast.info("Empty FIle", {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }

                    //       this._ToggleLoader(false)
                } else {
                    // console.log(response)
                    //    console.warn('error');
                    //    let resp=response.data;
                    //    console.warn(resp);
                    this.Allcorrection = [];
                    //    let message =resp.Message
                    //    Toast.show(message, Toast.SHORT, Toast.BOTTOM,styletoest);
                    //   this._ToggleLoader(false)
                }
            })
            .catch(error => {
                console.log(error.response)
                // console.log(error)
                // console.log(`error ${error}`);
                this.Allcorrection = [];
                // console.log('dis error');

            });
    }


    // @action GetQuestion = () => { 
    //     alert('am here');
    //    const{curriculum_idx, class_idx}=ExamStore.topicsparams;
    //    const{subject_idx, topic_idx, textSize}=ExamStore.subjectTopicparams;
    //    console.log(class_idx + "/" + curriculum_idx + "/" + subject_idx + "/" + topic_idx + "/" + textSize  )
    //    let stringsubject=JSON.stringify(subject_idx);
    //    let stringtopic_id=JSON.stringify(topic_idx);
    //    let params = {
    //     "moblie": '08090435673'
    // }
    //    // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    //     axios.post(`https://api2.edves.net/attendance/v1/authenticate`,{params})

    //     .then(response => {
    //         alert('am here2');
    //         if (response.status === 200) {
    //             // console.log(response) 
    //             let resp = response.data;
    //             console.log(resp)
    //             let Payload=null;
    //             if(resp.status===true){
    //                 Payload=resp.payload;
    //                 const formattedMessagedata = Payload.map(singleData => {
    //                     // console.log(singleData);
    //                             //idx	subject	curriculum_idx	date_created	date_modified	status 
    //                     return {  
    //                     value: singleData.idx, 
    //                     // text: singleData.topic,
    //                     // key: singleData.curriculum_Idx
    //                     };
    //                 });
    //                 // console.log(formattedMessagedata);
    //                 this.Question = formattedMessagedata
    //             }
    //             else{
    //                 toast.info("Empty FIle", {
    //                     position: toast.POSITION.TOP_CENTER
    //                 });
    //                 this.Question = []
    //             }

    //             //       this._ToggleLoader(false)
    //         }
    //         else {
    //             console.log(response)
    //             //    console.warn('error');
    //             //    let resp=response.data;
    //             //    console.warn(resp);
    //             //    let message =resp.Message
    //             //    Toast.show(message, Toast.SHORT, Toast.BOTTOM,styletoest);
    //             this.Question = []
    //             //   this._ToggleLoader(false)
    //         }
    //     })
    //     .catch(error => {
    //         console.log(error)
    //         console.log(`error ${error}`);
    //         this.Question = [];
    //         console.log('dis error');

    //     });
    // }

    @action _handleChangeSelect = (Option) => {
        let optionValue = Option;
        ExamStore.GetCurriculumClass(Option);

        // let expiryDate = stateOption.value.expiryDate; 
        // let cvv = stateOption.value.cvv;
        // let panNumber = stateOption.value.panNumber;
        // let reference = stateOption.value.reference;
        // let card_id = stateOption.value.id;
        // this[type]['expiryDate'] = optionValue;  _handleChangeSelecttopicAmount

    }
    @action _handleChangeSelectClass = (Option) => {
        // let optionValue = Option.value;
        ExamStore.GetCurriculumSubject(Option);
    }
    @action _handleChangeSelectSubject = (Option) => {
        // let optionValue = Option.value;
        ExamStore.GetSubjectTopic(Option);
    }
    @action _handleChangeSelecttopic = (Option) => {
        // let optionValue = Option.value; 
        // console.table(Option)
        ExamStore.processTopic(Option);
    }
    @action _handleChangeSelecttopicAmount = (Option) => {
        // alert(Option)
        // let optionValue = Option.value;
        ExamStore.processTopicAmount(Option);
    }
    @action _handleChangeStartExam = () => {
        // let optionValue = Option.value;
        ExamStore.GetQuestion();
    }
    @action _handleGetAllExamCorrection = () => {
        // let optionValue = Option.value;
        ExamStore.ExamCorrection();
    }
    @action _handleChangeSaveExam = () => {
        // let optionValue = Option.value; addGrade
        ExamStore.SaveAnswer();
    }
    // 
    @action globaGrade = (params) => {
        // let optionValue = Option.value; addGrade
        this.UserGrade = params
    }
    @action globaTopic = (params) => {
        // let optionValue = Option.value; addGrade
        this.UserTopic = params
    }
    @action globaSubject = (params) => {
        // let optionValue = Option.value; addGrade
        this.UserSubject = params
    }
    // 
}

const ExamStore = new Account()
export default ExamStore