import React, { Component } from 'react'
import { Dropdown , Button, Image, Modal, Divider, Form, Icon } from 'semantic-ui-react'
import { observer } from 'mobx-react';
import ExamStore from '../../stores/exam';
import AccountStore from '../../stores/Account'
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {isSignedIn,getUsertype,getMyRole,hasChangePwd} from '../../auth';
import SingImg from '../assets/img/sign.png'
import Arrow from '../assets/img/minAssets/arrow.png'
import { Link, BrowserRouter, Route } from 'react-router-dom'
import styles from './prepareExam.css';

const sizes = ['huge']
const Tinsizes = ['tiny']
const options = [
  { key: 'angular', text: 'Angular', value: 'angular' },
  { key: 'css', text: 'CSS', value: 'css' },
  { key: 'design', text: 'Graphic Design', value: 'design' },
  { key: 'ember', text: 'Ember', value: 'ember' },
  { key: 'html', text: 'HTML', value: 'html' },
  { key: 'ia', text: 'Information Architecture', value: 'ia' },
  { key: 'javascript', text: 'Javascript', value: 'javascript' },
  { key: 'mech', text: 'Mechanical Engineering', value: 'mech' },
  { key: 'meteor', text: 'Meteor', value: 'meteor' },
  { key: 'node', text: 'NodeJS', value: 'node' },
  { key: 'plumbing', text: 'Plumbing', value: 'plumbing' },
  { key: 'python', text: 'Python', value: 'python' },
  { key: 'rails', text: 'Rails', value: 'rails' },
  { key: 'react', text: 'React', value: 'react' },
  { key: 'repair', text: 'Kitchen Repair', value: 'repair' },
  { key: 'ruby', text: 'Ruby', value: 'ruby' },
  { key: 'ui', text: 'UI Design', value: 'ui' },
  { key: 'ux', text: 'User Experience', value: 'ux' },
]
@observer
class StepOne extends Component{

  constructor(props) {
    super(props);
  this.state = { 
    activeItem: 'How it worksss',
     open: false,
      showModal: false,
     }
     this.passCuricullum = this.passCuricullum.bind(this);
     this.passClass = this.passClass.bind(this);
     }
    
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })
  openSUModal = () => this.setState({ showModal: true })
  openSUModal2 = () => {
    this.setState({ open: false });
    this.setState({ showModal: true });
  };
  openLOgModal2 = () => {
    this.setState({ showModal: false });
    this.setState({ open: true });
  };
  closeSUModal = () => this.setState({ showModal: false })
  
  saveAndContinue = (e) => {
    e.preventDefault()
    this.props.nextStep()
}
  passCuricullum(option){
    this.props.handleChangeSelect(option)
}
passClass(data){
    this.props.handleChangeSelect2(data)
}
componentDidMount() {
let loginstate=isSignedIn();
console.log(loginstate)
// if(loginstate==){

// }
  this.setState({ 
    open: loginstate ===true ? false:true 
  });
  // alert( ExamStore.CrriMonitor)
  if (ExamStore.CrriMonitor != ""){
    this.passCuricullum( ExamStore.CrriMonitor);
  }
  ExamStore.GetCurriculum();
  // 
  console.log()
 // DashboardStore.GetRoles_Bankuser();
 // DashboardStore.getCoperateUsers();
}
// console.log();
  render(){ 
    const { from } = this.props.location.state || { from: { pathname: '/exam' } }
        const { redirectToReferrer } = this.state
        const { history } = this.props;
        if (AccountStore.redirectToReferrer === true) {
            history.push(from);
            window.location.reload();
            //  return <Redirect to={from} />
        }
        else {
            console.log("am false")
        }

        let  Question_Curriculum=null;
if(Array.isArray(ExamStore.AllCurriculum)){
  let itemkeys = -1;
    Question_Curriculum = ExamStore.AllCurriculum.map((item) => {
      itemkeys += 1;
      let myData = item.curriculum + '/' + item.idx;
      let Curi = item.curriculum ;
      let CuriName = Curi.split(" ");
      // alert(CuriName[0]);
      // alert(myData)
       return (
      <ToggleButton 
      className="Rectangle-118 bluecolor sixteenFontSize mgRgh mgBtm1 centerFlex alignFlex "
    key={itemkeys.toString()} 
    value={myData} 
    onChange={()=>this.passCuricullum(myData)}
    >
      {CuriName[0]} 
      
    </ToggleButton>
   
      );
     
    });
}
else{
  Question_Curriculum='No Option Available';
}
        let Question_Class=null;
if(Array.isArray(ExamStore.CurriculumClass)){
  let itemkeys2 = -1;
  Question_Class = ExamStore.CurriculumClass.map((Classitem) => {
      itemkeys2 += 1;
      let myData2 = Classitem.class_name + '/' + Classitem.idx;

       return (
      <ToggleButton 
      className="Rectangle-118 bluecolor sixteenFontSize mgRgh mgBtm1 centerFlex alignFlex "
    key={itemkeys2.toString()} 
    value={myData2} 
    onChange={()=>this.passClass(myData2)}
    >
      {Classitem.class_name} 
      
    </ToggleButton>
   
      );
     
    });
}
else{
  Question_Class='No Option Available';
}

    const { open } = this.state
    const { showModal } = this.state
    return ( 
    <div className="centerFlex allWrap">
    <div style={styles.DivWrapper2} className="centerFlex pad0 alignFlex blueLayer pad4">
      <div className="DivWrapper2Wrap2 centerFlex alignCenterFlex fullHeight" style={styles.DivWrapper2Wrap}>
      <div className="centerSty1 flexDisplayColume alignCenterFlex">
        <div className="font500 fullWidth mgBtm3 twentyFontSize centerFlex">These are the 3 steps you need to take to start your test</div>
        <div className="fullWidth centerFlex justifyFlexSpaceBtw">
          <div className="flexDisplayColume alignCenterFlex Rectangle-113 Active113 justifyFlex">
                <span className="thirtyFontSize font500 lHg0">01</span>
                <span className="fourteenFontSize lHg2 txtmedia1">Curriculum & Class</span>
                {/* <span className="nineFontSize lHg1">
                This is going to help you choose the education system you want to follow
                </span> */}
          </div>
          <div className="centerFlex alignFlex"> <Icon name='play' className="NotActiveStep" /></div>
          <div className="flexDisplayColume alignCenterFlex Rectangle-113 justifyFlex">
                <span className="thirtyFontSize font500 lHg0">02</span>
                <span className="fourteenFontSize lHg2 txtmedia1">Subject & Topics</span>
                {/* <span className="nineFontSize lHg1">
                This is going to help you select the subject and topics you want to answer
                </span> */}
          </div>
          <div className="centerFlex alignFlex"> <Icon name='play' className="NotActiveStep" /></div>
          <div className="flexDisplayColume alignCenterFlex Rectangle-113 justifyFlex">
                <span className="thirtyFontSize font500 lHg0">03</span>
                <span className="fourteenFontSize lHg2 txtmedia1">Confirmation</span>
                {/* <span className="nineFontSize lHg1">
                This is when you will get to confirm your entry before you start the test
                </span> */}
          </div>
        </div>
    
    <ToastContainer /> 
    <div className="fullWidth">
          
          <div className="flexColumeflexStart selectWrap padTop1" style={styles.selectWrap}>
            <p style={{textAlign: 'left', fontSize: '16px',}} className='noMargin font500 mgBtm2'>Select Curriculum</p>
          </div>

          <ButtonToolbar className="mgBtm2">
    <ToggleButtonGroup className="btnGroup1" type="radio" name="options" defaultValue={1}>
      
      {Question_Curriculum}
    </ToggleButtonGroup>
  </ButtonToolbar>
       </div>   
    <div className="fullWidth">
          
          <div className="flexColumeflexStart selectWrap padTop1">
            <p style={{textAlign: 'left', fontSize: '16px',}} className='noMargin font500 mgBtm2'>Select Class</p>
          </div>

          <ButtonToolbar className="mgBtm2">
    <ToggleButtonGroup className="btnGroup1" type="radio" name="options" defaultValue={1}>
      
      {Question_Class}
    </ToggleButtonGroup>
  </ButtonToolbar>
       </div>   

  {/* <Dropdown 
  placeholder='curriculum' 
  fluid selection 
  options={ExamStore.AllCurriculum}
   style={styles.selectWrap}
    defaultValue={this.props.values.curriculum} 
      onChange={(e,value) => this.props.handleChangeSelect(e, value)}
      
      /> */}
  <div className="fullWidth centerFlex">
          <div className="equalWidth leftFlex alignFlex"><Link to="/"><Button className="Rectangle-115 font500"><Icon name="home" />Back</Button></Link></div>
          <div className="equalWidth rightFlex alignFlex"><Button onClick={this.saveAndContinue} className="Rectangle-114 whiteColor sixteenFontSize font500">Continue</Button></div>
          </div>
          </div>
          </div>
       </div>   

          <Modal open={open}
          onOpen={this.open}
          onClose={this.close}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          size={'small'}
          >
            
          <Modal.Content image style={{ padding: '4% 10% 5%', }}>
            <Modal.Description style={{ minWidth: '41%' }}>
              <div className="TewntyFiveFontSize font500" style={{marginBottom: 35}}>Sign In</div>
              <div>
                {sizes.map(size => (
                  <Form size={size} key={size}>
                    <Form.Field className="relPosition">

                    <span className="inputLabel">Email</span>
                    <input
                    className="styInputAcct"
                     control='input' 
                     name="email"
                     onChange={(e)=>AccountStore.handleReg_Change(e,'loginInfo')}
                      />
                       </Form.Field>
                       <Form.Field className="relPosition">
                       <span className="inputLabel">Password</span>
                       <input
                     control='input' 
                     className="styInputAcct"
                     name="password"
                     type="password"
                     onChange={(e)=>AccountStore.handleReg_Change(e,'loginInfo')}
                     />
                       </Form.Field>
                    
                    <Button
                     className='greenBackground sixteenFontSize font500 LologinBtn'
                      onClick={AccountStore._LoginHandler}
                      >
                         {AccountStore.isprocessing ? 'Processing....' : 'Sign in'}
                      
                      </Button>
                    <Divider hidden  style={{marginBottom:3}}/>
                    {/* <p style={{ fontSize: '18px' }}>Don't have an account yet?<span onClick={this.openSUModal2}  className="blueColor" style={{cursor: 'pointer'}}> Register.</span></p> */}

                    <p style={{ fontSize: '18px', marginTop:0 }}>Don’t have an Account? <span onClick={this.openSUModal2}  className="blueColor font500" style={{cursor: 'pointer'}}> Sign Up </span> now</p>
                    {/* <Link to={'/'} className="greenColor">Home</Link> */}
                  </Form>
                ))}
              </div>
            </Modal.Description>
          </Modal.Content>
        </Modal>


        <Modal 
        open={showModal}
        onOpen={this.openSUModal}
        onClose={this.closeSUModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        size={'small'}
        >
           
            <Modal.Content image style={{padding: '4% 10% 5%',}}>
              <Modal.Description>
              <div className="TewntyFiveFontSize font500" style={{marginBottom: 35}}>Sign Up</div>
              <div>
                    {Tinsizes.map(size => (
                    <Form size={size} key={size}>
                      <Form.Field className="relPosition">
                        <span className="inputLabel">First Name</span>
                        <input
                          className="styInputAcct"
                          control='input' 
                          name="Reg_firstname"
                          onChange={(e)=>AccountStore.handleReg_Change(e,'regInfo')}
                        />
                      </Form.Field>
                      <Form.Field className="relPosition">
                        <span className="inputLabel">Last Name</span>
                        <input
                          className="styInputAcct"
                          control='input' 
                          name="Reg_lastname"
                          onChange={(e)=>AccountStore.handleReg_Change(e,'regInfo')}
                        />
                      </Form.Field>
                      <Form.Field className="relPosition">
                        <span className="inputLabel">Age</span>
                        <input
                          className="styInputAcct"
                          control='input' 
                          name="Reg_age"
                          onChange={(e)=>AccountStore.handleReg_Change(e,'regInfo')}
                        />
                      </Form.Field>

                      <Form.Field className="relPosition">
                        <span className="inputLabel">Email</span>
                        <input
                          className="styInputAcct"
                          control='email' 
                          name="Reg_email"
                          onChange={(e)=>AccountStore.handleReg_Change(e,'regInfo')}
                        />
                      </Form.Field>
                      <Form.Field className="relPosition">
                        <span className="inputLabel">Password</span>
                        <input
                          className="styInputAcct"
                          control='input' 
                          type="password"
                          name="Reg_password"
                          onChange={(e)=>AccountStore.handleReg_Change(e,'regInfo')}
                        />
                      </Form.Field>
                      <Form.Field className="relPosition">
                        <span className="inputLabel">Confirm Password</span>
                        <input
                          className="styInputAcct"
                          control='input' 
                          type="password"
                          name="Reg_confirm"
                          onChange={(e)=>AccountStore.handleReg_Change(e,'regInfo')}
                        />
                      </Form.Field>

                      <Form.Field className="relPosition">
                        <span className="inputLabel">School</span>
                        <input
                          className="styInputAcct"
                          control='input' 
                          name="Reg_school"
                          onChange={(e)=>AccountStore.handleReg_Change(e,'regInfo')}
                        />
                      </Form.Field>
                      
                        {/* <Form.Field control='input' placeholder='Country' />
                        <Form.Field control='input' placeholder='State' /> */}
                        {/* <Button fluid>Fits to Container</Button> */}
                        <Button  
                        className='greenBackground sixteenFontSize font500 LologinBtn' 
                        fluid type='submit'
                        onClick={AccountStore._SignupHandler}
                        >
                          {AccountStore.isprocessing ? 'Processing....' : 'Sign Up'}</Button>
                         
                        <Divider hidden  style={{marginBottom:3}}/>

                    <p style={{ fontSize: '18px', marginTop:0 }}>Already have an Account?<span onClick={this.openLOgModal2}  className="blueColor font500" style={{cursor: 'pointer'}}> Sign In </span> now</p>
                    </Form>
                    ))}
                </div>
              </Modal.Description>
            </Modal.Content>
          </Modal>
     

  </div>
)
}
}

 export default withRouter(StepOne);