import React, { Component } from 'react';
 
import { observable, action } from 'mobx';
import qs from 'qs';
import axios from 'axios'
import queryString from 'query-string'
import ls from 'local-storage';
import { ToastContainer, toast } from 'react-toastify';
 

import 'react-toastify/dist/ReactToastify.css';
 

class Dashboard {
    @observable AllCardRequest = []
    @observable AllCardList = []
    @observable AllAuditTrail = []
    @observable loginInfo = {
        email: '',
        password: '',
        rememberme: false,
    }
    @observable Changepwd = {
        oldpassword: '',
        newpassword: '',
        confirmpassword: '',
    }
    @observable cardrequest = {
        phoneNumber: "",
        surName: "",
        firstName: "",
        middleName: "",
        location: "",
        address: ""
    }
    @observable RoleandPermission = {
        roleid: "",
        rolename: ""
        
    }
    @observable linkcard = {
        expiryDate: '',
        cvv: '',
        panNumber: '',
        reference: '',
        card_id: '',

    }
    @observable UploadPanDeatils = {
   
        "csvFile": null,

    }
    @observable AllUserRoles = []
    @observable CompanionPermissions = []



    @observable loggedinUserdetails = {

    }
    @observable Modalopen = false;
    @observable disabled = false
    @observable loader_visible = false
    @observable redirectmode = false
    @observable isprocessing = false
    @observable redirectToReferrer = false

    @observable selectedPermissions = [];





    @action  TogglePermissionArray = (singlePermission) => {
        // alert("hey")
        if(singlePermission==='empty'){
          this.selectedPermissions= [];
        }
        else{
          
            var check = this.selectedPermissions.includes(singlePermission);
            if(check===true){
        console.warn("true")
        this.selectedPermissions.splice(this.selectedPermissions.indexOf(singlePermission), 1);
       
            }
            else{
              console.warn("false")
              this.selectedPermissions[this.selectedPermissions.length] = singlePermission;
            }
        console.warn(this.selectedPermissions);
       
          
        }

     
            }










    @action ToggleModal() {
        DashboardStore.Modalopen = !DashboardStore.Modalopen;
    }
    @action _handleChangeSelect = (stateOption, name, type) => {
        let value = stateOption.value;

        let expiryDate = stateOption.value.expiryDate;
        let cvv = stateOption.value.cvv;
        let panNumber = stateOption.value.panNumber;
        let reference = stateOption.value.reference;
        let card_id = stateOption.value.id;
        this[type]['expiryDate'] = expiryDate;
        this[type]['cvv'] = cvv;
        this[type]['panNumber'] = panNumber;
        this[type]['reference'] = reference;
        this[type]['card_id'] = card_id;

    }

    @action handleInputChange(e, type) {
        const value = e.target[e.target.type === "checkbox" ? "checked" : "value"]
        const name = e.target.name;
        console.log(name)
        console.log(type)
        DashboardStore[type][name] = value;
    }
    @action _ToggleLoader = (status) => {
        this.loader_visible = status;
    }
    @action _ToggleProcessing = () => {
        this.isprocessing = !this.isprocessing;
    }
    @action handlefilePicker(event, type) {
        // event.preventDefault();
        const name = event.target.name;
        console.log(event.target.files[0]);
        // DashboardStore[type][name]=URL.createObjectURL(event.target.files[0])
        DashboardStore[type][name] = event.target.files[0]
        // this.setState({
        //   file: URL.createObjectURL(event.target.files[0])
        // })
    }



    @action _SubmitCardRequest(e) {
        e.preventDefault()
        console.log(DashboardStore.cardrequest)

        const {
            phoneNumber,
            surName,
            firstName,
            middleName,
            location,
            address
        } = DashboardStore.cardrequest;
        // alert(firstName)
        // alert(phoneNumber)
        console.log(DashboardStore.cardrequest)

        if (firstName === '') {
            toast.warning("First Name  is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        else if (middleName === '') {
            toast.warning("Middle Name  is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        else if (phoneNumber === '') {
            toast.warning("Phone Number  is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        else if (location === '') {
            toast.warning("Enter Your Location To Proceed", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        else if (address === '') {
            toast.warning("Enter Your Address To Proceed", {
                position: toast.POSITION.TOP_CENTER
            });
        }


        else {


            let params = {
                phoneNumber,
                surName,
                firstName,
                middleName,
                location,
                address
            }

            console.log(params);
            DashboardStore.isprocessing = true
            axios.post(`api/Cards/NewCardRequest`, params)
                .then(response => {
                    DashboardStore.isprocessing = false
                    // console.log(response)
                    console.log(response.status)

                    if (response.status === 200) {
                        console.log(resp)
                        let resp = response.data;
                        let internalcode = resp.code;

                        let text = resp.description;
                        if (internalcode > 0) {
                            toast.success(text, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            // setTimeout(
                            //     function () {
                            //         window.location.reload();

                            //     }, 2000);
                            DashboardStore.ToggleModal();
                        }
                        else {
                            toast.error(text, {
                                position: toast.POSITION.TOP_CENTER
                            });
                        }




                    }
                    else {
                        console.log(response)
                        DashboardStore.isprocessing = false
                    }

                })
                .catch(err => {
                    DashboardStore.isprocessing = false
                    toast.error('An Error Occured \n Please Check the Email Address', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    console.log(err)
                })
        }

        // 
    }
    @action _ChangePassword(e) {
        e.preventDefault()
        console.log(DashboardStore.Changepwd)

        const {
            oldpassword,
            newpassword,
            confirmpassword,
        } = DashboardStore.Changepwd;
        // alert(firstName)
        // alert(phoneNumber)
        console.log(DashboardStore.cardrequest)

        if (oldpassword === '') {
            toast.warning("Old Password  is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        else if (newpassword === '') {
            toast.warning("New Password  is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        else if (confirmpassword === '') {
            toast.warning("Confirm Password  is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        else if (newpassword !== confirmpassword) {
            toast.warning("Password Not Match", {
                position: toast.POSITION.TOP_CENTER
            });
        }



        else {


            let params = {
                oldpassword,
                newpassword,
                confirmpassword
            }

            console.log(params);
            DashboardStore.isprocessing = true
            axios.post(`api/UserManagement/changepassword`, params)
                .then(response => {
                    DashboardStore.isprocessing = false
                    console.log(response)
                    console.log(response.status)

                    if (response.status === 200) {
                        console.log(resp)
                        let resp = response.data;
                        let internalcode = resp.code;

                        let text = resp.description;
                        if (internalcode > 0) {
                            toast.success(text, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            setTimeout(
                                function () {
                                    window.location.reload();

                                }, 2000);
                        }
                        else {
                            toast.error(text, {
                                position: toast.POSITION.TOP_CENTER
                            });
                        }




                    }
                    else {
                        console.log(response)
                        DashboardStore.isprocessing = false
                    }

                })
                .catch(err => {
                    DashboardStore.isprocessing = false
                    toast.error('An Error Occured \n Please your old Password', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    console.log(err)
                })
        }

        // 
    }
    @action _uploadPans(e) {
        e.preventDefault() // Stop form submit
        console.log(DashboardStore.UploadPanDeatils)
        const {  csvFile
        } = DashboardStore.UploadPanDeatils;
      
        if (csvFile === null) {
            toast.warn("Please Select a CSV file  is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        

        else {
        

            let params = {
                "cardType":"Verve",
                "BatchNo":"1234567"
            }
            // formData.append(item.name, fs.createReadStream(pathToFile));
            var bodyFormData = new FormData();
            bodyFormData.set('payload', JSON.stringify(params));          
             bodyFormData.append('File', csvFile);
            // bodyFormData.append('File', fs.createReadStream(imageFile));
      

            console.log(bodyFormData);
            // console.log(params);
            DashboardStore.isprocessing = true
            // axios.post(`/api/UserManagement/createBankAdmin`,
            //     queryString.stringify(params))
            // axios.post(`api/UserManagement/createBankAdmin`,
            // queryString.stringify(params))

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data"
                    // 'content-type': `multipart/form-data; boundary=${bodyFormData._boundary}`
                }
            };
            axios.post(`api/Cards/uploadPans`,
                bodyFormData, config)
                .then(response => {
                    DashboardStore.isprocessing = false
                    console.log(response)
                    console.log(response.status)
                    DashboardStore.isprocessing = false

                    if (response.status === 200) {
                        console.log(resp)
                        let resp = response.data;
                        let internalcode = resp.code;

                        let text = resp.description;
                        if (internalcode > 0) {
                            toast.success(text, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            setTimeout(
                                function () {
                                    window.location.reload();

                                }, 2000);

                        }
                        else {
                            toast.error(text, {
                                position: toast.POSITION.TOP_CENTER
                            });
                        }




                    }
                    else {
                        console.log(response)
                        console.log('response')
                        DashboardStore.isprocessing = false
                    }

                })
                .catch(err => {
                    DashboardStore.isprocessing = false
                    toast.error('An Error Occured', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    console.log(err)
                })
        }

        // 
    }
   
    @action _CreateRoleandPermission(e) {
        e.preventDefault() 
        console.log(DashboardStore.RoleandPermission)
        const {  roleid,rolename
        } = DashboardStore.RoleandPermission;
      
        if (roleid === '') {
            toast.warn("Role Id  is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        else if (rolename === '') {
            toast.warn("Role Name  is required", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        else if (DashboardStore.selectedPermissions.length < 1) {
            toast.warn("Select Some Permission for the role", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        

        else {
        

            let params = {
                roleName: rolename,
                roleId: roleid,
                permssion:DashboardStore.selectedPermissions
 
            }
            // formData.append(item.name, fs.createReadStream(pathToFile));
            var bodyFormData = new FormData();
            // bodyFormData.set('payload', JSON.stringify(params));          
            //  bodyFormData.append('File', csvFile);
            // bodyFormData.append('File', fs.createReadStream(imageFile));
      

            console.log(bodyFormData);
            // console.log(params);
            DashboardStore.isprocessing = true
            // axios.post(`/api/UserManagement/createBankAdmin`,
            //     queryString.stringify(params))
            // axios.post(`api/UserManagement/createBankAdmin`,
            // queryString.stringify(params))

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data"
                    // 'content-type': `multipart/form-data; boundary=${bodyFormData._boundary}`
                }
            };
            axios.post(`api/UserManagement/CreateRoleandPermission`,
            params)
                .then(response => {
                    DashboardStore.isprocessing = false
                    console.log(response)
                    console.log(response.status)
                    DashboardStore.isprocessing = false

                    if (response.status === 200) {
                        console.log(resp)
                        let resp = response.data;
                        let internalcode = resp.code;

                        let text = resp.description;
                        if (internalcode > 0) {
                            toast.success(text, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            // setTimeout(
                            //     function () {
                            //         window.location.reload();

                            //     }, 2000);

                        }
                        else {
                            toast.error(text, {
                                position: toast.POSITION.TOP_CENTER
                            });
                        }




                    }
                    else {
                        console.log(response)
                        console.log('response')
                        DashboardStore.isprocessing = false
                    }

                })
                .catch(err => {
                    DashboardStore.isprocessing = false
                    toast.error('An Error Occured', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    console.log(err)
                })
        }

        // 
    }


    

    GetCardList = () => {
        console.warn("GetCardList");

        DashboardStore._ToggleProcessing();

        axios.get("api/Cards/GetallCardsLinked")
            .then(response => {
                DashboardStore._ToggleProcessing();
                if (response.status === 200) {
                    let resp = response.data;
                    // console.warn(resp)
                    let payload = resp.payload
                    console.log("ppp")
                    console.log(payload)
                    let index = 1;
                    const formattedMessagedata = payload.map(singleData => {
                        let cardstatus = '';
                        if (singleData.cardStatus === 1) {
                            cardstatus = 'Pending';
                        }
                        else if (singleData.cardStatus === 2) {
                            cardstatus = 'Active';
                        }
                        else if (singleData.cardStatus === 3) {
                            cardstatus = 'InActive';
                        }
                        else if (singleData.cardStatus === 4) {
                            cardstatus = 'Failed';
                        }

                        return {

                            id: index++,
                            refrence: singleData.reference,
                            pan: singleData.panNumber,
                            expirer: singleData.expiryDate,
                            status: cardstatus,

                        };
                    });
                    console.log('GetallCardsLinked');
                    console.log(formattedMessagedata);
                    this.AllCardList = formattedMessagedata

                }
                else {
                    DashboardStore._ToggleProcessing();

                    console.warn("Error loading GetallCardsLinked");

                    this.AllCardList = []

                }
            })
            .catch(error => {
                DashboardStore._ToggleProcessing();
                console.warn(`error ${error}`);
                this.AllCardList = [];
                //  this._ToggleLoader(false)
            });
    }
   

    GetAllRolls = () => {
        console.warn("GetallRoles");

        DashboardStore._ToggleProcessing();

        let params = {
            "fromDate": null,
            "toDate": null,
            "filterBy": null,
            "pageIndex": 1,
            "pageSize": 100
        }

        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
                // 'content-type': `multipart/form-data; boundary=${bodyFormData._boundary}`
            }
        };
        axios.post("api/UserManagement/GetAllRoles", params)
            .then(response => {
                console.log(response);
                DashboardStore._ToggleProcessing();

                if (response.status === 200) {
                    let resp = response.data;
                    console.warn(resp)
                    let payload = resp.payload
                    console.log(payload)
                    let index = 0;
                    const FormattedData = payload.map(singleData => {

                        return {
                            id: index += 1,
                            Iden: singleData.id,
                            roleName: singleData.name,
                            // Action: <div className="displayCenterFlex">
                            //     <MDBIcon fa icon="edit" className="ml-2 lightweight" onClick={() => DashboardStore.ModalEditUser(singleData)} />
                            //     <MDBIcon fa icon="trash-alt" className="ml-2 colred lightweight" />
                            //     <MDBIcon onClick={() => DashboardStore.ModalNewuser(singleData)} fa icon="fa-plus" className="ml-2 colred lightweight" />  <i className="fa fa-fw fa-plus faStyle" onClick={() => DashboardStore.ModalNewuser(singleData)} /></div>

                        };
                    });

                    DashboardStore.AllUserRoles = FormattedData;

                    console.log(this.AllUserRoles);
                }
                else {

                    console.warn("Error loading corperate user");

                    this.AllUserRoles = []

                }
            })
            .catch(error => {
                DashboardStore._ToggleProcessing();

                console.warn(`error ${error}`);
                this.AllUserRoles = [];
            });
    }

    GetCompanionPermissions = () => {
        console.warn("GetCompanionPermissions");
        // alert("p")
        DashboardStore._ToggleProcessing();
        axios.get("api/UserManagement/GetCompanionPermissions")
            .then(response => {
                // console.log(response);
                DashboardStore._ToggleProcessing();

                if (response.status === 200) {
                    // alert("enter")
                    let resp = response.data;
                    console.warn(resp)
                    let payload = resp.payload
                    console.log(payload)
                    // let index = 0;
                    // const FormattedData = payload.map(singleData => {

                    //     return {
                    //         id: index += 1,
                    //         roleName: singleData.name,
                    //          Action: <div className="displayCenterFlex">
                    //             <MDBIcon fa icon="edit" className="ml-2 lightweight" onClick={() => DashboardStore.ModalEditUser(singleData)} />
                    //             <MDBIcon fa icon="trash-alt" className="ml-2 colred lightweight" />
                    //             <MDBIcon onClick={() => DashboardStore.ModalNewuser(singleData)} fa icon="fa-plus" className="ml-2 colred lightweight" />  <i className="fa fa-fw fa-plus faStyle" onClick={() => DashboardStore.ModalNewuser(singleData)} /></div>

                    //     };
                    // });

                    DashboardStore.CompanionPermissions = payload;

                }
                else {
                    alert("enterss")
                    console.warn("Error loading corperate user");

                    this.CompanionPermissions = []

                }
            })
            .catch(error => {
                DashboardStore._ToggleProcessing();

                console.warn(`error ${error}`);
                this.CompanionPermissions = [];
            });
    }





    GetCompanionPermissionsold = () => {
        console.warn("GetCaseStatus")

        //    this._ToggleLoader(true)

        axios.get("/api/UserManagement/GetCompanionPermissions")
            .then(response => {
                let resp = response.data;
                console.log(response);
                if (resp.StatusCode === '00') {
                    let data = resp.Data;
                    console.warn(data)
                    this.caseStatuses = data

                    // this._ToggleLoader(false)
                }
                else {
                    //    console.warn('error');
                    //    let resp=response.data;
                    //    console.warn(resp);
                    //    let message =resp.Message
                    //    Toast.show(message, Toast.SHORT, Toast.BOTTOM,styletoest);
                    //    this.caseStatuses=[]
                    //  this._ToggleLoader(false)
                }
            })
            .catch(error => {
                console.warn(`error ${error}`);
                this.caseStatuses = [];
                //  this._ToggleLoader(false)
            });
    }


}

const DashboardStore = new Dashboard()
export default DashboardStore
